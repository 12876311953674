import DatePicker from "react-date-picker";
import { useState, useEffect } from "react";
import { NumericFormat } from "react-number-format";
import axios from "axios";

export default function WarehouseInventory({
  product,
  clientList,
  productCategory,
  warArray,
  existCategory,
  wareName,
  onModProduct,
  onModProdWare,
  onDelProduct,
}) {
  const [isActive, setIsActive] = useState(false);
  const [edit, setEdit] = useState(true);
  const [moveWare, setMoveWare] = useState(true);
  const [warehouses, setWarehouses] = useState([]);

  function getWarehouses() {
    axios
      .get("https://caravela.dev/gestor-bolso-api/warehouses", {
        withCredentials: true,
      })
      .then((resp) => {
        setWarehouses(resp.data.data);
      });
  }

  useEffect(() => {
    getWarehouses();
  }, []);

  return (
    <>
      <div
        className="warehouseInventory thinBox centerBetween LRPadding20"
        onClick={() => setIsActive(!isActive)}
      >
        <div className="warehouseInventoryShort alignCenter">
          {product.category_name} / {product.brand} / {product.product_name} /
          EAN: {product.ean} / SN: {product.serial_number} /{" "}
          {product.state === "In Stock" ? (
            <strong>Stock</strong>
          ) : (
            <strong> - </strong>
          )}
        </div>
        <div className="alignCenter gap10">
          {isActive && (
            <button
              onClick={(e) => {
                setEdit(!edit);
                setMoveWare(true);
                e.stopPropagation();
              }}
            >
              Edit
            </button>
          )}
          {isActive && (
            <button
              onClick={(e) => {
                setMoveWare(!moveWare);
                setEdit(true);
                e.stopPropagation();
              }}
            >
              Move Item
            </button>
          )}
          <h3>{isActive ? "-" : "+"} </h3>
        </div>
      </div>
      {isActive && (
        <ProductDescription
          productInfo={product}
          // clientList={clientList}
          edit={edit}
          // onEdit={setEdit}
          moveWare={moveWare}
          // onMoveWare={setMoveWare}
          // warArray={warArray}
          // wareName={wareName}
          // existCategory={existCategory}
          // onModProduct={onModProduct}
          // onModProdWare={onModProdWare}
          // onDelProduct={onDelProduct}
          warehouses={warehouses}
        />
      )}
    </>
  );
}

function ProductDescription({
  productInfo,
  clientList,
  edit,
  onEdit,
  warArray,
  moveWare,
  onMoveWare,
  existCategory,
  wareName,
  onModProduct,
  onModProdWare,
  onDelProduct,
  warehouses,
}) {
  const [category, setCategoty] = useState(productInfo.category_name);
  const [brand, setBrand] = useState(productInfo.brand);
  const [productName, setProductName] = useState(productInfo.product_name);
  const [sku, setSKU] = useState(productInfo.sku);
  const [ean, setEAN] = useState(productInfo.ean);
  const [sn, setSN] = useState(productInfo.serial_number);
  const [purchaseDate, setPurchaseDate] = useState(productInfo.created_at);
  const [entryDate, setEntryDate] = useState(productInfo.entry_date);
  const [purchaseValue, setPurchaseValue] = useState(productInfo.cost_price);
  const [pricePVP, setPricePVP] = useState(productInfo.current_price);
  const [location, setLocation] = useState("");
  const [supplier, setSupplier] = useState(productInfo.supplier_name);
  const [available, setAvailable] = useState(productInfo.state);
  const [obs, setObs] = useState(productInfo.obs);

  const [soldPrice, setSoldPrice] = useState(productInfo.soldPrice);
  const [soldDate, setSoldDate] = useState(productInfo.sale_date);
  const [soldWho, setSoldWho] = useState(productInfo.soldWho);

  function getWareName() {
    setLocation();
    let findLocation = warehouses.filter(
      (ware) => ware.warehouse_id === productInfo.warehouse_id
    );
    return setLocation(findLocation[0].name);
  }

  console.log(productInfo);

  function handleSubmit(e) {
    e.preventDefault();

    const actualIndex = warArray.findIndex((item) => item.name === wareName);
    const locationIndex = warArray.findIndex((item) => item.name === location);
    const stockIndex = warArray[actualIndex].stock.findIndex(
      (item) => item.sn === sn
    );

    if (category === "") {
      window.alert("Please, insert a category");
    } else if (location === "") {
      window.alert("Please, insert a warehouse");
    } else if (purchaseDate === "" || entryDate === "") {
      window.alert("Please, insert a purchase date and a entry date");
    } else if (new Date(purchaseDate) > new Date(entryDate)) {
      window.alert("Purchase date can't be superior of the entry date");
    } else {
      const changedProduct = {
        category,
        brand,
        productName,
        sku,
        ean,
        sn,
        purchaseDate,
        entryDate,
        purchaseValue,
        pricePVP,
        supplier,
        available,
        obs,
      };

      if (location !== wareName) {
        onModProdWare(stockIndex, actualIndex, locationIndex);
        onMoveWare(!moveWare);
        window.alert("Product moved");
      } else {
        onModProduct(changedProduct, actualIndex, stockIndex);
        onEdit(!edit);
        window.alert("Product edited");
      }
    }
  }

  function handleDel(e) {
    e.preventDefault();

    const actualIndex = warArray.findIndex((item) => item.name === wareName);
    const stockIndex = warArray[actualIndex].stock.findIndex(
      (item) => item.sn === sn
    );

    if (
      window.confirm(
        `Are you sure you want to delete ${brand} ${productName} ${sn}?`
      )
    ) {
      let isDelete = prompt("Type 'delete' to confirm");
      if (isDelete === "delete") {
        window.confirm(`SN: ${sn} deleted`);
        onDelProduct(stockIndex, actualIndex);
      } else {
        window.alert("Operation canceled");
      }
    } else {
      window.alert("Operation canceled");
    }
  }

  const handlePurchaseDateChange = (date) => {
    const month = date.toLocaleString("en-US", { month: "long" });
    let day = date.getDate();
    const year = date.getFullYear();
    day = day < 10 ? `0${day}` : day;
    let formatedMonth =
      month.charAt(0).toUpperCase() + month.slice(1).toLowerCase();
    const formattedDate = `${day} ${formatedMonth} ${year}`;
    setPurchaseDate(formattedDate);
  };

  const handleEntryDateChange = (date) => {
    const month = date.toLocaleString("en-US", { month: "long" });
    let day = date.getDate();
    const year = date.getFullYear();
    day = day < 10 ? `0${day}` : day;
    let formatedMonth =
      month.charAt(0).toUpperCase() + month.slice(1).toLowerCase();
    const formattedDate = `${day} ${formatedMonth}, ${year}`;
    setEntryDate(formattedDate);
  };

  useEffect(() => {
    getWareName();
  }, []);

  return (
    <form className="productDescription thinBox">
      <div className="LRPadding20 column">
        <ul className="productInputTable centerBetween">
          <div>
            <label>Category</label>
            <div className="alignCenter gap10">
              <input
                type="text"
                className="productInput column"
                value={category}
                disabled
              />
            </div>
          </div>

          <div>
            <label>Brand</label>
            <input
              type="text"
              value={brand}
              className="productInput column"
              disabled
            />
          </div>

          <div>
            <label>Product name</label>
            <input
              type="text"
              value={productName}
              className="productInput column"
              disabled
            />
          </div>

          <div>
            <label>Purchase Value</label>
            <NumericFormat
              value={purchaseValue}
              onValueChange={(e) => setPurchaseValue(e.target.value)}
              thousandSeparator={true}
              prefix={"€ "}
              decimalScale={2}
              fixedDecimalScale={true}
              className="productInput column"
              disabled={edit}
            />
          </div>

          <div>
            <label>Price</label>
            <NumericFormat
              value={pricePVP}
              onValueChange={(e) => setPricePVP(e.target.value)}
              thousandSeparator={true}
              prefix={"€ "}
              decimalScale={2}
              fixedDecimalScale={true}
              className="productInput column"
              disabled={edit}
            />
          </div>

          <div>
            <label>Location</label>
            <div className="alignCenter gap10">
              <select
                className="filter larger"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                disabled={moveWare}
              >
                <option value={location}>{location}</option>
                {warehouses.map((war, i) => (
                  <option value={war.warehouse_id} key={"war" + i}>
                    {war.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div>
            <label>Supplier</label>
            <input
              type="text"
              value={supplier}
              className="productInput column"
              disabled
            />
          </div>

          <div>
            <label>EAN</label>
            <input
              type="text"
              value={ean}
              placeholder={productInfo.ean}
              className="productInput column"
              disabled
            />
          </div>

          <div>
            <label>SKU</label>
            <input
              type="text"
              value={sku}
              className="productInput column"
              disabled
            />
          </div>

          <div>
            <label>SN</label>
            <input
              type="text"
              value={sn}
              onChange={(e) => setSN(e.target.value)}
              className="productInput column"
              disabled={edit}
            />
          </div>

          <div className="productInput column">
            <label>Entry date</label>
            <DatePicker
              className="dates"
              value={entryDate}
              clearIcon={null}
              calendarIcon={null}
              disabled
            />
          </div>

          <div className="productInput column">
            <label>Purchase date</label>
            <DatePicker
              className="dates"
              value={purchaseDate}
              clearIcon={null}
              calendarIcon={null}
              disabled
            />
          </div>

          <div>
            <label>Obs</label>
            <input
              type="text"
              value={obs}
              placeholder={productInfo.obs}
              onChange={(e) => setObs(e.target.value)}
              className="productInput column"
              disabled={edit}
            />
          </div>

          <div className="alignCenter">
            <label>Avaible?</label>
            <input
              type="checkbox"
              checked={available === "In Stock"}
              onChange={() => setAvailable(!available)}
              disabled={edit}
            />
          </div>

          {available !== "In Stock" && (
            <>
              <div>
                <label>Sold Price w/Vat</label>
                <NumericFormat
                  value={soldPrice}
                  thousandSeparator={true}
                  prefix={"€ "}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  className="productInput column"
                  disabled
                />
              </div>
              <div>
                <label>Sold Price w.o/Vat</label>
                <NumericFormat
                  value={soldPrice / 1.23}
                  thousandSeparator={true}
                  prefix={"€ "}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  className="productInput column"
                  disabled
                />
              </div>

              <div>
                <label>Profit</label>
                <NumericFormat
                  value={soldPrice / 1.23 - purchaseValue}
                  thousandSeparator={true}
                  prefix={"€ "}
                  suffix={`   (${(
                    ((soldPrice / 1.23 - purchaseValue) / (soldPrice / 1.23)) *
                    100
                  ).toFixed(2)}%)`}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  className="productInput column"
                  disabled
                />
              </div>

              <div className="productInput column">
                <label>Sold date</label>
                <DatePicker
                  className="dates"
                  value={soldDate}
                  clearIcon={null}
                  disabled
                />
              </div>

              <div>
                <label>Sold to Who</label>
                <input
                  type="text"
                  value={soldWho}
                  className="productInput column"
                  disabled
                />
              </div>
            </>
          )}
        </ul>
        <div className="spaceBetween">
          {!edit && (
            <button className="confirmButton delete" onClick={handleDel}>
              Delete
            </button>
          )}
          {(!edit || !moveWare) && (
            <button className="confirmButton" onClick={handleSubmit}>
              Confirm
            </button>
          )}
        </div>
      </div>
    </form>
  );
}
