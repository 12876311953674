import { useState, useEffect } from "react";
import { MdDone } from "react-icons/md";
import { NumericFormat } from "react-number-format";
import ClientProductInfo from "./ClientProductInfo";
import axios from "axios";

export default function ClientPurchases({
  selClientInfo,
  clientList,
  prods,
  purchases,
  isEditPurchActive,
  productsStock,
  onModProduct,
  onModCompound,
  onModClientPurch,
  index,
  soldItems,
}) {
  const [newSoldPrice, setNewSoldPrice] = useState(prods.soldPrice);
  const [seeProductInfo, setSeeProductInfo] = useState(false);
  const [product, setProduct] = useState("");

  function getProduct() {
    let foundSN = soldItems.findIndex((info) => info.serial_number === prods);
    console.log(soldItems[foundSN]);
    return setProduct(soldItems[foundSN]);
  }

  function handleSoldPrice(price) {
    const newValue = parseFloat(price.floatValue);
    setNewSoldPrice(newValue);
  }

  function changePrice() {
    const allProducts = selClientInfo.sales;

    console.log(purchases);

    // const prodIndex = allProducts.findIndex(
    //   (item) => item.sale_id === arrProducts.order_item_id
    // );

    // allProducts[prodIndex] = {
    //   order_item_id: arrProducts.order_item_id,
    //   product_id: arrProducts.product_id,
    //   quantity: +units,
    //   arrived: +prodArrived,
    //   cost_price: purchaseValue,
    // };

    // axios
    //   .put(
    //     "https://caravela.dev/gestor-bolso-api/supplierOrder",
    //     {
    //       id: +purchases.order_id,
    //       supplier_id: selSupplierInfo.supplier_id,
    //       arriving_date: purchases.arriving_date.substring(0, 10),
    //       products: allProducts,
    //     },
    //     { withCredentials: true }
    //   )
    //   .then((resp) => {
    //     if (resp.data.ok) {
    //       window.alert(
    //         `Arrival ${arrProducts.brand} - ${arrProducts.product_name}  edited`
    //       );
    //       setWhichSupplier(selSupplierInfo.supplier_id);
    //       getSupplierList();
    //       setIsActive(!isActive);
    //     }
    //   })
    //   .catch((error) => {
    //     window.alert(error);
    //   });

    // const locationIndex = productsStock.findIndex(
    //   (ware) =>
    //     ware.stock.some((item) => item.sn === prods.sn) ||
    //     ware.compound.some((item) => item.sn === prods.sn)
    // );
    // const stockIndex = productsStock[locationIndex]?.stock.findIndex(
    //   (item) => item.sn === prods.sn
    // );
    // console.log(stockIndex);
    // if (stockIndex === -1) {
    //   const compoundIndex = productsStock[locationIndex].compound.findIndex(
    //     (item) => item.sn === prods.sn
    //   );
    //   const changedProduct = {
    //     ...productsStock[locationIndex].compound[compoundIndex],
    //     soldPrice: newSoldPrice,
    //   };
    //   onModCompound(changedProduct, locationIndex, compoundIndex);
    // } else {
    //   const changedProduct = {
    //     ...productsStock[locationIndex].stock[stockIndex],
    //     soldPrice: newSoldPrice,
    //   };
    //   onModProduct(changedProduct, locationIndex, stockIndex);
    // }
  }

  function delCltProduct() {
    if (
      window.confirm(
        `Are you sure you want to DELETE ${prods.brand} ${prods.productName} ${prods.sn}`
      ) === true
    ) {
      let isDelete = prompt("Type 'delete' to confirm");
      if (isDelete === "delete") {
        const locationIndex = productsStock.findIndex((ware) =>
          ware.stock.some((item) => item.sn === prods.sn)
        );
        const stockIndex = productsStock[locationIndex].stock.findIndex(
          (item) => item.sn === prods.sn
        );
        const changedProduct = {
          ...productsStock[locationIndex].stock[stockIndex],
          available: true,
          soldPrice: 0,
          soldDate: "",
          soldWho: "",
        };

        const cltLocationIndex = clientList.findIndex(
          (info) => info.cltNum === selClientInfo.cltNum
        );

        if (selClientInfo.purchases[index].sn.length === 1) {
          window.alert(
            "This purchase has only one item, use the Delete button instead"
          );
        } else {
          onModProduct(changedProduct, locationIndex, stockIndex);
          onModClientPurch(cltLocationIndex, index, prods.sn);
          window.alert("Purchase product deleted");
        }
      } else {
        window.alert("Operation Canceled");
      }
    } else {
      window.alert("Operation Canceled");
    }
  }

  useEffect(() => {
    getProduct();
  }, []);

  return (
    <div>
      <div className="clientPurchaseList centerBetween LRPadding20 thinBox">
        <div className="productInput column">
          <label>Category</label>
          <input
            type="text"
            value={product.category_name}
            className="clientPurchase"
            disabled
          />
        </div>
        <div className="productInput column">
          <label>Brand</label>
          <input
            type="text"
            value={product.brand}
            className="clientPurchase"
            disabled
          />
        </div>
        <div className="productInput column">
          <label>Product Name</label>
          <input
            type="text"
            value={product.product_name}
            className="clientPurchase"
            disabled
          />
        </div>
        <div className="productInput column">
          <label>SN</label>
          <input
            type="text"
            placeholder={prods}
            className="clientPurchase"
            disabled
          />
        </div>

        <div className="productInput column">
          <label>Sold Price</label>
          <NumericFormat
            value={product.current_price}
            onValueChange={handleSoldPrice}
            thousandSeparator={true}
            suffix={"€"}
            decimalScale={2}
            fixedDecimalScale={true}
            className="clientPurchase"
            disabled={!isEditPurchActive}
          />
        </div>

        {!isEditPurchActive ? (
          <>
            <p
              onClick={() => setSeeProductInfo(true)}
              style={{ cursor: "pointer" }}
            >
              {">"}
            </p>
            {/* {seeProductInfo && (
              <div className="overlay">
                <ClientProductInfo
                  productsStock={productsStock}
                  prods={prods}
                  setSeeProductInfo={setSeeProductInfo}
                />
              </div>
            )} */}
          </>
        ) : newSoldPrice !== prods.soldPrice ? (
          <MdDone style={{ cursor: "pointer" }} onClick={changePrice} />
        ) : (
          <strong style={{ cursor: "pointer" }} onClick={delCltProduct}>
            x
          </strong>
        )}
      </div>
    </div>
  );
}
