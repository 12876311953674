import { useState } from "react";

export default function SupplierList({ supplierList, setWhichSupplier }) {
  const [background, setBackground] = useState("");
  const [selSupplier, setSelSupplier] = useState("");
  const [isBold, setIsBold] = useState("");

  function handleBackground(e) {
    setSelSupplier(e);
    setBackground("#ababab");
  }

  function handleBackgroundRes() {
    setBackground("#e6e6e6");
  }

  function handleIsBold(e) {
    setIsBold(e);
  }

  return (
    <div className="clientList LRPadding20 thinBox">
      <h2>Supplier list</h2>
      {supplierList.map((supplier, i) => (
        <SupplierListEntry
          background={i === selSupplier ? background : ""}
          vat={supplier.vat}
          suppName={supplier.name}
          suppNum={supplier.supplier_id}
          onSelectSupplier={(e) => {
            setWhichSupplier(e);
            handleIsBold(i);
          }}
          isBold={isBold}
          onHoverIn={() => handleBackground(i)}
          onHoverOut={handleBackgroundRes}
          ind={i}
        />
      ))}
    </div>
  );
}

function SupplierListEntry({
  vat,
  suppName,
  suppNum,
  onSelectSupplier,
  onHoverIn,
  onHoverOut,
  background,
  ind,
  isBold,
}) {
  const [selected, setSelected] = useState(false);

  function handleselected(e) {
    setSelected(e);
  }

  return (
    <div
      onClick={() => {
        onSelectSupplier(suppNum);
        handleselected(ind);
      }}
      onMouseEnter={onHoverIn}
      onMouseLeave={onHoverOut}
      style={{
        backgroundColor: background,
        cursor: "pointer",
      }}
    >
      <div
        className="LRPadding20 thinBox alignCenter gap10"
        style={
          isBold === selected
            ? { fontWeight: "bold" }
            : { fontWeight: "normal" }
        }
      >
        <p>{vat}</p>
        <p>-</p>
        <p>{suppName}</p>
        <p>{isBold === selected ? ">" : ""}</p>
      </div>
    </div>
  );
}
