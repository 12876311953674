import { TbUrgent } from "react-icons/tb";
import { RiTodoLine } from "react-icons/ri";
import { MdDoneOutline } from "react-icons/md";
import { useState, useEffect } from "react";
import DatePicker from "react-date-picker";
import axios from "axios";

export default function PlanInfo({ plansInfo, getPlans }) {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="inventoryPlans thinBox centerBetween">
      {!isActive ? (
        <>
          <div className="centerBetween gap50">
            <p className="inventoryPlansIcon">
              {plansInfo.planFinished ? (
                <MdDoneOutline />
              ) : plansInfo.planTimming === "Urgent" ? (
                <TbUrgent />
              ) : (
                <RiTodoLine />
              )}
            </p>
            <div className=" inventortPlansWidth">
              <p>
                {plansInfo.planFinished ? (
                  `[Done] ${plansInfo.planTitle}`
                ) : plansInfo.planTimming === "Urgent" ? (
                  <strong>[Urgent] {plansInfo.planTitle}</strong>
                ) : (
                  plansInfo.planTitle
                )}
              </p>
              <p>{plansInfo.planDescription}</p>
            </div>
          </div>
          <div className=" noMove">
            <p>Warehouse:</p>
            <p>{plansInfo.planWarehouse}</p>
          </div>
          <div className=" noMove textCenter">
            Created date:
            <DatePicker
              value={plansInfo.planEntryDate}
              clearIcon={null}
              calendarIcon={null}
              disabled
            />
          </div>
          <div className="noMove textCenter">
            {plansInfo.planFinished ? `Finished at:` : "Pending"}
            {plansInfo.planFinished ? (
              <DatePicker
                value={plansInfo.planFinishDate}
                clearIcon={null}
                calendarIcon={null}
                disabled
              />
            ) : (
              ""
            )}
          </div>
          <button onClick={() => setIsActive(!isActive)}>Edit</button>
        </>
      ) : (
        <PlanInfoEdit
          plansInfo={plansInfo}
          onActive={setIsActive}
          getPlans={getPlans}
        />
      )}
    </div>
  );
}

function PlanInfoEdit({ plansInfo, onActive, getPlans }) {
  const [planId, setPlanId] = useState(plansInfo.planNumber);
  const [planTimming, setPlanTimming] = useState(plansInfo.planTimming);
  const [planTitle, setPlanTitle] = useState(plansInfo.planTitle);
  const [planWarehouse, setPlanWarehouse] = useState(plansInfo.planWarehouse);
  const [planDescription, setPlanDescription] = useState(
    plansInfo.planDescription
  );
  const [entryDate, setEntryDate] = useState(plansInfo.planEntryDate);
  const [finishDate, setFinishDate] = useState(plansInfo.planFinishDate);
  const [planFinished, setPlanFinished] = useState(plansInfo.planFinished);
  const [warehouseList, setWarehouseList] = useState([]);
  const timmingArr = ["Urgent", "Normal"];

  function getWarehouse() {
    axios
      .get("https://caravela.dev/gestor-bolso-api/productItems/warehouses", {
        withCredentials: true,
      })
      .then((resp) => {
        setWarehouseList(resp.data.data);
      });
  }

  function handleSubmit(e) {
    e.preventDefault();

    console.log(finishDate);

    if (planFinished && (finishDate === null || finishDate === "")) {
      window.alert("Please insert a finish date");
    } else if (!planFinished && finishDate?.length > 0) {
      window.alert("Please remove the finish date");
    } else if (planTitle === "") {
      window.alert("Please insert a title");
    } else
      axios
        .put(
          `https://caravela.dev/gestor-bolso-api/planning`,
          {
            id: +planId,
            timming: planTimming,
            title: planTitle,
            warehouse: planWarehouse,
            description: planDescription,
            finish_date: finishDate ? finishDate : null,
            finished: planFinished,
          },
          {
            withCredentials: true,
          }
        )
        .then((res) => {
          if (res.data.ok) {
            getPlans();
            onActive(false);
            window.alert("Plan edited");
          }
        });
  }

  useEffect(() => {
    getWarehouse();
  }, []);

  function handleDelPlan(e) {
    e.preventDefault();
    if (
      window.confirm(
        `Are you sure you want to delete ${planTitle} of ${entryDate.slice(
          0,
          10
        )}`
      )
    ) {
      if (
        window.confirm(`Are you REALLY sure you want to delete ${planTitle}?`)
      ) {
        axios
          .delete("https://caravela.dev/gestor-bolso-api/planning", {
            withCredentials: true,
            data: {
              id: +planId,
            },
          })
          .then((resp) => {
            if (resp.data.ok) {
              window.confirm(`${planTitle} deleted`);
              getPlans();
              onActive(false);
            }
          });
      }
    } else {
      window.alert("Operation cancelled");
    }
  }

  const handleCheckboxChange = () => {
    setPlanFinished(!planFinished);
  };

  const handleFinishedDateChange = (date) => {
    if (date) {
      let day = date.getDate();
      let month = date.getMonth() + 1;
      const year = date.getFullYear();
      day = day < 10 ? `0${day}` : day;
      month = month < 10 ? `0${month}` : month;
      const formattedDate = `${year}-${month}-${day}`;
      setFinishDate(formattedDate);
    } else {
      setFinishDate("");
    }
  };

  return (
    <form>
      <div>
        <ul className="planInputTable">
          <div className="planInformation centerBetween">
            <div>
              <label>Timming:</label>
              <div>
                <select
                  className="filter larger"
                  value={planTimming}
                  onChange={(e) => setPlanTimming(e.target.value)}
                  defaultChecked
                >
                  {timmingArr.map((tim, i) => (
                    <option value={tim} key={"tim" + i}>
                      {tim}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div>
              <label>Title</label>
              <input
                type="text"
                value={planTitle}
                onChange={(e) => setPlanTitle(e.target.value)}
                className="productInput column"
              />
            </div>

            <div>
              <label>Warehouse</label>
              <div>
                <select
                  className="filter larger"
                  value={planWarehouse}
                  onChange={(e) => setPlanWarehouse(e.target.value)}
                >
                  {warehouseList.map((ware, i) => (
                    <option value={ware.warehouse_name} key={"warehouse" + i}>
                      {ware.warehouse_name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="column">
              <label>Entry date</label>
              <DatePicker
                className="dates"
                value={entryDate}
                clearIcon={null}
                calendarIcon={null}
                disabled
              />
            </div>

            {planFinished ? (
              <div className="column">
                <label>Finished date</label>
                <DatePicker
                  className="dates"
                  value={finishDate}
                  onChange={handleFinishedDateChange}
                  clearAriaLabel={"Clear value"}
                />
              </div>
            ) : (
              ""
            )}

            <div>
              <label>Finished?</label>
              <input
                type="checkbox"
                checked={planFinished}
                onChange={handleCheckboxChange}
              />
            </div>
          </div>

          <div className="column gap10">
            <div>
              <label>Description</label>
              <textarea
                type="text"
                value={planDescription}
                onChange={(e) => setPlanDescription(e.target.value)}
                className="productInput column planInfoEditDescrip"
              />
            </div>
            <div className="spaceBetween">
              <button className="confirmButton delete" onClick={handleDelPlan}>
                Delete
              </button>
              <button className="confirmButton" onClick={handleSubmit}>
                Confirm
              </button>
            </div>
          </div>
        </ul>
      </div>
    </form>
  );
}
