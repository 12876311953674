import { useState, useEffect } from "react";
import { TbHomePlus } from "react-icons/tb";
import { TbCubePlus } from "react-icons/tb";
import { PiCirclesThreePlusLight } from "react-icons/pi";
import ModifyWarehouse from "./ModifyWarehouse";
import ModifyCategory from "./ModifyCategory";
import CreateProduct from "./CreateProduct";

export default function SearchProduct({
  onSearch,
  categoryList,
  getWarehouses,
  getCategories,
  getProducts,
  choosed,
}) {
  const [search, setSearch] = useState("");
  const [inStock, setInStock] = useState("all");
  const [isActiveWar, setIsActiveWar] = useState(false);
  const [isActiveProd, setIsActiveProd] = useState(false);
  const [isActiveCat, setIsActiveCat] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSearch(search, inStock);
  };

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  const handleChangeStock = (e) => {
    setInStock(e.target.value);
  };

  useEffect(() => {
    onSearch(search, inStock);
  }, [search, inStock]);

  return (
    <>
      <div className="centerBetween">
        <form onSubmit={handleSubmit}>
          <div className="alignCenter gap10">
            <input
              type="text"
              placeholder="🔍 Search"
              value={search}
              onChange={handleChange}
            />
            <label>Filter:</label>
            <select
              className="filter"
              value={inStock}
              onChange={handleChangeStock}
            >
              <option value="all">All</option>
              <option value="true">Stock</option>
              <option value="false">No Stock</option>
            </select>
          </div>
        </form>
        <div className="alignCenter gap10">
          <p className="alignCenter gap10">
            {choosed !== "prods" && (
              <button
                className="searchButton alignCenter"
                onClick={() => setIsActiveWar(true)}
              >
                <TbHomePlus />
              </button>
            )}
            {choosed === "prods" && (
              <button
                className="searchButton alignCenter"
                onClick={() => setIsActiveProd(true)}
              >
                <TbCubePlus />
              </button>
            )}
            {choosed === "prods" && (
              <button
                className="searchButton alignCenter"
                onClick={() => setIsActiveCat(true)}
              >
                <PiCirclesThreePlusLight />
              </button>
            )}
          </p>
        </div>
      </div>
      <div>
        <div
          className={
            isActiveWar || isActiveProd || isActiveCat ? "overlay" : ""
          }
        >
          {isActiveWar && (
            <ModifyWarehouse
              getWarehouses={getWarehouses}
              onClickDes={() => setIsActiveWar(false)}
            />
          )}

          {isActiveProd && (
            <CreateProduct
              onClickDes={() => setIsActiveProd(false)}
              categoryList={categoryList}
              getProducts={getProducts}
            />
          )}
          {isActiveCat && (
            <ModifyCategory
              onClickDes={() => setIsActiveCat(false)}
              categoryList={categoryList}
              getCategories={getCategories}
            />
          )}
        </div>
      </div>
    </>
  );
}
