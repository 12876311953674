import { PiWarehouse } from "react-icons/pi";
import { IoChevronUpSharp } from "react-icons/io5";
import { IoChevronDownSharp } from "react-icons/io5";
import { FaArrowRight } from "react-icons/fa";
import { useState, useEffect } from "react";
import WarehouseInventory from "./WarehouseInventory";
import WarehouseCompound from "./WarehouseCompound";
import axios from "axios";

export default function WarehousePage({
  productsStock,
  compoundStock,
  clientList,
  category,
  onModProduct,
  onModProdWare,
  onDelProduct,
  warehouses,
}) {
  return (
    <div className="warehousePage" key="warehousePage">
      {warehouses.map((warehouse, i) => (
        <WarehouseHeader
          wareName={warehouse.warehouse_name}
          wareStock={warehouse.product_items}
          wareID={warehouse.warehouse_id}
          index={i}
          //stockList={stockList}
          // stock={warehouse.stock}
          // compound={warehouse.compound}
          key={"ware" + i}
        >
          {warehouses[i].product_items.map((product) => (
            <WarehouseInventory
              key={product.sn}
              //productCategory={product.category}
              product={product}
              // clientList={clientList}
              // warArray={productsStock}
              // wareName={warehouse.name}
              // existCategory={category}
              // onModProduct={onModProduct}
              // onModProdWare={onModProdWare}
              // onDelProduct={onDelProduct}
            />
          ))}

          {/* {warehouse.compound.map((product) => (
            <WarehouseCompound
              key={product.sn}
              productCategory={product.category}
              product={product}
              clientList={clientList}
              warArray={productsStock}
              wareName={warehouse.name}
              existCategory={category}
              onModProduct={onModProduct}
              onModProdWare={onModProdWare}
              onDelProduct={onDelProduct}
              compoundStock={compoundStock}
            />
          ))} */}
        </WarehouseHeader>
      ))}
    </div>
  );
}

function WarehouseHeader({ wareName, wareID, wareStock, compound, children }) {
  const isAvailable = wareStock.filter(
    (product) => product.state === "In Stock"
  );
  // const isAvailableComp = compound.filter((product) => product.available);
  const [isActive, setIsActive] = useState(false);

  //const separateStock = stockList.reduce  {stock.length + compound.length} -- Items Available:{" "}

  return (
    <div className="WarehouseHeader">
      <div
        className="centerBetween thinBox LRPadding20"
        onClick={() => setIsActive(!isActive)}
      >
        <header className="alignCenter gap10">
          <p className="alignCenter gap10">
            <PiWarehouse /> {wareName} <FaArrowRight />
          </p>
          <p>
            Total Items avaible:
            {isAvailable.length}
          </p>
        </header>
        <div className="expandButton">
          {isActive ? <IoChevronUpSharp /> : <IoChevronDownSharp />}
        </div>
      </div>
      <div>{isActive && children}</div>
    </div>
  );
}
