import { useState, useEffect } from "react";
import axios from "axios";
import DatePicker from "react-date-picker";

export default function CreateProduct({
  onClickDes,
  categoryList,
  getProducts,
}) {
  const [productNum, setProductNum] = useState("");
  const [category, setCategory] = useState("");
  const [prodBrand, setProdBrand] = useState("");
  const [productName, setProductName] = useState("");
  const [prodSKU, setProdSKU] = useState("");
  const [prodEAN, setProdEAN] = useState("");

  function handleNewProduct(e) {
    e.preventDefault();

    if (productName.length > 2) {
      axios
        .post(
          `https://caravela.dev/gestor-bolso-api/product`,
          {
            name: productName,
            sku: prodSKU,
            ean: prodEAN,
            category_id: category,
            brand: prodBrand,
          },
          {
            withCredentials: true,
          }
        )
        .then((res) => {
          if (res.data.ok) {
            window.alert(`Product ${productName} created`);
            getProducts();
            onClickDes();
          }
        });
    } else {
      window.alert("You need to input at least 3 characters");
    }
  }

  useEffect(() => {
    //handleWarehouses();
  }, []);

  return (
    <form className="creatProductForm" onSubmit={handleNewProduct}>
      <header className="centerBetween">
        <h1>Create New Product</h1>
        <h3 onClick={onClickDes} style={{ cursor: "pointer" }}>
          X
        </h3>
      </header>
      <h2>Product Information</h2>
      <div className="productInformation">
        <ul className="productInputTable centerBetween">
          <div className="clientTypeOfLab column">
            <label>Category</label>
            <select
              type="text"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              className="clientTypeOf"
            >
              <option value={""}>Select a Category</option>
              {categoryList.map((category, i) => (
                <option value={category.category_id} key={"category" + i}>
                  {category.name}
                </option>
              ))}
            </select>
          </div>

          <div>
            <div className="column gap10">
              <div>
                <label>Product Number</label>{" "}
                <input style={{ width: `60px` }} value={productNum} disabled />{" "}
              </div>
              <div>
                <label>Since Date:</label>{" "}
                <DatePicker
                  value={new Date()}
                  clearIcon={null}
                  calendarIcon={null}
                  disabled
                />
              </div>
            </div>
          </div>

          <div>
            <label>Brand</label>
            <input
              type="text"
              value={prodBrand}
              onChange={(e) => setProdBrand(e.target.value)}
              className="productInput column"
            />
          </div>

          <div>
            <label>Name</label>
            <input
              type="text"
              value={productName}
              onChange={(e) => setProductName(e.target.value)}
              className="productInput column"
            />
          </div>

          <div>
            <label>SKU</label>
            <input
              type="text"
              value={prodSKU}
              onChange={(e) => setProdSKU(e.target.value)}
              className="productInput column"
            />
          </div>

          <div>
            <label>EAN</label>
            <input
              type="text"
              value={prodEAN}
              onChange={(e) => setProdEAN(e.target.value)}
              className="productInput column"
            />
          </div>

          <button>Create</button>
        </ul>
      </div>
    </form>
  );
}
