import DatePicker from "react-date-picker";
import { useState, useEffect } from "react";
import { NumericFormat } from "react-number-format";
import axios from "axios";

export default function SupplierArrivingButton({
  selSupplierInfo,
  setIsActive,
}) {
  const [arrivingList, setArrivingList] = useState([]);
  const [arrivalDate, setArrivalDate] = useState("");
  const [productsList, setProductsList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [prodsToAdd, setProdsToAdd] = useState([]);

  function getProducts() {
    axios
      .get("https://caravela.dev/gestor-bolso-api/products", {
        withCredentials: true,
      })
      .then((resp) => {
        setProductsList(resp.data.data);
      });
  }

  function getCategories() {
    axios
      .get("https://caravela.dev/gestor-bolso-api/categories", {
        withCredentials: true,
      })
      .then((resp) => {
        setCategoryList(resp.data);
        console.log(resp.data);
      });
  }

  const [background, setBackground] = useState("");
  const [selProduct, setSelClient] = useState("");
  const [isBold, setIsBold] = useState("");
  function handleBackground(e) {
    setSelClient(e);
    setBackground("#ababab");
  }

  function handleBackgroundRes() {
    setBackground("");
  }

  function handleIsBold(e) {
    setIsBold(e);
  }

  function handleAddtoList(prodID, prodCat, bra, prodName, prodSku, prodEan) {
    const isSkuExisting = arrivingList.some((item) => item.sku === prodSku);

    if (!isSkuExisting) {
      const updatedSaleList = [
        ...arrivingList,
        {
          product_id: prodID,
          category_id: prodCat,
          brand: bra,
          name: prodName,
          sku: prodSku,
          ean: prodEan,
        },
      ];
      setArrivingList(updatedSaleList);
    } else {
      window.alert(`${prodName} already exists in the list.`);
    }
  }

  function handleRemOnArr(pSKU) {
    let text = "Are you sure you want to remove this product?";
    if (window.confirm(text) === true) {
      setArrivingList((prevList) => {
        return prevList.filter((item) => item.sku !== pSKU);
      });
    }
  }

  function handleFinishArrivingList(e) {
    e.preventDefault();

    console.log(selSupplierInfo.supplier_id, arrivalDate, prodsToAdd);

    if (arrivalDate === "") {
      window.alert("Please insert an arrival date");
    } else if (prodsToAdd.length < arrivingList.length) {
      window.alert("Please insert add all the products on the arrival list");
    } else {
      axios
        .post(
          `https://caravela.dev/gestor-bolso-api/supplierOrder`,
          {
            supplier_id: selSupplierInfo.supplier_id,
            arriving_date: arrivalDate,
            products: prodsToAdd,
          },
          {
            withCredentials: true,
          }
        )

        .then((resp) => {
          if (resp.data.ok) {
            setIsActive(false);
            window.alert("New arriving created!");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  const handleArrivalDateChange = (date) => {
    let day = date.getDate();
    let month = date.getMonth() + 1;
    const year = date.getFullYear();
    day = day < 10 ? `0${day}` : day;
    month = month < 10 ? `0${month}` : month;
    const formattedDate = `${year}/${month}/${day}`;
    setArrivalDate(formattedDate);
  };

  useEffect(() => {
    getProducts();
    getCategories();
  }, []);

  return (
    <div className="creatSuppArr">
      <header className="centerBetween">
        <h1>
          Create a new arriving: {selSupplierInfo.vat} - {selSupplierInfo.name}{" "}
        </h1>
        <h3 onClick={() => setIsActive(false)} style={{ cursor: "pointer" }}>
          X
        </h3>
      </header>
      <div className="alignStart gap10">
        <div className="suppProdList LRPadding20 thinBox ">
          <div>
            <h2>Product list</h2>
            {productsList.map((prod, i) => (
              <ProductID
                background={i === selProduct ? background : ""}
                brand={prod.brand}
                sku={prod.sku}
                prodNum={prod.product_id}
                onSelectClient={() => {
                  handleAddtoList(
                    prod.product_id,
                    prod.category_id,
                    prod.brand,
                    prod.name,
                    prod.sku,
                    prod.ean
                  );
                  handleIsBold(i);
                }}
                isBold={isBold}
                onHoverIn={() => handleBackground(i)}
                onHoverOut={handleBackgroundRes}
                ind={i}
              />
            ))}
          </div>
        </div>

        <div className="suppArrList thinBox">
          {arrivingList.length === 0 ? (
            <h3 className="LRPadding20">list is empty</h3>
          ) : (
            <>
              {arrivingList.map((item) => (
                <ProductArrivingInfo
                  item={item}
                  categoryList={categoryList}
                  onRemOnArr={handleRemOnArr}
                  setProdsToAdd={setProdsToAdd}
                  prodsToAdd={prodsToAdd}
                />
              ))}
              <p>
                Arriving:{" "}
                <DatePicker
                  value={arrivalDate}
                  clearIcon={null}
                  onChange={handleArrivalDateChange}
                  onClick={(e) => e.stopPropagation()}
                />
              </p>
              <button onClick={handleFinishArrivingList}>
                Create Arriving
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

function ProductID({
  brand,
  sku,
  prodNum,
  onSelectClient,
  onHoverIn,
  onHoverOut,
  background,
  ind,
  isBold,
}) {
  const [selected, setSelected] = useState(false);

  function handleselected(e) {
    setSelected(e);
  }

  return (
    <div
      onClick={() => {
        onSelectClient(prodNum);
        handleselected(ind);
      }}
      onMouseEnter={onHoverIn}
      onMouseLeave={onHoverOut}
      style={{
        backgroundColor: background,
        cursor: "pointer",
      }}
    >
      <div
        className="LRPadding20 thinBox alignCenter gap10"
        style={
          isBold === selected
            ? { fontWeight: "bold" }
            : { fontWeight: "normal" }
        }
      >
        <p>{brand}</p>
        <p>-</p>
        <p>{sku}</p>
        <p>{isBold === selected ? ">" : ""}</p>
      </div>
    </div>
  );
}

function ProductArrivingInfo({
  item,
  categoryList,
  onRemOnArr,
  setProdsToAdd,
  prodsToAdd,
}) {
  const [purchaseValue, setPurchaseValue] = useState(0);
  const [units, setUnits] = useState(0);
  const [added, setAdded] = useState(false);

  function handlePurchValue(price) {
    const newValue = parseFloat(price.floatValue);
    setPurchaseValue(newValue);
  }

  const foundCategory = categoryList.findIndex(
    (cat) => cat.category_id === +item.category_id
  );

  function handleAddToArriving() {
    if (purchaseValue === 0 && units === 0) {
      window.alert("Please, insert price and units on the product");
      setAdded(false);
    } else {
      const updatedSaleList = [
        ...prodsToAdd,
        {
          product_id: item.product_id,
          quantity: units,
          cost_price: purchaseValue,
        },
      ];
      setProdsToAdd(updatedSaleList);
    }
    console.log(prodsToAdd);
  }

  return (
    <>
      <div className="suppArrListUnits thinBox LRPadding20">
        <div className="centerBetween">
          <p>
            - {categoryList[foundCategory].name} / {item.brand} / {item.name} /
            SKU: {item.sku} / EAN: {item.ean}
          </p>
          <strong
            onClick={() => onRemOnArr(item.sku)}
            style={{ cursor: "pointer" }}
          >
            X
          </strong>
        </div>
        <div div className="alignCenter gap10">
          <p>
            Price:{" "}
            <NumericFormat
              value={purchaseValue}
              onValueChange={handlePurchValue}
              thousandSeparator={true}
              suffix={"€"}
              decimalScale={2}
              fixedDecimalScale={true}
              className="clientPurchase"
              onClick={(e) => e.stopPropagation()}
              disabled={added}
            />
          </p>

          <p>
            Units:{" "}
            <input
              type="number"
              value={units}
              onChange={(e) => setUnits(e.target.value)}
              className="clientPurchase"
              onClick={(e) => e.stopPropagation()}
              disabled={added}
            />
          </p>
          <button
            onClick={(e) => {
              setAdded(true);
              handleAddToArriving();
              e.stopPropagation();
            }}
          >
            Add
          </button>
        </div>
      </div>
    </>
  );
}
