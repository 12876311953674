import { useState, useEffect } from "react";
import ClientWareheader from "./ClientWareheader";
import CheckSaleList from "./CheckSaleList";
import axios from "axios";

export default function ClientPurchasesButton({
  selClientInfo,
  productsStock,
  setIsActive,
  onAddClientPurch,
  clientList,
  onModProduct,
  onModCompound,
}) {
  const [saleList, setSaleList] = useState([]);
  const [search, setSearch] = useState("");
  const [inCategory, setInCategory] = useState("all");
  const [productSearched, setProductSearched] = useState(productsStock);
  const [checkSale, setCheckSale] = useState(false);
  const [productsList, setProductsList] = useState([]);

  function getWarehouses() {
    axios
      .get("https://caravela.dev/gestor-bolso-api/productItems/warehouses", {
        withCredentials: true,
      })
      .then((resp) => {
        setProductsList(resp.data.data);
        console.log(resp.data.data);
      });
  }

  const handleChange = (e) => {
    const searchText = e.target.value;
    setSearch(searchText);
    handleSearch(searchText, inCategory);
  };

  const handleChangeCat = (e) => {
    const category = e.target.value;
    setInCategory(category);
    handleSearch(search, category);
  };

  function handleAddtoSale(prodID, cat, bra, pName, pSN, purValue, ware, pvp) {
    const isSNExist = saleList.some((item) => item.sn === pSN);

    if (isSNExist) {
      alert("This serial number already exists in the sale list!");
    } else {
      const updatedSaleList = [
        ...saleList,
        {
          product_item_id: prodID,
          category: cat,
          brand: bra,
          productName: pName,
          sn: pSN,
          purchaseValue: purValue,
          pricePVP: pvp,
          warehouse: ware,
        },
      ];
      setSaleList(updatedSaleList);
    }
  }

  function handleSearch(e, inCategory) {
    setProductSearched(productsStock);
    let newSearch = productsStock.map((warehouse) => ({
      ...warehouse,
      stock: warehouse.stock.filter((product) =>
        Object.values(product).some(
          (value) =>
            (typeof value === "string" || typeof value === "number") &&
            String(value).toLowerCase().includes(String(e).toLowerCase())
        )
      ),
    }));

    if (inCategory !== "all") {
      newSearch = productsStock.map((warehouse) => ({
        ...warehouse,
        stock: warehouse.stock.filter((product) =>
          Object.values(product).some(
            (value) =>
              (typeof value === "string" || typeof value === "number") &&
              String(value)
                .toLowerCase()
                .includes(String(inCategory).toLowerCase())
          )
        ),
      }));
    }
    setProductSearched(newSearch);
  }

  function handleRemOnSale(pSN) {
    setSaleList((prevList) => {
      return prevList.filter((item) => item.sn !== pSN);
    });
  }

  const categoryUsedFilter = productsStock.reduce((acc, warehouse) => {
    warehouse.stock.forEach((product) => {
      if (product.available) {
        if (!acc[product.category]) {
          acc[product.category] = [];
        }
        acc[product.category].push(product);
      }
    });
    return acc;
  }, {});

  const categoryUsed = Object.keys(categoryUsedFilter);

  function handleCreateButton() {
    if (saleList.length === 0) {
      window.alert("Please, add some items to the list");
    } else {
      setCheckSale(true);
    }
  }

  useEffect(() => {
    getWarehouses();
  }, []);

  return (
    <div className="creatCltPurch">
      {!checkSale ? (
        <>
          <header className="centerBetween">
            <h1>
              Create New Sale: {selClientInfo.vat} - {selClientInfo.name}
            </h1>
            <h3
              onClick={() => setIsActive(false)}
              style={{ cursor: "pointer" }}
            >
              X
            </h3>
          </header>

          <div className="spaceBetween">
            <div className="cltProdList">
              <div>
                <div className="clientSearchInv">
                  <h2>Products List</h2>
                  <input
                    type="text"
                    placeholder="🔍 Search"
                    value={search}
                    onChange={handleChange}
                  />{" "}
                  <label>Filter:</label>{" "}
                  <select
                    className="filter"
                    value={inCategory}
                    onChange={handleChangeCat}
                  >
                    <option value={"all"}>All</option>
                    {categoryUsed.map((cat) => (
                      <option value={cat}>{cat}</option>
                    ))}
                  </select>
                </div>
                {productsList.map((ware) => (
                  <ClientWareheader ware={ware} onAddtoSale={handleAddtoSale} />
                ))}
              </div>
            </div>
            <div className="cltProdList">
              <h2>Sale List</h2>
              <div className="clientSearchInv">
                <button onClick={handleCreateButton} className="filter">
                  Create
                </button>
              </div>
              <div
                className={
                  saleList.length > 0 ? "clientHeader" : "clientHeader thinBox"
                }
              >
                {saleList.length === 0 ? (
                  <p className="LRPadding20">Sale list is empty</p>
                ) : (
                  saleList.map((item) => (
                    <div
                      className="alignCenter thinBox gap10 LRPadding20"
                      onClick={() => handleRemOnSale(item.sn)}
                      style={{ cursor: "pointer" }}
                    >
                      <p> {"<"}</p>
                      <p>{item.category} /</p>
                      <p>{item.brand} /</p>
                      <p>{item.productName} /</p>
                      <p>SN: {item.sn}</p>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <CheckSaleList
          selClientInfo={selClientInfo}
          saleList={saleList}
          setCheckSale={setCheckSale}
          onAddClientPurch={onAddClientPurch}
          clientList={clientList}
          onModProduct={onModProduct}
          onModCompound={onModCompound}
          productsStock={productsStock}
          setIsActive={setIsActive}
        />
      )}
    </div>
  );
}
