import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import { useState, useEffect } from "react";
import axios from "axios";

//ALTERAR O FINISH DATE PARA APARECER

export default function CreatePlan({ getPlans }) {
  const [isActive, setIsActive] = useState(false);

  return (
    <div>
      <header className="centerBetween">
        <h1>Inventory Plan</h1>
        <button onClick={() => setIsActive(true)} style={{ cursor: "pointer" }}>
          + Create New Plan
        </button>
      </header>
      <div className={isActive ? "overlay" : ""}>
        {isActive && (
          <CreatePlanButton
            onClickDes={() => setIsActive(false)}
            getPlans={getPlans}
          />
        )}
      </div>
    </div>
  );
}

function CreatePlanButton({ onClickDes, getPlans }) {
  const [planTimming, setPlanTimming] = useState("");
  const [planTitle, setPlanTitle] = useState("");
  const [planWarehouse, setPlanWarehouse] = useState("");
  const [planDescription, setPlanDescription] = useState("");
  const [finishDate, setFinishDate] = useState("");
  const [planFinished, setPlanFinished] = useState(false);
  const [warehouseList, setWarehouseList] = useState([]);

  const timmingArr = ["Urgent", "Normal"];

  function getWarehouse() {
    axios
      .get("https://caravela.dev/gestor-bolso-api/productItems/warehouses", {
        withCredentials: true,
      })
      .then((resp) => {
        setWarehouseList(resp.data.data);
      });
  }

  function handleSubmit(e) {
    e.preventDefault();

    console.log(finishDate);

    if (planTimming === "") {
      window.alert("Please, insert a timming");
    } else if (planTitle === "") {
      window.alert("Please, insert a title");
    } else if (planWarehouse === "") {
      window.alert("Please, select a warehouse");
    } else {
      axios
        .post(
          `https://caravela.dev/gestor-bolso-api/planning`,
          {
            timming: planTimming,
            title: planTitle,
            warehouse: planWarehouse,
            description: planDescription ? planDescription : "",
            finish_date: finishDate ? finishDate : null,
            finished: planFinished,
          },
          {
            withCredentials: true,
          }
        )
        .then((res) => {
          if (res.data.ok) {
            window.alert(`Plan ${planTitle} created`);
            onClickDes();
            getPlans();
          }
        });
    }
  }

  useEffect(() => {
    getWarehouse();
  }, []);

  const handleFinishedDateChange = (date) => {
    if (date) {
      let day = date.getDate();
      let month = date.getMonth() + 1;
      const year = date.getFullYear();
      day = day < 10 ? `0${day}` : day;
      month = month < 10 ? `0${month}` : month;
      const formattedDate = `${year}-${month}-${day}`;
      setFinishDate(formattedDate);
    } else {
      setFinishDate("");
    }
  };

  return (
    <form className="creatProductForm" onSubmit={handleSubmit}>
      <header className="centerBetween">
        <h1>Create New Plan</h1>
        <h3 onClick={onClickDes} style={{ cursor: "pointer" }}>
          X
        </h3>
      </header>
      <h2>Plan Information</h2>
      <div className="productInformation">
        <ul className="productInputTable centerBetween">
          <div>
            <label>Timming of the plan</label>
            <div>
              <select
                className="filter larger"
                value={planTimming}
                onChange={(e) => setPlanTimming(e.target.value)}
                defaultChecked
              >
                <option value={""}>Select a Timming</option>
                {timmingArr.map((tim, i) => (
                  <option value={tim} key={"tim" + i}>
                    {tim}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div>
            <label>Warehouse</label>
            <div>
              <select
                className="filter larger"
                value={planWarehouse}
                onChange={(e) => setPlanWarehouse(e.target.value)}
                defaultChecked
              >
                <option value={""}>Select a Warehouse</option>
                {warehouseList.map((warehouse, i) => (
                  <option
                    value={warehouse.warehouse_name}
                    key={"warehouse" + i}
                  >
                    {warehouse.warehouse_name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div>
            <label>Title</label>
            <input
              type="text"
              value={planTitle}
              onChange={(e) => setPlanTitle(e.target.value)}
              className="newPlanTitle column"
            />
          </div>

          <div>
            <label>Description</label>
            <textarea
              type="text"
              value={planDescription}
              onChange={(e) => setPlanDescription(e.target.value)}
              className="newPlanDescrip column"
            />
          </div>

          <div className="column">
            <label>Entry date</label>
            <DatePicker
              className="dates"
              value={new Date()}
              clearIcon={null}
              calendarIcon={null}
              disabled
            />
          </div>

          <div className="alignCenter">
            <label>Finished?</label>
            <input
              type="checkbox"
              checked={planFinished}
              onChange={() => setPlanFinished(!planFinished)}
            />
          </div>

          {planFinished && (
            <div className="column">
              <label>Finished date</label>
              <DatePicker
                className="dates"
                value={finishDate}
                onChange={handleFinishedDateChange}
                clearAriaLabel={"Clear value"}
              />
            </div>
          )}

          <button>Create</button>
        </ul>
      </div>
    </form>
  );
}
