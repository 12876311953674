import { useState, useEffect, useCallback } from "react";
import CompoundWare from "./CompoundWare";
import CompoundList from "./CompoundList";
import axios from "axios";

export default function CreateCompound({
  warehouses,
  category,
  onAddCompound,
  onModProduct,
  choosed,
  setChoosed,
}) {
  const [isActive, setIsActive] = useState(false);
  const [chooseTab, setChooseTab] = useState("prods");
  const [prodsSN, setProdsSN] = useState([]);
  const [showingSN, setShowingSN] = useState([]);

  const getProductsSN = useCallback(() => {
    axios
      .get("https://caravela.dev/gestor-bolso-api/productItems", {
        withCredentials: true,
      })
      .then((resp) => {
        setShowingSN([]);
        setProdsSN(resp.data.data);
      });
    // .then(() => {
    //   prodsSN.forEach((prodSN) => {
    //     if (
    //       selWhichProduct.product_item_ids.includes(prodSN.product_item_id)
    //     ) {
    //       setShowingSN((showSN) => [...showSN, prodSN]);
    //     }
    //   });
    // });
  }, []);

  console.log();

  useEffect(() => {
    getProductsSN();
  }, [getProductsSN]);

  return (
    <div>
      <header className="centerBetween">
        <div className="alignCenter gap50">
          <div
            onClick={() => setChoosed("prods")}
            style={{ cursor: "pointer" }}
            onMouseEnter={() => setChooseTab("prods")}
            onMouseLeave={() => setChooseTab(choosed)}
          >
            {chooseTab === "prods" ? (
              <h1>See Products</h1>
            ) : (
              <p>See Products</p>
            )}{" "}
          </div>
          <h1>/</h1>
          <div
            onClick={() => setChoosed("wares")}
            style={{ cursor: "pointer" }}
            onMouseEnter={() => setChooseTab("wares")}
            onMouseLeave={() => setChooseTab(choosed)}
          >
            {chooseTab === "wares" ? (
              <h1>See Warehouses</h1>
            ) : (
              <p>See Warehouses</p>
            )}{" "}
          </div>
        </div>
        <button onClick={() => setIsActive(true)} style={{ cursor: "pointer" }}>
          + Create New Compound
        </button>
      </header>
      {isActive && (
        <div className="overlay">
          <CreateProductButton
            onClickDes={() => setIsActive(false)}
            warehouses={warehouses}
            category={category}
            onAddCompound={onAddCompound}
            onModProduct={onModProduct}
          />
        </div>
      )}
    </div>
  );
}

function CreateProductButton({
  onClickDes,
  warehouses,
  category,
  onAddCompound,
  onModProduct,
}) {
  const [compoundList, setCompoundList] = useState([]);
  const [search, setSearch] = useState("");
  const [inCategory, setInCategory] = useState("all");
  const [productSearched, setProductSearched] = useState(warehouses);
  const [checkList, setCheckList] = useState(false);

  const handleChange = (e) => {
    const searchText = e.target.value;
    setSearch(searchText);
    handleSearch(searchText, inCategory);
  };

  const handleChangeCat = (e) => {
    const category = e.target.value;
    setInCategory(category);
    handleSearch(search, category);
  };

  function handleSearch(e, inCategory) {
    setProductSearched(warehouses);
    let newSearch = warehouses.map((warehouse) => ({
      ...warehouse,
      stock: warehouse.stock.filter((product) =>
        Object.values(product).some(
          (value) =>
            (typeof value === "string" || typeof value === "number") &&
            String(value).toLowerCase().includes(String(e).toLowerCase())
        )
      ),
    }));

    if (inCategory !== "all") {
      newSearch = warehouses.map((warehouse) => ({
        ...warehouse,
        stock: warehouse.stock.filter((product) =>
          Object.values(product).some(
            (value) =>
              (typeof value === "string" || typeof value === "number") &&
              String(value)
                .toLowerCase()
                .includes(String(inCategory).toLowerCase())
          )
        ),
      }));
    }
    setProductSearched(newSearch);
  }

  function handleAddtoSale(cat, bra, pName, pSKU, pSN, purValue, ware, proID) {
    const isSNExist = compoundList.some((item) => item.sn === pSN);

    if (isSNExist) {
      alert("This serial number already exists in the sale list!");
    } else {
      const updatedSaleList = [
        ...compoundList,
        {
          category: cat,
          brand: bra,
          productName: pName,
          sku: pSKU,
          sn: pSN,
          current_price: purValue,
          warehouse: ware,
          product_item_id: proID,
        },
      ];
      setCompoundList(updatedSaleList);
    }
  }

  function handleRemOnSale(pSN) {
    setCompoundList((prevList) => {
      return prevList.filter((item) => item.sn !== pSN);
    });
  }

  // const categoryUsedFilter = warehouses.reduce((acc, warehouse) => {
  //   warehouse.stock.forEach((product) => {
  //     if (product.available) {
  //       if (!acc[product.category]) {
  //         acc[product.category] = [];
  //       }
  //       acc[product.category].push(product);
  //     }
  //   });
  //   return acc;
  // }, {});

  // const categoryUsed = Object.keys(categoryUsedFilter);

  return (
    <>
      {!checkList ? (
        <form className="creatCltPurch">
          <header className="centerBetween">
            <h1>Create New Compound</h1>
            <h3 onClick={onClickDes} style={{ cursor: "pointer" }}>
              X
            </h3>
          </header>
          <div className="spaceBetween">
            <div className="cltProdList">
              <div>
                <div className="clientSearchInv">
                  <h2>Products List</h2>
                  <input
                    type="text"
                    placeholder="🔍 Search"
                    value={search}
                    onChange={handleChange}
                  />{" "}
                  <label>Filter:</label>{" "}
                  <select
                    className="filter"
                    value={inCategory}
                    onChange={handleChangeCat}
                  >
                    <option value={"all"}>All</option>
                    {/* {categoryUsed.map((cat) => (
                      <option value={cat}>{cat}</option>
                    ))} */}
                  </select>
                </div>
                {productSearched.map((ware) => (
                  <CompoundWare ware={ware} onAddtoSale={handleAddtoSale} />
                ))}
              </div>
            </div>
            <div className="cltProdList">
              <h2>Compound List</h2>
              <div className="clientSearchInv">
                <button onClick={() => setCheckList(true)} className="filter">
                  Create
                </button>
              </div>
              <div
                className={
                  compoundList.length > 0
                    ? "clientHeader"
                    : "clientHeader thinBox"
                }
              >
                {compoundList.length === 0 ? (
                  <p className="LRPadding20">Compound list is empty</p>
                ) : (
                  compoundList.map((item) => (
                    <div
                      className="centerBetween thinBox gap10 LRPadding20"
                      onClick={() => handleRemOnSale(item.sn)}
                      style={{ cursor: "pointer" }}
                    >
                      <div> {"<"}</div>
                      <div className="displayProducts gap10">
                        <div>{item.category} /</div>
                        <div>{item.brand} /</div>
                        <div>{item.productName} /</div>
                        <div>SKU: {item.sku} /</div>
                        <div>SN: {item.sn}</div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </form>
      ) : (
        <CompoundList
          //selClientInfo={selClientInfo}
          compoundList={compoundList}
          //setCheckSale={setCheckSale}
          //onAddClientPurch={onAddClientPurch}
          //clientList={clientList}
          //onModProduct={onModProduct}
          warehouses={warehouses}
          category={category}
          setCheckList={setCheckList}
          onAddCompound={onAddCompound}
          onModProduct={onModProduct}
        />
      )}
    </>
  );
}
