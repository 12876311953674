import { useState } from "react";
import HeaderPages from "./header/HeaderPages";
import Logo from "./header/Logo";
import UserOptions from "./header/UserOptions";
import ProductsMainPage from "./products/ProductsMainPage";
import DashboardMainPage from "./dashboard/DashboardMainPage";
import InventoryPlansMainPage from "./inventoryPlans/InventoryPlansMainPage";
import SalesMainPage from "./sales/SalesMainPage";
import PurchaseMainPage from "./purchase/PurchaseMainPage";
import { proxy } from "valtio";
import axios from "axios";

//caravela.dev/gestor-bolso-api

const productsStock = [
  {
    name: "Warehouse A",
    stock: [
      {
        category: "Mouse",
        brand: "Corsair",
        productName: "Scimitar",
        sku: 1131,
        ean: 12561,
        sn: 118511,
        purchaseDate: "01/10/2022",
        entryDate: "07/09/2023",
        purchaseValue: 100,
        pricePVP: 147.6,
        supplier: "Bajoras",
        available: true,
        soldPrice: 0,
        soldDate: "",
        soldWho: "",
        obs: "Caixa danificada",
      },
      {
        category: "Mouse",
        brand: "Razer",
        productName: "Naga",
        sku: 1321,
        ean: 141561,
        sn: 123511,
        purchaseDate: "01/10/2022",
        entryDate: "07/09/2023",
        purchaseValue: 50,
        pricePVP: 73.8,
        supplier: "Bajoras",
        available: true,
        soldPrice: 0,
        soldDate: "",
        soldWho: "",
        obs: "",
      },
      {
        category: "Mouse",
        brand: "Corsair",
        productName: "Scimitar",
        sku: 1131,
        ean: 12561,
        sn: 118512,
        purchaseDate: "10/10/2010",
        entryDate: "11/10/2023",
        purchaseValue: 100,
        pricePVP: 147.6,
        supplier: "Bajoras",
        available: true,
        soldPrice: 0,
        soldDate: "",
        soldWho: "",
        obs: "",
      },
      {
        category: "Monitor",
        brand: "Corsair",
        productName: "XENEON",
        sku: 1131,
        ean: 12561,
        sn: 118423513,
        purchaseDate: "8/22/2013",
        entryDate: "11/10/2018",
        purchaseValue: 400,
        pricePVP: 590.4,
        supplier: "BajoraseFilhos",
        available: false,
        soldPrice: 590.4,
        soldDate: "04/18/2024",
        soldWho: 1,
        obs: "",
      },
      {
        category: "Monitor",
        brand: "Corsair",
        productName: "XENEON",
        sku: 1131,
        ean: 12561,
        sn: 118423520,
        purchaseDate: "8/22/2013",
        entryDate: "11/10/2018",
        purchaseValue: 400,
        pricePVP: 590.4,
        supplier: "BajoraseFilhos",
        available: false,
        soldPrice: 590.4,
        soldDate: "04/18/2024",
        soldWho: 1,
        obs: "",
      },
      {
        category: "Monitor",
        brand: "Asus",
        productName: "Swift",
        sku: 3131,
        ean: 42161,
        sn: 141143,
        purchaseDate: "05/11/2015",
        entryDate: "11/10/2018",
        purchaseValue: 200,
        pricePVP: 295.2,
        supplier: "BajoraseFilhos",
        available: true,
        soldPrice: 0,
        soldDate: "",
        soldWho: "",
        obs: "Caixa aberta",
      },
      {
        category: "Keyboard",
        brand: "Xtrfy",
        productName: "K4",
        sku: 442,
        ean: 925618,
        sn: 33621,
        purchaseDate: "10/03/2024",
        entryDate: "11/10/2024",
        purchaseValue: 100,
        pricePVP: 170.9,
        supplier: "BajoraseIrmaos",
        available: true,
        soldPrice: 0,
        soldDate: "",
        soldWho: "",
        obs: "",
      },
      {
        category: "Graphic Card",
        brand: "Gigabyte",
        productName: "GeForce® RTX 4060 Ti Gaming OC 8GB GDDR6 DLSS3",
        sku: "GV-N406TGAMOC-8GD",
        ean: 4719331313456,
        sn: 614145999,
        purchaseDate: "07/05/2023",
        entryDate: "08/10/2023",
        purchaseValue: 450,
        pricePVP: 500,
        supplier: "BajoraseIrmaos",
        available: false,
        soldPrice: 0,
        soldDate: "05/09/2024",
        soldWho: "Bundle poupança 10010000",
        obs: "",
      },
      {
        category: "Cooler",
        brand: "Phanteks",
        productName: "Glacier One 420D30 DRGB Branco - 420mm",
        sku: "PH-GO420D30_DWT01",
        ean: 886523003342,
        sn: 474811826,
        purchaseDate: "07/05/2023",
        entryDate: "08/10/2023",
        purchaseValue: 150,
        pricePVP: 190,
        supplier: "BajoraseIrmaos",
        available: false,
        soldPrice: 0,
        soldDate: "05/09/2024",
        soldWho: "Bundle poupança 10010000",
        obs: "",
      },
    ],
    compound: [
      {
        category: "Bundle",
        productName: "Bundle poupança",
        sku: "BPou",
        ean: 100000,
        sn: 10010000,
        pricePVP: 750,
        purchaseValue: 600,
        entryDate: "05/09/2024",
        available: true,
        prodsSN: [614145999, 474811826],
        soldPrice: 0,
        soldDate: "",
        soldWho: "",
        obs: "",
      },
    ],
  },
  {
    name: "Warehouse B",
    stock: [
      {
        category: "Keyboard",
        brand: "Glorious",
        productName: "GMMK",
        sku: 15523,
        ean: 93365,
        sn: 3243621,
        purchaseDate: "09/20/2023",
        entryDate: "10/10/2023",
        purchaseValue: 150,
        pricePVP: 230.9,
        supplier: "BajoraseSobrinhos",
        available: true,
        soldPrice: 0,
        soldDate: "",
        soldWho: "",
        obs: "",
      },
      {
        category: "Power Supply",
        brand: "Kolink",
        productName: "Core",
        sku: 4435,
        ean: 74572,
        sn: 7568343,
        purchaseDate: "02/02/2021",
        entryDate: "03/20/2021",
        purchaseValue: 30,
        pricePVP: 59.9,
        supplier: "BajoraseSobrinhos",
        available: false,
        soldPrice: 59.9,
        soldDate: "01/17/2022",
        soldWho: 5,
        obs: "Falta cabos",
      },
      {
        category: "Power Supply",
        brand: "Kolink",
        productName: "Core",
        sku: 4435,
        ean: 74572,
        sn: 7568356,
        purchaseDate: "02/02/2023",
        entryDate: "03/20/2023",
        purchaseValue: 30,
        pricePVP: 59.9,
        supplier: "BajoraseSobrinhos",
        available: false,
        soldPrice: 59.9,
        soldDate: "04/19/2024",
        soldWho: 1,
        obs: "",
      },
    ],
    compound: [],
  },
];

const planArray = [
  {
    number: 0,
    timming: "Urgent",
    title: "Contagem de Stock",
    warehouse: "Warehouse A",
    description: "Contar todo o stock até ao final da Semana",
    entryDate: "03/01/2024",
    finishDate: "",
    finished: false,
  },
  {
    number: 1,
    timming: "Normal",
    title: "Encomendar caixas de cartão",
    warehouse: "Warehouse B",
    description: "Caixas de cartão para envio de encomendas",
    entryDate: "03/25/2024",
    finishDate: "",
    finished: false,
  },
  {
    number: 2,
    timming: "Normal",
    title: "Lavar WC",
    warehouse: "Warehouse A",
    description: "Deixar tudo a cheirar bem",
    entryDate: "03/03/2024",
    finishDate: "03/15/2024",
    finished: true,
  },
  {
    number: 3,
    timming: "Urgent",
    title: "Mandar vir chocolates",
    warehouse: "Warehouse B",
    description: "Ninguem trabalha sem um bom chocolate",
    entryDate: "02/10/2024",
    finishDate: "",
    finished: false,
  },
  {
    number: 4,
    timming: "Urgent",
    title: "Mudar o óleo do carro do chefe",
    warehouse: "Warehouse A",
    description: "Tentar não estragar nada",
    entryDate: "02/24/2024",
    finishDate: "02/27/2024",
    finished: true,
  },
];

const clientsWallet = [
  {
    cltNum: 0,
    sinceDate: "02/14/2014",
    vat: 299451931,
    cltName: "Getulio Fadista",
    adress: "Quinta dos cavalitos malditos",
    adressNum: "Lt 54 - 65ºfrt",
    adressCode: "9939-323 Vilar de Lugares",
    email: "@tudoquandopoder",
    phoneNumber: 992992831,
    cltType: "Particular",
    obs: "Caloteiro",
    purchases: [],
    valueSpended: 0,
  },
  {
    cltNum: 1,
    sinceDate: "06/22/2015",
    vat: 212334143,
    cltName: "Fenando Venando",
    adress: "Lugar dos Canhotos",
    adressNum: "Lt 23 - 2ºesq",
    adressCode: "2239-212 Destros proibidos",
    email: "H34D$H0073R@gmail.com",
    phoneNumber: 911912563,
    cltType: "Particular",
    obs: "Chatinho como a putassa",
    purchases: [
      { purchNumber: 0, date: "04/18/2024", sn: [118423513, 118423520] },
      { purchNumber: 1, date: "04/19/2024", sn: [7568356] },
    ],
    valueSpended: 0,
  },
  {
    cltNum: 2,
    sinceDate: "09/30/2017",
    vat: 236958741,
    cltName: "Empresa do Capeta Unipessoal",
    adress: "Cunhas longas",
    adressNum: "arm 4",
    adressCode: "3231-313 Afinal são Curtas",
    email: "666Diabinhos@hotmail.com",
    phoneNumber: 215563669,
    cltType: "Empresarial",
    obs: "",
    purchases: [],
    valueSpended: 0,
  },
  {
    cltNum: 3,
    sinceDate: "10/09/2018",
    vat: 999999999,
    cltName: "Cliente Consumo",
    adress: "",
    adressNum: "",
    adressCode: "",
    email: "",
    phoneNumber: "",
    cltType: "Particular",
    obs: "",
    purchases: [],
    valueSpended: 0,
  },
  {
    cltNum: 4,
    sinceDate: "11/12/2020",
    vat: 602595236,
    cltName: "Partido Chega",
    adress: "Assembleia canto esquerdo",
    adressNum: "lugar 553",
    adressCode: "1295-336 Centro Conservador",
    email: "abaixociganada@chega.pt",
    phoneNumber: 216659392,
    cltType: "Estado",
    obs: "Quer acabar com a ciganada",
    purchases: [],
    valueSpended: 0,
  },
  {
    cltNum: 5,
    sinceDate: "01/17/2022",
    vat: 359952544,
    cltName: "Zuca Bazuca",
    adress: "Algures em Braga",
    adressNum: "Quinta 102",
    adressCode: "7292-110 Rio de Braga",
    email: "brasilSempre@yahoo.br",
    phoneNumber: 913362418,
    cltType: "Particular",
    obs: "",
    purchases: [{ purchNumber: 0, date: "01/25/2024", sn: [7568343] }],
    valueSpended: 0,
  },
  {
    cltNum: 6,
    sinceDate: "02/28/2023",
    vat: 502336241,
    cltName: "Explorador de Pessoas SA",
    adress: "Mesmo numa Metropolitana",
    adressNum: "Torre das mais caras 5",
    adressCode: "1236-210 Lisboa",
    email: "explorar4ever@explorador.pt",
    phoneNumber: 760707070,
    cltType: "Corporate",
    obs: "Querem comprar sempre abaixo do preço de custo",
    purchases: [],
    valueSpended: 0,
  },
];

const supplierWallet = [
  {
    suppNum: 0,
    sinceDate: "05/21/2011",
    vat: 503226854,
    suppName: "Bajoras",
    adress: "Sitio dos armazéns",
    adressNum: "ARM 10",
    adressCode: "1795-365 Armazéns4ever",
    email: "geral@Bajoras.pt",
    phoneNumber: 235436659,
    obs: "Tem que se pagar na hora",
    purchases: [
      { purchNumber: 0, date: "07/09/2023", sn: [118511, 123511] },
      { purchNumber: 1, date: "11/10/2023", sn: [118512] },
    ],
    arriving: [],
    valueSpended: 0,
  },
  {
    suppNum: 1,
    sinceDate: "07/05/2015",
    vat: 512443758,
    suppName: "BajoraseFilhos",
    adress: "Lugares dos Martelos",
    adressNum: "Loja 234",
    adressCode: "2563-669 Marteladas",
    email: "bajoras&filhos@gmail.com",
    phoneNumber: 245589636,
    obs: "Entregam material danificado",
    purchases: [
      {
        purchNumber: 0,
        date: "11/10/2018",
        sn: [118423513, 118423520, 141143],
      },
    ],
    arriving: [],
    valueSpended: 0,
  },
  {
    suppNum: 2,
    sinceDate: "02/22/2017",
    vat: 532442553,
    suppName: "BajoraseIrmaos",
    adress: "Rua dos Irmãos",
    adressNum: "Loja 142",
    adressCode: "3365-214 Irmandade",
    email: "bajoras&Irmaos.geral@hotmail.com",
    phoneNumber: 233633654,
    obs: "",
    purchases: [{ purchNumber: 0, date: "11/10/2024", sn: [33621] }],
    arriving: [],
    valueSpended: 0,
  },
  {
    suppNum: 3,
    sinceDate: "05/25/2020",
    vat: 522232249,
    suppName: "BajoraseSobrinhos",
    adress: "Pão dos Sobrinhos de Deus",
    adressNum: "Armazém 443",
    adressCode: "5523-654 Fora Capeta",
    email: "Sobrinhos.geral@bajoraslda.pt",
    phoneNumber: 253321114,
    obs: "",
    purchases: [
      { purchNumber: 0, date: "03/20/2021", sn: [7568343] },
      { purchNumber: 1, date: "03/20/2023", sn: [7568356] },
      { purchNumber: 2, date: "10/10/2023", sn: [3243621] },
    ],
    arriving: [],
    valueSpended: 0,
  },
  {
    suppNum: 4,
    sinceDate: "05/25/2022",
    vat: 503363345,
    suppName: "Melhor que os Bajoras",
    adress: "Sitio dos melhores",
    adressNum: "Armazém 1337",
    adressCode: "1337-420 Lugas dos Melhores",
    email: "Melhor.email@theBests.pt",
    phoneNumber: 211441145,
    obs: "Impecáveis",
    purchases: [],
    arriving: [
      {
        category: "Cooler",
        brand: "Arctic",
        productName: "Freezer 34 eSports Duo",
        sku: "ACFRE00060A",
        ean: 4895213701860,
        purchaseValue: 15,
        purchaseDate: "04/25/2024",
        units: 25,
        arrivalDate: "05/15/2024",
      },
      {
        category: "Storage",
        brand: "Samsung",
        productName: "980 PRO 1TB Gen4 M.2 NVMe",
        sku: "MZ-V8P1T0BW",
        ean: 8806090295546,
        purchaseValue: 75,
        purchaseDate: "04/28/2024",
        units: 10,
        arrivalDate: "05/20/2024",
      },
    ],
    valueSpended: 0,
  },
];

const category = [
  "Mouse",
  "Monitor",
  "Keyboard",
  "Power Supply",
  "Graphic Card",
  "Cooler",
  "Bundle",
];

export default function App() {
  const stateProduct = proxy(productsStock);
  const stateClient = proxy(clientsWallet);
  const stateSupplier = proxy(supplierWallet);
  const statePlans = proxy(planArray);
  const stateCategory = proxy(category);

  const [elementChoosen, setElementChoosen] = useState("dashboard");
  const [addProduct, setAddProduct] = useState(stateProduct);
  const [addClient, setAddClient] = useState(stateClient);
  const [addSupplier, setAddSupplier] = useState(stateSupplier);
  const [addPlan, setAddPlan] = useState(statePlans);
  const [newCategory, setNewCategory] = useState(stateCategory);

  function handleElementChoosen(e) {
    setElementChoosen(e);
  }

  //products

  function handleAddCompound(newCompound, i) {
    setAddProduct((prevCompounds) => {
      const updatedCompounds = [...prevCompounds];
      const updatedCompound = { ...updatedCompounds[i] };
      updatedCompound.compound = [...updatedCompound.compound, newCompound];
      updatedCompounds[i] = updatedCompound;
      return updatedCompounds;
    });
  }

  function handleModCompound(modProduct, i, e) {
    setAddProduct((prevProducts) => {
      const updatedProducts = [...prevProducts];
      const updatedProduct = { ...updatedProducts[i] };
      const updatedStock = [...updatedProduct.compound];
      updatedStock[e] = modProduct;
      updatedProduct.compound = updatedStock;
      updatedProducts[i] = updatedProduct;
      return updatedProducts;
    });
  }

  function handleModProduct(modProduct, i, e) {
    setAddProduct((prevProducts) => {
      const updatedProducts = [...prevProducts];
      const updatedProduct = { ...updatedProducts[i] };
      const updatedStock = [...updatedProduct.stock];
      updatedStock[e] = modProduct;
      updatedProduct.stock = updatedStock;
      updatedProducts[i] = updatedProduct;
      return updatedProducts;
    });
  }

  function handleChangeProdWare(product, wareOut, wareIn) {
    setAddProduct((prevProducts) => {
      return prevProducts.map((category, index) => {
        const updatedStock = category.stock.slice();

        if (index === wareOut) {
          updatedStock.splice(product, 1);
        } else if (index === wareIn) {
          updatedStock.push(prevProducts[wareOut].stock[product]);
        }

        return { ...category, stock: updatedStock };
      });
    });
  }

  //Clients
  function handleAddClient(newClt) {
    setAddClient((clt) => [...clt, newClt]);
  }

  function handleModClient(client, i) {
    setAddClient((prevClt) => {
      const updatedClt = [...prevClt];
      let updatedThisClt = { ...updatedClt[i] };
      updatedThisClt = client;
      updatedClt[i] = updatedThisClt;
      return updatedClt;
    });
  }

  function handleAddClientPurch(newPurch, i) {
    setAddClient((prevPurch) => {
      const updatedPurchs = [...prevPurch];
      const updatedPurch = { ...updatedPurchs[i] };
      updatedPurch.purchases = [...updatedPurch.purchases, newPurch];
      updatedPurchs[i] = updatedPurch;
      return updatedPurchs;
    });
  }

  function handleModClientPurch(clientIndex, purchaseIndex, snToRemove) {
    setAddClient((prevClients) => {
      const updatedClients = prevClients.map((client, i) => {
        if (i === clientIndex) {
          if (purchaseIndex !== undefined && snToRemove === undefined) {
            return {
              ...client,
              purchases: client.purchases.filter((_, j) => j !== purchaseIndex),
            };
          } else if (purchaseIndex !== undefined && snToRemove !== undefined) {
            return {
              ...client,
              purchases: client.purchases.map((purchase) => ({
                ...purchase,
                sn: purchase.sn.filter((sn) => sn !== snToRemove),
              })),
            };
          }
        }
        return client;
      });
      return updatedClients;
    });
  }

  function handleDelClient(i) {
    setAddClient((prevClt) => {
      return prevClt.filter((_, index) => index !== i);
    });
  }

  //Suppliers
  function handleAddSupplier(newSup) {
    setAddSupplier((sups) => [...sups, newSup]);
  }

  function handleModSupplier(supplier, i) {
    setAddSupplier((prevSup) => {
      const updatedSup = [...prevSup];
      let updatedThisSup = { ...updatedSup[i] };
      updatedThisSup = supplier;
      updatedSup[i] = updatedThisSup;
      return updatedSup;
    });
  }

  function handleDelSupplier(i) {
    setAddSupplier((prevSup) => {
      return prevSup.filter((_, index) => index !== i);
    });
  }

  function handleAddSupplierBuy(newBuy, i) {
    setAddSupplier((prevBuy) => {
      const updatedBuys = [...prevBuy];
      const updatedBuy = { ...updatedBuys[i] };
      updatedBuy.purchases = [...updatedBuy.purchases, newBuy];
      updatedBuys[i] = updatedBuy;
      return updatedBuys;
    });
  }

  function handleDelSupplierBuy(i, supp, purch) {
    const updatedSupplierArriving = [...supplierWallet];
    if (typeof purch === "number") {
      updatedSupplierArriving[supp].purchases[purch].sn.splice(i, 1);
      return setAddSupplier(updatedSupplierArriving);
    } else {
      updatedSupplierArriving[supp].purchases.splice(i, 1);
      return setAddSupplier(updatedSupplierArriving);
    }
  }

  function handleAddProduct(newProduct, i) {
    setAddProduct((prevProducts) => {
      const updatedProducts = [...prevProducts];
      const updatedProduct = { ...updatedProducts[i] };
      updatedProduct.stock = [...updatedProduct.stock, newProduct];
      updatedProducts[i] = updatedProduct;
      return updatedProducts;
    });
  }

  function handleDelProduct(i, w) {
    const updatedProductsStock = [...productsStock];
    updatedProductsStock[w].stock.splice(i, 1);
    setAddProduct(updatedProductsStock);
  }

  function handleAddSupplierArriving(newArr, i) {
    setAddSupplier((prevArr) => {
      const updatedArrivals = [...prevArr];
      const updatedArr = { ...updatedArrivals[i] };
      updatedArr.arriving = [...updatedArr.arriving, newArr];
      updatedArrivals[i] = updatedArr;
      return updatedArrivals;
    });
  }

  function handleModSupplierArriving(arrPro, i, e) {
    setAddSupplier((prevArr) => {
      const updatedArrivals = [...prevArr];
      const updatedArr = { ...updatedArrivals[i] };
      const updatedArrProd = [...updatedArr.arriving];
      updatedArrProd[e] = arrPro;
      updatedArr.arriving = updatedArrProd;
      updatedArrivals[i] = updatedArr;
      return updatedArrivals;
    });
  }

  function handleDelSupplierArriving(i, supp) {
    const updatedSupplierArriving = [...supplierWallet];
    updatedSupplierArriving[supp].arriving.splice(i, 1);
    setAddSupplier(updatedSupplierArriving);
  }

  //Plans

  function handleModPlan(modPlan, i) {
    setAddPlan((prevPlans) => {
      const updatedPlans = [...prevPlans];
      updatedPlans[i] = { ...updatedPlans[i], ...modPlan };
      return updatedPlans;
    });
  }

  function handleDelPlan(number) {
    setAddPlan((prevPlans) => {
      return prevPlans.filter((plan) => plan.number !== number);
    });
  }

  //login

  const [username, setUsername] = useState("alixcarmo03@protonmail.com");
  const [userPassword, setUserPassword] = useState("123456");
  const [userLogged, setUserLogged] = useState(false);
  const [loading, setLoading] = useState(false);

  function handleLogin(e) {
    e.preventDefault();

    if (username.length === "" || userPassword.length === "") {
      window.alert("Please an username and a password");
    } else {
      axios
        .post(
          "https://caravela.dev/gestor-bolso-api/login",
          {
            email: username,
            password: userPassword,
          },
          { withCredentials: true }
        )
        .then((resp) => {
          if (resp.data.ok) {
            setUserLogged(true);
          }
        })
        .catch((error) => {
          window.alert(`Login failed, ${error} please try again.`);
        });
    }
  }

  return (
    <div>
      <header id="topBar" className="topBar alignCenter">
        <Logo />
        <HeaderPages onElementChoosen={handleElementChoosen} />
        <UserOptions />
      </header>
      {userLogged ? (
        <div>
          <div className="elementChoosen">
            {elementChoosen === "dashboard" && (
              <DashboardMainPage
                productsStock={addProduct}
                addPlan={addPlan}
                onSetElementChoosen={setElementChoosen}
              />
            )}
            {elementChoosen === "products" && (
              <ProductsMainPage
                productsStock={addProduct}
                clientList={addSupplier}
                onAddCompound={handleAddCompound}
                onModProduct={handleModProduct}
                onModProdWare={handleChangeProdWare}
                onDelProduct={handleDelProduct}
                category={newCategory}
              />
            )}
            {elementChoosen === "sales" && (
              <SalesMainPage
                clientList={addClient}
                productsStock={addProduct}
                onAddClient={handleAddClient}
                onModClient={handleModClient}
                onAddClientPurch={handleAddClientPurch}
                onModClientPurch={handleModClientPurch}
                onDelClient={handleDelClient}
                onModProduct={handleModProduct}
                onModCompound={handleModCompound}
              />
            )}
            {elementChoosen === "purchase" && (
              <PurchaseMainPage
                supplierList={addSupplier}
                productsStock={addProduct}
                category={newCategory}
                onAddSupplier={handleAddSupplier}
                onModSupplier={handleModSupplier}
                onAddSupplierArriving={handleAddSupplierArriving}
                onModSupplierArriving={handleModSupplierArriving}
                onDelSupplierArriving={handleDelSupplierArriving}
                onAddSupplierBuy={handleAddSupplierBuy}
                onDelSupplier={handleDelSupplier}
                onAddProduct={handleAddProduct}
                onDelSupplierBuy={handleDelSupplierBuy}
                onDelProduct={handleDelProduct}
              />
            )}
            {elementChoosen === "plans" && (
              <InventoryPlansMainPage
                onModPlan={handleModPlan}
                onDelPlan={handleDelPlan}
                productsStock={addProduct}
                plansList={addPlan}
              />
            )}
          </div>
        </div>
      ) : (
        <div>
          <div className="overlay">
            <form className="loginScreen" onSubmit={handleLogin}>
              {!loading ? (
                <>
                  <h2>Gestor de Bolso - Login</h2>
                  <div>
                    <header>Username</header>
                    <input
                      className="productInput column"
                      type="text"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </div>
                  <div>
                    <header>Password</header>
                    <input
                      className="productInput column"
                      type="text"
                      value={userPassword}
                      onChange={(e) => setUserPassword(e.target.value)}
                    />
                  </div>
                </>
              ) : (
                <img
                  src="./loading.gif"
                  alt="Loading Gif"
                  style={{ width: "180px" }}
                />
              )}
              <button onClick={() => setLoading(true)}>
                {!loading ? "Login" : "Wait"}
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}
