import { useEffect, useState } from "react";
import CreateCompound from "./CreateCompound";
import SearchProduct from "./search/SearchProduct";
import WarehousePage from "./warehouses/WarehousePage";
import ProductList from "./ProductList";
import ProductInfo from "./productInfo/ProductInfo";
import axios from "axios";

export default function ProductsMainPage({
  productsStock,
  clientList,
  onModProduct,
  onModProdWare,
  onDelProduct,
  onAddCompound,
  category,
}) {
  const [searched, setSearched] = useState(productsStock);
  const [warehouses, setWarehouses] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [productsList, setProductsList] = useState([]);
  const [whichProduct, setWhichProduct] = useState("");
  const [choosed, setChoosed] = useState("prods");

  function getCategories() {
    axios
      .get("https://caravela.dev/gestor-bolso-api/categories", {
        withCredentials: true,
      })
      .then((resp) => {
        setCategoryList(resp.data);
      });
  }

  function getWarehouses() {
    axios
      .get("https://caravela.dev/gestor-bolso-api/productItems/warehouses", {
        withCredentials: true,
      })
      .then((resp) => {
        setWarehouses(resp.data.data);
      });
  }

  function getProducts() {
    axios
      .get("https://caravela.dev/gestor-bolso-api/products", {
        withCredentials: true,
      })
      .then((resp) => {
        setProductsList(resp.data.data);
      });
  }

  function handleSearch(e, inStock) {
    setSearched(productsStock);
    let newSearch = productsStock.map((warehouse) => ({
      ...warehouse,
      stock: warehouse.stock.filter((product) =>
        Object.values(product).some(
          (value) =>
            (typeof value === "string" || typeof value === "number") &&
            String(value).toLowerCase().includes(String(e).toLowerCase())
        )
      ),
    }));

    if (inStock !== "all") {
      const availabilityBool = inStock === "true";
      newSearch = newSearch.map((warehouse) => ({
        ...warehouse,
        stock: warehouse.stock.filter(
          (product) => product.available === availabilityBool
        ),
      }));
    }
    setSearched(newSearch);
  }

  function handleSelProductInfo(e) {
    let productFilter = productsList.filter((prod) => prod.product_id === e);
    setWhichProduct(productFilter[0]);
  }

  useEffect(() => {
    getWarehouses();
    getCategories();
    getProducts();
    setWhichProduct();
  }, []);

  return (
    <div>
      <CreateCompound
        warehouses={warehouses}
        category={category}
        onSearch={handleSearch}
        onAddCompound={onAddCompound}
        onModProduct={onModProduct}
        choosed={choosed}
        setChoosed={setChoosed}
      />
      <SearchProduct
        onSearch={handleSearch}
        categoryList={categoryList}
        getWarehouses={getWarehouses}
        getCategories={getCategories}
        getProducts={getProducts}
        choosed={choosed}
      />
      {choosed === "prods" ? (
        <div className="spaceBetween">
          <ProductList
            productsList={productsList}
            setWhichProduct={handleSelProductInfo}
          />
          <ProductInfo
            selWhichProduct={whichProduct}
            warehouses={warehouses}
            categoryList={categoryList}
            getProducts={getProducts}
          />
        </div>
      ) : (
        <WarehousePage
          productsStock={searched}
          compoundStock={productsStock}
          clientList={clientList}
          category={category}
          onModProduct={onModProduct}
          onModProdWare={onModProdWare}
          onDelProduct={onDelProduct}
          warehouses={warehouses}
        />
      )}
    </div>
  );
}
