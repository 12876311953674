import { useState, useEffect } from "react";

export default function SearchPlan({ onPlanSearch, productsStock }) {
  const [search, setSearch] = useState("");
  const [inWare, setInWare] = useState("all");

  const handleSubmit = (e) => {
    e.preventDefault();
    onPlanSearch(search, inWare);
  };

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  const handleChangeWare = (e) => {
    setInWare(e.target.value);
  };

  useEffect(() => {
    onPlanSearch(search, inWare);
  }, [search, inWare]);

  return (
    <>
      <div className="searchPlan centerBetween">
        <form onSubmit={handleSubmit}>
          <div className="alignCenter gap10">
            <input
              type="text"
              placeholder="🔍 Search"
              value={search}
              onChange={handleChange}
            />
            <label>Filter:</label>
            <select
              className="filter"
              value={inWare}
              onChange={handleChangeWare}
            >
              <option value="all">All</option>
              {productsStock.map((ware, i) => (
                <option value={ware.name} key={"warehouse" + i}>
                  {ware.name}
                </option>
              ))}
            </select>
          </div>
        </form>
      </div>
    </>
  );
}
