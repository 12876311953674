import { useEffect, useState } from "react";
import ClientInfoEdit from "./ClientInfoEdit";
import ClientPurchases from "./ClientPurchases";
import ClientPurchasesHeader from "./ClientPurchasesHeader";
import ClientPurchasesButton from "./clientPurchButton/ClientPurchasesButton";

export default function ClientInfo({
  clientList,
  selClientInfo,
  productsStock,
  onModClient,
  onDelClient,
  onAddClientPurch,
  onModClientPurch,
  setWhichClient,
  onModProduct,
  onModCompound,
  typeOfClient,
  getCostumerList,
  soldItems,
}) {
  const [edit, setEdit] = useState(true);
  const [isActive, setIsActive] = useState(false);
  const [spended, setSpended] = useState();
  const [isEditPurchActive, setIsEditPurchActive] = useState(false);

  function handleEdit() {
    setEdit(!edit);
  }

  console.log(selClientInfo);

  const boughtProducts = productsStock.reduce((acc, ware) => {
    if (selClientInfo?.purchases) {
      selClientInfo.purchases.forEach((purchase) => {
        const matchingProducts = ware.stock.filter((prod) =>
          purchase.sn.includes(prod.sn)
        );
        const matchingCompounds = ware.compound.filter((prod) =>
          purchase.sn.includes(prod.sn)
        );
        const combinedMatches = [...matchingProducts, ...matchingCompounds];

        if (combinedMatches.length > 0) {
          const totalSoldPrice = combinedMatches.reduce(
            (productTotal, product) => productTotal + product.soldPrice,
            0
          );
          acc.push({
            purchNumber: purchase.purchNumber,
            sn: combinedMatches,
            soldPrice: totalSoldPrice,
            date: purchase.date,
          });
        }
      });
    }
    return acc;
  }, []);

  boughtProducts.sort((a, b) => a.purchNumber - b.purchNumber);

  useEffect(() => {
    const newTotalValue = boughtProducts.reduce(
      (productTotal, product) => productTotal + product.soldPrice,
      0
    );

    setSpended(newTotalValue);
  }, [boughtProducts]);

  return (
    <div className="clientInfo LRPadding20 thinBox">
      {edit && (
        <div className="centerBetween">
          <h2>Client Info</h2>
          {selClientInfo && edit && (
            <button onClick={() => setEdit(false)}>Edit Client</button>
          )}
        </div>
      )}
      <ClientInfoEdit
        selClientInfo={selClientInfo}
        edit={edit}
        onEdit={handleEdit}
        typeOfClient={typeOfClient}
        getCostumerList={getCostumerList}
      />
      {selClientInfo && (
        <div className="width100">
          <div className="centerBetween">
            <h3>
              Total Purchases: {selClientInfo && selClientInfo.sales.length}
            </h3>
            {selClientInfo && (
              <button onClick={() => setIsActive(true)}>Sale a product</button>
            )}
          </div>
          {selClientInfo.sales.length > 0 ? (
            selClientInfo.sales.map((purch) => (
              <ClientPurchasesHeader
                purch={purch}
                selClientInfo={selClientInfo}
                // productsStock={productsStock}
                // purchNum={purch.purchNumber + 1}
                // purchDate={purch.date}
                // //soldPrice={boughtProducts[i].soldPrice}
                // onModClientPurch={onModClientPurch}
                // onModProduct={onModProduct}
                // onModCompound={onModCompound}
                isEditPurchActive={isEditPurchActive}
                setIsEditPurchActive={setIsEditPurchActive}
              >
                {purch.sn.map((prods, i) => (
                  <ClientPurchases
                    selClientInfo={selClientInfo}
                    clientList={clientList}
                    prods={prods}
                    purchases={purch}
                    isEditPurchActive={isEditPurchActive}
                    setIsEditPurchActive={setIsEditPurchActive}
                    productsStock={productsStock}
                    onModProduct={onModProduct}
                    onModCompound={onModCompound}
                    onModClientPurch={onModClientPurch}
                    index={i}
                    soldItems={soldItems}
                  />
                ))}
              </ClientPurchasesHeader>
            ))
          ) : (
            <p>Has bough nothing yet</p>
          )}
          {isActive && (
            <div className="overlay">
              <ClientPurchasesButton
                clientList={clientList}
                selClientInfo={selClientInfo}
                productsStock={productsStock}
                setIsActive={setIsActive}
                onAddClientPurch={onAddClientPurch}
                onModProduct={onModProduct}
                onModCompound={onModCompound}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
