import Chart from "chart.js/auto";
import { Doughnut } from "react-chartjs-2";
import axios from "axios";
import { useEffect, useState } from "react";

export default function WarehouseDetail({ productsStock }) {
  const [warehouses, setWarehouses] = useState([]);

  function getWarehouses() {
    axios
      .get("https://caravela.dev/gestor-bolso-api/productItems/warehouses", {
        withCredentials: true,
      })
      .then((resp) => {
        setWarehouses(resp.data.data);
      });
  }

  let stock = 0;
  let wareName = new Array(warehouses.warehouse_name).fill(0);
  let warColor = new Array(warehouses.warehouse_name).fill(0);
  let wareStock = new Array(warehouses.length).fill(0);

  warehouses.forEach((war, i) => {
    warColor[i] = `rgb(${128},  ${255 - i * 25},  ${0 + i * 15})`;
    war.product_items.forEach((info) => {
      if (info.state === "In Stock") {
        stock++;
        wareStock[i] += 1;
      }
    });
    wareName[i] = `${war.warehouse_name} - stock ${wareStock[i]}`;
  });

  const data = {
    labels: wareName,
    datasets: [
      {
        label: "Stock",
        data: wareStock,
        backgroundColor: warColor,
        borderColor: "grey",
        hoverOffset: 20,
      },
    ],
  };

  useEffect(() => {
    getWarehouses();
  }, []);

  return (
    <div className="warehouseDetail thinBox LRPadding20">
      <div>
        <h2>Warehouse Detail</h2>
      </div>
      <Doughnut
        data={data}
        options={{
          radius: 80,
          plugins: {
            subtitle: {
              display: true,
              text: `Total Stock: ${stock}`,
              color: "black",
              padding: 7,
              font: {
                size: 25,
              },
            },
            legend: {
              position: "bottom",
              align: "center",
              labels: {
                boxWidth: 20,
                color: "black",
                padding: 20,
                font: {
                  size: 17,
                },
              },
            },
          },
        }}
        style={{
          container: {
            width: 500,
            height: 500,
          },
        }}
      />
    </div>
  );
}
