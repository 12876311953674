import { NumericFormat } from "react-number-format";
import { useState } from "react";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";

export default function CreateProductButton({
  selSupplierInfo,
  setIsBuying,
  productsStock,
  category,
  onAddProduct,
  supplierList,
  onAddSupplierBuy,
}) {
  const [selecCategory, setSelecCategory] = useState("");
  const [brand, setBrand] = useState("");
  const [productName, setProductName] = useState("");
  const [sku, setSKU] = useState("");
  const [ean, setEAN] = useState("");
  const [snArray, setSNArray] = useState("");
  const [purchaseDate, setPurchaseDate] = useState("");
  const [entryDate, setEntryDate] = useState("");
  const [purchaseValue, setPurchaseValue] = useState("");
  const [pricePVP, setPricePVP] = useState("");
  const [location, setLocation] = useState("");
  const [supplier, setSupplier] = useState(selSupplierInfo.suppNum);
  const [available, setAvailable] = useState(false);

  function handleSubmit(e) {
    e.preventDefault();

    const locationIndex = productsStock.findIndex(
      (item) => item.name === location
    );

    const snValues = snArray.split(",").map((value) => value.trim());
    const snSuppArr = [];

    if (selecCategory === "") {
      window.alert("Please, insert a category");
    } else if (location === "") {
      window.alert("Please, insert a warehouse");
    } else if (purchaseDate === "") {
      window.alert("Please, insert a purchase date");
    } else if (new Date(purchaseDate) > new Date(entryDate)) {
      window.alert("Purchase date can't be superior of the entry date");
    } else {
      snValues.forEach((snValues) => {
        const newProduct = {
          category: selecCategory,
          brand,
          productName,
          sku,
          ean,
          sn: snValues,
          purchaseDate,
          entryDate,
          purchaseValue,
          pricePVP,
          supplier,
          available,
        };
        onAddProduct(newProduct, locationIndex);
        snSuppArr.push(snValues);
      });

      const suppIndex = supplierList.findIndex(
        (sup) => sup.suppNum === selSupplierInfo.suppNum
      );

      const newSuppBuy = {
        purchNumber: selSupplierInfo.purchases.length,
        date: entryDate,
        sn: snSuppArr,
      };

      onAddSupplierBuy(newSuppBuy, suppIndex);
      window.alert("Product created");
      setIsBuying(false);
    }
  }

  const handlePurchaseDateChange = (date) => {
    let month = date.getMonth() + 1;
    let day = date.getDate();
    const year = date.getFullYear();
    day = day < 10 ? `0${day}` : day;
    month = month < 10 ? `0${month}` : month;
    const formattedDate = `${month}/${day}/${year}`;
    setPurchaseDate(formattedDate);
  };

  const handleEntryDateChange = (date) => {
    let month = date.getMonth() + 1;
    let day = date.getDate();
    const year = date.getFullYear();
    day = day < 10 ? `0${day}` : day;
    month = month < 10 ? `0${month}` : month;
    const formattedDate = `${month}/${day}/${year}`;
    setEntryDate(formattedDate);
  };

  function handlePurchValue(price) {
    const newValue = parseFloat(price.floatValue);
    setPurchaseValue(newValue);
  }

  function handlePVPValue(price) {
    const newValue = parseFloat(price.floatValue);
    setPricePVP(newValue);
  }

  return (
    <form className="creatSuppProduct" onSubmit={handleSubmit}>
      <header className="centerBetween">
        <h1>
          Create new product: {selSupplierInfo.vat} - {selSupplierInfo.suppName}{" "}
        </h1>
        <h3 onClick={() => setIsBuying(false)} style={{ cursor: "pointer" }}>
          X
        </h3>
      </header>
      <h2>Product Information</h2>
      <div className="productInformation">
        <ul className="productInputTable centerBetween">
          <div>
            <label>Category</label>
            <div>
              <select
                className="filter larger"
                value={selecCategory}
                onChange={(e) => setSelecCategory(e.target.value)}
                defaultChecked
              >
                <option value={""}>Select a Category</option>
                {category.map((cat, i) => (
                  <option value={cat} key={"cat" + i}>
                    {cat}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div>
            <label>Brand</label>
            <input
              type="text"
              value={brand}
              onChange={(e) => setBrand(e.target.value)}
              className="productInput column"
            />
          </div>

          <div>
            <label>Product name</label>
            <input
              type="text"
              value={productName}
              onChange={(e) => setProductName(e.target.value)}
              className="productInput column"
            />
          </div>

          <div>
            <label>Warehouse</label>
            <div>
              <select
                className="filter larger"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                defaultChecked
              >
                <option value={""}>Select a Warehouse</option>
                {productsStock.map((warehouse, i) => (
                  <option value={warehouse.name} key={"warehouse" + i}>
                    {warehouse.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div>
            <label>SKU</label>
            <input
              type="text"
              value={sku}
              onChange={(e) => setSKU(e.target.value)}
              className="productInput column"
            />
          </div>

          <div>
            <label>EAN</label>
            <input
              type="text"
              value={ean}
              onChange={(e) => setEAN(e.target.value)}
              className="productInput column"
            />
          </div>

          <div className="column">
            <label>Purchase date</label>
            <DatePicker
              className="dates"
              value={purchaseDate}
              onChange={handlePurchaseDateChange}
              clearIcon={null}
            />
          </div>

          <div className="column">
            <label>Entry date</label>
            <DatePicker
              className="dates"
              value={entryDate}
              onChange={handleEntryDateChange}
              clearIcon={null}
            />
          </div>

          <div className="productInput column"></div>

          <div>
            <label>PVP Price</label>
            <NumericFormat
              value={pricePVP}
              onValueChange={handlePVPValue}
              thousandSeparator={true}
              suffix={"€"}
              decimalScale={2}
              fixedDecimalScale={true}
              className="productInput column"
            />
          </div>

          <div>
            <label>Purchase Value</label>
            <NumericFormat
              value={purchaseValue}
              onValueChange={handlePurchValue}
              thousandSeparator={true}
              suffix={"€"}
              decimalScale={2}
              fixedDecimalScale={true}
              className="productInput column"
            />
          </div>

          <div className="productInput column"></div>

          <div>
            <label>Supplier</label>
            <input
              type="text"
              value={selSupplierInfo.suppName}
              className="productInput column"
              disabled
            />
          </div>

          <div className="productInput alignCenter gap10">
            <label>Avaible?</label>
            <input
              type="checkbox"
              checked={available}
              onChange={() => setAvailable(!available)}
            />
          </div>

          <div className="productInput column"></div>

          <div>
            <label className="alignCenter gap10">
              SN{" "}
              <span style={{ fontSize: "13px" }}>
                (use "," for multiple SN)
              </span>
            </label>
            <textarea
              type="text"
              value={snArray}
              onChange={(e) => setSNArray(e.target.value)}
              className="createProductSN productInput column"
            />
          </div>

          <div className="createProductPDF productInput column">
            <label>Purchase PDF</label>
            <input type="file" name="pdf_upload" accept="application/pdf" />
          </div>
          <div className="spaceBetween width100">
            <div></div>
            <button>Create Product</button>
          </div>
        </ul>
      </div>
    </form>
  );
}
