import { useState, useEffect } from "react";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import CreatePlan from "./CreatePlan";
import SearchPlan from "./SearchPlan";
import PlansPage from "./PlansPage";
import axios from "axios";

export default function InventoryPlansMainPage({ productsStock }) {
  const [plansList, setPlansList] = useState([]);

  function getPlans() {
    axios
      .get("https://caravela.dev/gestor-bolso-api/plans", {
        withCredentials: true,
      })
      .then((resp) => {
        setPlansList(resp.data.data);
      });
  }

  useEffect(() => {
    getPlans();
  }, []);

  function handleSearch(e, ware) {
    // setPlanSearched(plansList);
    // let newSearch = plansList.filter((plan) =>
    //   Object.values(plan).some(
    //     (value) =>
    //       (typeof value === "string" || typeof value === "number") &&
    //       String(value).toLowerCase().includes(String(e).toLowerCase())
    //   )
    // );
    // if (ware !== "all") {
    //   newSearch = newSearch.filter((plan) => plan.warehouse === ware);
    // }
    // setPlanSearched(newSearch);
  }

  return (
    <div>
      <CreatePlan getPlans={getPlans} />
      <SearchPlan
        onPlanSearch={handleSearch}
        productsStock={productsStock}
        getPlans={getPlans}
      />
      <PlansPage plansList={plansList} getPlans={getPlans} />
    </div>
  );
}
