import { useState } from "react";
import DatePicker from "react-date-picker";
import CheckoutList from "./CheckoutList";
import axios from "axios";

export default function CheckSaleList({
  saleList,
  setCheckSale,
  selClientInfo,
  onAddClientPurch,
  clientList,
  onModProduct,
  onModCompound,
  productsStock,
  setIsActive,
}) {
  const [totalWOutVat, setTotalWOutVat] = useState([]);
  const [totalWVat, setTotalWVat] = useState([]);
  const [totalProfit, setTotalProfit] = useState([]);
  const [totalProfitPer, setTotalProfitPer] = useState([]);

  console.log(saleList);

  function handleTotalWOutVat(value, index) {
    setTotalWOutVat((prevTotals) => {
      const newTotals = [...prevTotals];
      newTotals[index] = value;
      return newTotals;
    });
  }

  function handleTotalWVat(value, index) {
    setTotalWVat((prevTotals) => {
      const newTotals = [...prevTotals];
      newTotals[index] = value;
      return newTotals;
    });
  }

  function handleTotalProfit(value, index) {
    setTotalProfit((prevTotals) => {
      const newTotals = [...prevTotals];
      newTotals[index] = value;
      return newTotals;
    });
  }

  function handleTotalProfitPer(value, index) {
    setTotalProfitPer((prevTotals) => {
      const newTotals = [...prevTotals];
      newTotals[index] = value;
      return newTotals;
    });
  }

  function handleSubmit(e) {
    e.preventDefault();

    // let today = new Date();

    // let month = today.getMonth() + 1;
    // let day = today.getDate();
    // const year = today.getFullYear();
    // day = day < 10 ? `0${day}` : day;
    // month = month < 10 ? `0${month}` : month;
    // const formattedDate = `${month}/${day}/${year}`;

    // const snArray = saleList.map((item) => item.sn);

    // const cltIndex = clientList.findIndex(
    //   (clt) => clt.cltNum === selClientInfo.cltNum
    // );

    // const newProduct = {
    //   purchNumber:
    //     selClientInfo.purchases.length > 0
    //       ? selClientInfo.purchases[selClientInfo.purchases.length - 1]
    //           .purchNumber + 1
    //       : 0,
    //   date: formattedDate,
    //   sn: snArray,
    // };

    let calProf =
      ((totalWOutVat.reduce((acc, curr) => acc + curr, 0).toFixed(2) -
        totalProfitPer.reduce((acc, curr) => acc + curr, 0).toFixed(2)) /
        totalWOutVat.reduce((acc, curr) => acc + curr, 0).toFixed(2)) *
      100;

    // function modProduct() {
    //   snArray.forEach((arr, i) => {
    //     const locationIndex = productsStock.findIndex(
    //       (ware, e) => ware.name === saleList[e].warehouse
    //     );
    //     const stockIndex = productsStock[locationIndex].stock.findIndex(
    //       (item) => item.sn === arr
    //     );
    //     if (stockIndex === -1) {
    //       const compIndex = productsStock[locationIndex].compound.findIndex(
    //         (item) => item.sn === arr
    //       );
    //       const changedCompound = {
    //         ...productsStock[locationIndex].compound[compIndex],
    //         available: false,
    //         soldPrice: +totalWVat[i],
    //         soldDate: formattedDate,
    //         soldWho: selClientInfo.cltNum,
    //       };

    //       onModCompound(changedCompound, locationIndex, compIndex);
    //     } else {
    //       const changedProduct = {
    //         ...productsStock[locationIndex].stock[stockIndex],
    //         available: false,
    //         soldPrice: +totalWVat[i],
    //         soldDate: formattedDate,
    //         soldWho: selClientInfo.cltNum,
    //       };
    //       onModProduct(changedProduct, locationIndex, stockIndex);
    //     }
    //   });

    const addID = [];

    saleList.forEach((id) => {
      const prodID = { product_item_id: id.product_item_id };
      addID.push(prodID);
    });

    console.log(addID);

    if (calProf < 0) {
      let choose = "Your profit is negative, are you sure?";
      if (window.confirm(choose) === true) {
        axios
          .post(
            `https://caravela.dev/gestor-bolso-api/sale`,
            {
              customer_id: selClientInfo.customer_id,
              total_price: totalWVat,
              products: addID,
            },
            {
              withCredentials: true,
            }
          )
          .then((res) => {
            if (res.data.ok) {
              window.alert("Sale created");
              setIsActive(false);
            }
          });
      } else {
        window.alert("Sale not created");
      }
    } else if (calProf >= 0 && calProf < 2) {
      let choose = "Your profit is too low, are you sure?";
      if (window.confirm(choose) === true) {
        axios
          .post(
            `https://caravela.dev/gestor-bolso-api/sale`,
            {
              customer_id: selClientInfo.customer_id,
              total_price: totalWVat,
              products: addID,
            },
            {
              withCredentials: true,
            }
          )
          .then((res) => {
            if (res.data.ok) {
              window.alert("Sale created");
              setIsActive(false);
            }
          });
      } else {
        window.alert("Sale not created");
      }
    } else {
      axios
        .post(
          `https://caravela.dev/gestor-bolso-api/sale`,
          {
            customer_id: selClientInfo.customer_id,
            total_price: totalWVat,
            products: addID,
          },
          {
            withCredentials: true,
          }
        )
        .then((res) => {
          if (res.data.ok) {
            window.alert("Sale created");
            setIsActive(false);
          }
        });
    }
  }

  // if (calProf < 0) {
  //   let choose = "Your profit is negative, are you sure?";
  //   if (window.confirm(choose) === true) {
  //     onAddClientPurch(newProduct, cltIndex);
  //     modProduct();
  //     window.alert("Sale created");
  //     setIsActive(false);
  //   } else {
  //     window.alert("Sale not created");
  //   }
  // } else if (calProf >= 0 && calProf < 2) {
  //   let choose = "Your profit is too low, are you sure?";
  //   if (window.confirm(choose) === true) {
  //     onAddClientPurch(newProduct, cltIndex);
  //     modProduct();
  //     window.alert("Sale created");
  //     setIsActive(false);
  //   } else {
  //     window.alert("Sale not created");
  //   }
  // } else {
  //   onAddClientPurch(newProduct, cltIndex);
  //   modProduct();
  //   window.alert("Sale created");
  //   setIsActive(false);
  // }

  return (
    <div>
      <header className="centerBetween">
        <h1>Check the Sale List</h1>
        <h3 onClick={() => setCheckSale(false)} style={{ cursor: "pointer" }}>
          X
        </h3>
      </header>
      <header className="centerBetween">
        <div></div>
        <div>
          <h2>
            {selClientInfo.vat} - {selClientInfo.name} | Purchase:{" "}
            {/* {selClientInfo.sales.length + 1} -{" "} */}
            <DatePicker
              value={new Date()}
              clearIcon={null}
              calendarIcon={null}
              disabled
            />
          </h2>
        </div>
      </header>

      {saleList.map((info, i) => (
        <CheckoutList
          info={info}
          onTotalWOutVat={handleTotalWOutVat}
          onTotalWVat={handleTotalWVat}
          onTotalProfit={handleTotalProfit}
          onTotalProfitPer={handleTotalProfitPer}
          index={i}
        />
      ))}

      <h4 className="textRight">
        Total w.o/Vat:{" "}
        {totalWOutVat.reduce((acc, curr) => acc + curr, 0).toFixed(2)}€
      </h4>

      <div>
        <h4 className="textRight" style={{ marginBottom: "5px" }}>
          Total profit:{" "}
          {totalProfit.length === saleList.length
            ? totalProfit.reduce((acc, curr) => acc + curr, 0).toFixed(2)
            : "0.00"}
          €
        </h4>
        <h5 className="textRight" style={{ marginTop: "0" }}>
          (
          {totalProfit.length === saleList.length
            ? (
                ((totalWOutVat.reduce((acc, curr) => acc + curr, 0).toFixed(2) -
                  totalProfitPer
                    .reduce((acc, curr) => acc + curr, 0)
                    .toFixed(2)) /
                  totalWOutVat
                    .reduce((acc, curr) => acc + curr, 0)
                    .toFixed(2)) *
                100
              ).toFixed(2) + "%"
            : "0%"}
          )
        </h5>
      </div>

      <h2 className="textRight">
        Total w/Vat: {totalWVat.reduce((acc, curr) => acc + curr, 0).toFixed(2)}
        €
      </h2>
      <div className="centerBetween">
        <div></div>
        <button onClick={handleSubmit}>Finish the Sale</button>
      </div>
    </div>
  );
}
