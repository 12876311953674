import { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";

export default function CheckoutList({
  info,
  onTotalWOutVat,
  onTotalWVat,
  onTotalProfit,
  onTotalProfitPer,
  index,
}) {
  const [priceWOutVat, setPriceWOutVat] = useState(info.pricePVP / 1.23);
  const [priceWVat, setPriceWVat] = useState(info.pricePVP);
  const [profit, setProfit] = useState(0);

  useEffect(() => {
    const initialPriceWOutVat = info.pricePVP / 1.23;
    const initialPriceWVat = info.pricePVP;
    const initialProfit =
      ((initialPriceWOutVat - info.purchaseValue) / initialPriceWOutVat) * 100;

    setPriceWOutVat(initialPriceWOutVat);
    setPriceWVat(initialPriceWVat);
    setProfit(initialProfit);

    onTotalWOutVat(initialPriceWOutVat, index);
    onTotalWVat(initialPriceWVat, index);
    onTotalProfit(initialPriceWOutVat - info.purchaseValue, index);
    onTotalProfitPer(info.purchaseValue, index);
  }, []);

  function handleWOutVat(price) {
    const newValue = parseFloat(price.floatValue);
    setPriceWOutVat(newValue);
    const calc = (newValue * 1.23).toFixed(2);
    setPriceWVat(parseFloat(calc));
    onTotalWOutVat(newValue, index);
    handleProfit();
  }

  function handleWVat(price) {
    const newValue = parseFloat(price.floatValue);
    setPriceWVat(newValue);
    const calc = (newValue / 1.23).toFixed(2);
    setPriceWOutVat(parseFloat(calc));
    onTotalWVat(newValue, index);
    handleProfit();
  }

  function handleProfit() {
    setProfit(((priceWOutVat - info.purchaseValue) / priceWOutVat) * 100);
    onTotalProfit(priceWOutVat - info.purchaseValue, index);
    onTotalProfitPer(info.purchaseValue, index);
  }

  return (
    <div className="clientHeader thinBox">
      <div className="centerBetween thinBox gap10 LRPadding20">
        <div className="alignCenter gap10">
          <p>{info.category} /</p>
          <p>{info.brand} /</p>
          <p>{info.productName} /</p>
          <p>SN: {info.sn}</p>
        </div>
        <div className="alignCenter gap10">
          <label>Profit: </label>
          <p>
            {priceWOutVat === 0
              ? -info.purchaseValue
              : (priceWOutVat - info.purchaseValue).toFixed(2)}
            € / {profit.toFixed(2)}% |
          </p>
          <label>Price w.o/Vat:</label>
          <NumericFormat
            value={priceWOutVat}
            onValueChange={handleWOutVat}
            defaultValue={0}
            className="clientInvPrice"
            thousandSeparator={true}
            suffix={"€"}
            decimalScale={2}
            fixedDecimalScale={true}
          />
          <p>|</p>
          <label>Price w/Vat:</label>
          <NumericFormat
            value={priceWVat}
            onValueChange={handleWVat}
            defaultValue={0}
            className="clientInvPrice"
            thousandSeparator={true}
            suffix={"€"}
            decimalScale={2}
            fixedDecimalScale={true}
          />
        </div>
      </div>
    </div>
  );
}
