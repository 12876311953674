import { useState } from "react";
import axios from "axios";

export default function ModifyCategory({
  onClickDes,
  categoryList,
  getCategories,
}) {
  const [newCategory, setNewCategory] = useState("");
  const [modCategory, setModCategory] = useState("");
  const [newModCategory, setNewModCategory] = useState("");
  const [delCategory, setDelCategory] = useState("");

  function prevent(e) {
    e.preventDefault();
  }

  function handleNewCategory(e) {
    e.preventDefault();

    const formattedNewCategory =
      newCategory.charAt(0).toUpperCase() + newCategory.slice(1).toLowerCase();

    if (newCategory.length > 2) {
      axios
        .post(
          `https://caravela.dev/gestor-bolso-api/category`,
          {
            name: formattedNewCategory,
          },
          {
            withCredentials: true,
          }
        )
        .then((res) => {
          if (res.data.ok) {
            window.alert(`${formattedNewCategory} created`);
            getCategories();
            onClickDes();
          } else {
            window.alert("You need to input at least 3 characters");
          }
        });
    }
  }

  function handleModCategory(e) {
    e.preventDefault();

    const foundCategory = categoryList.findIndex(
      (cat) => cat.category_id === +modCategory
    );

    const formattedModCategory =
      newModCategory.charAt(0).toUpperCase() +
      newModCategory.slice(1).toLowerCase();

    console.log(categoryList);
    const oldCategory = categoryList[foundCategory].name;

    if (newModCategory.length > 2) {
      axios
        .put(
          `https://caravela.dev/gestor-bolso-api/category`,
          {
            id: +modCategory,
            name: formattedModCategory,
          },
          {
            withCredentials: true,
          }
        )
        .then((res) => {
          if (res.data.ok) {
            window.alert(
              `Category ${oldCategory}'s name changed to ${formattedModCategory}`
            );
            getCategories();
            onClickDes();
          }
        });
    } else {
      window.alert("You need to input at least 3 characters");
    }
  }

  function handleDelCategory(e) {
    e.preventDefault();

    const foundCategory = categoryList.findIndex(
      (cat) => cat.category_id === +delCategory
    );

    const oldCategory = categoryList[foundCategory].name;

    if (delCategory === "") {
      window.alert("Please choose a category!!");
    } else if (
      window.confirm(`Are you sure you want to delete ${oldCategory}?`)
    ) {
      let isDelete = prompt("Type 'delete' to confirm");
      if (isDelete === "delete") {
        axios
          .delete("https://caravela.dev/gestor-bolso-api/category", {
            withCredentials: true,
            data: {
              id: delCategory,
            },
          })
          .then((resp) => {
            if (resp.data.ok) {
              window.confirm(`${oldCategory} deleted`);
              getCategories();
              onClickDes();
            } else {
              window.alert("Choose again the category");
            }
          });
      } else {
        window.alert("Operation cancelled");
      }
    } else {
      window.alert("Operation cancelled");
    }
  }

  return (
    <form className="creatProductForm" onSubmit={prevent}>
      <header className="centerBetween">
        <h1>Category Management</h1>
        <h3 onClick={onClickDes} style={{ cursor: "pointer" }}>
          X
        </h3>
      </header>
      <div>
        <h3>Create New Category</h3>
        <div className="alignCenter gap10">
          <input
            type="text"
            value={newCategory}
            onChange={(e) => setNewCategory(e.target.value)}
            placeholder={"New Category"}
          />
          {newCategory.length > 2 && (
            <button onClick={handleNewCategory} className="filter">
              Create
            </button>
          )}
        </div>
      </div>
      <div>
        <h3>Modify a Category</h3>
        <div className="alignCenter gap10">
          <select
            className="filter"
            value={modCategory}
            onChange={(e) => setModCategory(e.target.value)}
            defaultChecked
          >
            <option value={""}>Select a Category</option>
            {categoryList.map((category, i) => (
              <option value={category.category_id} key={"category" + i}>
                {category.name}
              </option>
            ))}
          </select>
          {modCategory && (
            <input
              type="text"
              value={newModCategory}
              onChange={(e) => setNewModCategory(e.target.value)}
            />
          )}
          {newModCategory.length > 2 ? (
            <button onClick={handleModCategory} className="filter">
              Modify
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
      <div>
        <h3>Delete a Category</h3>
        <div className="alignCenter gap10">
          <select
            className="filter"
            value={delCategory}
            onChange={(e) => setDelCategory(e.target.value)}
            defaultChecked
          >
            <option value={""}>Select a Category</option>
            {categoryList.map((category, i) => (
              <option value={category.category_id} key={"cat" + i}>
                {category.name}
              </option>
            ))}
          </select>
          {delCategory && (
            <button onClick={handleDelCategory} className="filter">
              Delete
            </button>
          )}
        </div>
      </div>
    </form>
  );
}
