import { useState } from "react";
import { IoChevronUpSharp } from "react-icons/io5";
import { IoChevronDownSharp } from "react-icons/io5";

export default function ClientWareheader({ ware, onAddtoSale }) {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="clientWareInventory thinBox">
      <header
        className="centerBetween gap10"
        onClick={() => setIsActive(!isActive)}
        style={{ cursor: "pointer" }}
      >
        <p className="alignCenter gap10">{ware.warehouse_name}</p>
        <div className="expandButton">
          {isActive ? <IoChevronUpSharp /> : <IoChevronDownSharp />}
        </div>
      </header>
      {isActive && (
        <>
          {ware.product_items.map((info) =>
            info.state === "In Stock" ? (
              <div
                className="warehouseInventory centerBetween thinBox LRPadding20"
                onClick={() =>
                  onAddtoSale(
                    info.product_item_id,
                    info.category_name,
                    info.brand,
                    info.product_name,
                    info.serial_number,
                    info.cost_price,
                    info.pricePVP
                  )
                }
                style={{ cursor: "cell" }}
              >
                <div className="alignCenter gap10">
                  <p>{info.category_name} /</p>
                  <p>{info.brand} /</p>
                  <p>{info.product_name} /</p>
                  <p>SN: {info.serial_number}</p>
                </div>
                <p> {">"}</p>
              </div>
            ) : (
              ""
            )
          )}
        </>
      )}
    </div>
  );
}
