import { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import axios from "axios";
import DatePicker from "react-date-picker";

export default function ClientInfoEdit({
  selClientInfo,
  onEdit,
  edit,
  typeOfClient,
  getCostumerList,
}) {
  const [cltNum, setCltNum] = useState("");
  const [sinceDate, setSinceDate] = useState("");
  const [costVAT, setCostVAT] = useState("");
  const [cltName, setCltName] = useState("");
  const [costAdress, setCostAdress] = useState("");
  const [adressNum, setAdressNum] = useState("");
  const [adressCode, setAdressCode] = useState("");
  const [costEmail, setCostEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [cltType, setCltType] = useState("");
  const [obs, setOBS] = useState("");
  const [purchases, setPurchases] = useState("");
  const [valueSpended, setValueSpended] = useState(0);
  const [seePurchases, setSeePurchases] = useState("");

  useEffect(() => {
    if (selClientInfo) {
      setCltNum(selClientInfo.customer_id);
      setSinceDate(selClientInfo.created_at);
      setCostVAT(selClientInfo.vat);
      setCltName(selClientInfo.name);
      setCostAdress(selClientInfo.address);
      setAdressNum(selClientInfo.address_number);
      setAdressCode(selClientInfo.address_code);
      setCostEmail(selClientInfo.email);
      setPhoneNumber(selClientInfo.phone_number);
      setCltType(selClientInfo.client_type);
      setOBS(selClientInfo.observations);
      setPurchases(selClientInfo.sales);
      setValueSpended(selClientInfo.total_spent);
    }
  }, [selClientInfo]);

  function handleSubmit(e) {
    e.preventDefault();

    if (cltType === "") {
      window.alert("Please, insert the type of client");
    } else if (cltName === "") {
      window.alert('Please, insert a name, for default use "Cliente Consumo"');
    } else if (costVAT.length < 9) {
      window.alert(
        "Please, insert a 9 digit VAT, use 999999999 if there is no VAT"
      );
    } else {
      axios
        .put(
          "https://caravela.dev/gestor-bolso-api/costumer",
          {
            id: cltNum,
            name: cltName,
            email: costEmail,
            address: costAdress,
            phone_number: phoneNumber,
            address_number: adressNum,
            address_code: adressCode,
            client_type: cltType,
            observations: obs,
            vat: costVAT,
          },
          { withCredentials: true }
        )
        .then((resp) => {
          if (resp.data.ok) {
            getCostumerList();
            window.alert(`Client ${costVAT} - ${cltName} edited`);
            onEdit();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  function handleDelClient(e) {
    e.preventDefault();

    if (purchases.length > 0) {
      window.alert(
        "You can't delete a client with purchases. Change his info instead"
      );
    } else if (
      window.confirm(`Are you sure you want to delete ${costVAT} - ${cltName}?`)
    ) {
      let isDelete = prompt("Type 'delete' to confirm");
      if (isDelete === "delete") {
        axios
          .delete("https://caravela.dev/gestor-bolso-api/costumer", {
            withCredentials: true,
            data: {
              id: cltNum,
            },
          })
          .then((resp) => {
            if (resp.data.ok) {
              getCostumerList();
              onEdit();
              window.confirm(`${costVAT} - ${cltName} removed`);
            } else {
              window.alert("Choose again the warehouse");
            }
          });
      }
    }
  }

  return (
    <form>
      {!edit && (
        <div className="centerBetween">
          <h2>Client Info</h2>
          <div>
            <button className="delete" onClick={handleDelClient}>
              Delete Client
            </button>{" "}
            <button onClick={handleSubmit}>Confirm Edition</button>
          </div>
        </div>
      )}

      <div className="LRPadding20 column">
        <ul className="productInputTable centerBetween">
          <div>
            <label>NIF</label>
            <input
              type="text"
              value={costVAT}
              onChange={(e) => setCostVAT(+e.target.value)}
              className="productInput column"
              disabled={edit}
            />
          </div>
          <div>
            <label>Client Number</label>
            <div className="alignCenter gap10">
              <input
                type="text"
                value={cltNum}
                onChange={(e) => setCltNum(e.target.value)}
                className="productInput column"
                disabled
              />
            </div>
          </div>
          {edit ? (
            <div>
              <label>Client Type</label>
              <input
                type="text"
                value={cltType}
                onChange={(e) => setCltType(e.target.value)}
                className="productInput column"
                disabled={edit}
              />
            </div>
          ) : (
            <div className="productInput column">
              <label>Type of Client</label>
              <select
                type="text"
                value={cltType}
                onChange={(e) => setCltType(e.target.value)}
              >
                <option value={""}>Select type of client</option>
                {typeOfClient.map((type, i) => (
                  <option value={type} key={"type" + i}>
                    {type}
                  </option>
                ))}
              </select>
            </div>
          )}
          <div>
            <label>Client Name</label>
            <input
              type="text"
              value={cltName}
              onChange={(e) => setCltName(e.target.value)}
              className="productInput column"
              disabled={edit}
            />
          </div>
          <div>
            <label>Phone Number</label>
            <input
              type="text"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              className="productInput column"
              disabled={edit}
            />
          </div>
          <div>
            <label>E-mail</label>
            <input
              type="text"
              value={costEmail}
              onChange={(e) => setCostEmail(e.target.value)}
              className="productInput column"
              disabled={edit}
            />
          </div>
          <div>
            <label>Adress</label>
            <input
              type="text"
              value={costAdress}
              onChange={(e) => setCostAdress(e.target.value)}
              className="productInput column"
              disabled={edit}
            />
          </div>
          <div>
            <label>Adress Number</label>
            <div className="alignCenter gap10">
              <input
                type="text"
                value={adressNum}
                onChange={(e) => setAdressNum(e.target.value)}
                className="productInput column"
                disabled={edit}
              />
            </div>
          </div>
          <div>
            <label>Adress Code</label>
            <input
              type="text"
              value={adressCode}
              onChange={(e) => setAdressCode(e.target.value)}
              className="productInput column"
              disabled={edit}
            />
          </div>
          <div className="productInput column">
            <label>Since Date</label>
            <DatePicker
              value={sinceDate}
              clearIcon={null}
              calendarIcon={null}
              disabled
            />
          </div>

          <div>
            <label>Value Spended</label>
            <NumericFormat
              value={valueSpended}
              thousandSeparator={true}
              prefix={"€ "}
              decimalScale={2}
              fixedDecimalScale={true}
              className="productInput column"
              disabled
            />
          </div>
          <div>
            <label>Observations</label>
            <input
              type="text"
              value={obs}
              onChange={(e) => setOBS(e.target.value)}
              className="productInput column"
              disabled={edit}
            />
          </div>
        </ul>
      </div>
    </form>
  );
}
