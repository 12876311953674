export default function TopDashboard({
  title,
  leftInfo,
  leftInfoNumbers,
  rightInfo,
  rightInfoNumbers,
}) {
  return (
    <div className="topDashboardBoxes thinBox width100">
      <h2>{title}</h2>
      <div className="alignCenter">
        <div className="thinBox centerBetween LRPadding20 width100">
          <div>
            <h3>{leftInfo}</h3>
            <p>{leftInfoNumbers}</p>
          </div>
          <p>View detail {">"}</p>
        </div>
        <div className="thinBox centerBetween LRPadding20 width100">
          <div>
            <h3>{rightInfo}</h3>
            <p>{rightInfoNumbers}</p>
          </div>
          <p>View detail {">"}</p>
        </div>
      </div>
    </div>
  );
}
