import { useState } from "react";
import DatePicker from "react-date-picker";
import axios from "axios";

export default function CreateClient({
  clientList,
  typeOfClient,
  getCostumerList,
}) {
  const [isActive, setIsActive] = useState(false);

  return (
    <div>
      <header className="centerBetween">
        <h1>Sales</h1>
        <button onClick={() => setIsActive(true)} style={{ cursor: "pointer" }}>
          + Create New Client
        </button>
      </header>
      <div className={isActive ? "overlay" : ""}>
        {isActive && (
          <CreateClientButton
            onClickDes={() => setIsActive(false)}
            clientList={clientList}
            typeOfClient={typeOfClient}
            getCostumerList={getCostumerList}
          />
        )}
      </div>
    </div>
  );
}

function CreateClientButton({
  clientList,
  onClickDes,
  typeOfClient,
  getCostumerList,
}) {
  const [cltNum, setCltNum] = useState(clientList.length);
  const [sinceDate, setSinceDate] = useState("");
  const [costVAT, setCostVAT] = useState("");
  const [cltName, setCltName] = useState("");
  const [adressStreet, setAdressStreet] = useState("");
  const [adressNum, setAdressNum] = useState("");
  const [adressCode, setAdressCode] = useState("");
  const [costEmail, setCostEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selecTypeOfClt, setSelecTypeOfClt] = useState("");
  const [obs, setOBS] = useState("");
  const [purchases, setPurchases] = useState([]);
  const [valueSpended, setValueSpended] = useState(0);

  function handleSubmit(e) {
    e.preventDefault();

    console.log(selecTypeOfClt);

    if (selecTypeOfClt === "") {
      window.alert("Please, insert the type of client");
    } else if (cltName === "") {
      window.alert('Please, insert a name, for default use "Cliente Consumo"');
    } else if (costVAT.length !== 9) {
      window.alert(
        "Please, insert a 9 digit VAT, use 999999999 if there is no VAT"
      );
    } else {
      axios
        .post(
          `https://caravela.dev/gestor-bolso-api/costumer`,
          {
            name: cltName,
            email: costEmail,
            address: adressStreet,
            phone_number: phoneNumber,
            address_number: adressNum,
            address_code: adressCode,
            client_type: selecTypeOfClt,
            observations: obs,
            vat: costVAT,
          },
          {
            withCredentials: true,
          }
        )
        .then((res) => {
          if (res.data.ok) {
            window.alert("Client created");
            getCostumerList();
          }
        });
    }
  }

  return (
    <form className="creatProductForm" onSubmit={handleSubmit}>
      <header className="centerBetween">
        <h1>Create New Client</h1>
        <h3 onClick={onClickDes} style={{ cursor: "pointer" }}>
          X
        </h3>
      </header>
      <h2>Client Information</h2>
      <div className="productInformation">
        <ul className="productInputTable centerBetween">
          <div className="clientTypeOfLab column">
            <label>Type of Client</label>
            <select
              type="text"
              value={selecTypeOfClt}
              onChange={(e) => setSelecTypeOfClt(e.target.value)}
              className="clientTypeOf"
            >
              <option value={""}>Select type of client</option>
              {typeOfClient.map((type, i) => (
                <option value={type} key={"type" + i}>
                  {type}
                </option>
              ))}
            </select>
          </div>

          <div>
            <div className="column gap10">
              <div>
                <label>Client Number:</label>{" "}
                <input style={{ width: `60px` }} value={cltNum} disabled />{" "}
              </div>
              <div>
                <label>Since Date:</label>{" "}
                <DatePicker
                  value={new Date()}
                  clearIcon={null}
                  calendarIcon={null}
                  disabled
                />
              </div>
            </div>
          </div>

          <div>
            <label>VAT</label>
            <input
              type="number"
              value={costVAT}
              onChange={(e) => setCostVAT(e.target.value)}
              className="productInput column"
            />
          </div>

          <div>
            <label>Name</label>
            <input
              type="text"
              value={cltName}
              onChange={(e) => setCltName(e.target.value)}
              className="productInput column"
            />
          </div>

          <div>
            <label>Adress</label>
            <input
              type="text"
              value={adressStreet}
              onChange={(e) => setAdressStreet(e.target.value)}
              className="productInput column"
            />
          </div>

          <div>
            <label>Adress Number</label>
            <input
              type="text"
              value={adressNum}
              onChange={(e) => setAdressNum(e.target.value)}
              className="productInput column"
            />
          </div>

          <div>
            <label>Postal Code</label>
            <input
              type="text"
              value={adressCode}
              onChange={(e) => setAdressCode(e.target.value)}
              className="productInput column"
            />
          </div>

          <div>
            <label>E-mail</label>
            <input
              type="text"
              value={costEmail}
              onChange={(e) => setCostEmail(e.target.value)}
              className="productInput column"
            />
          </div>

          <div>
            <label>Phone Number</label>
            <input
              type="text"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              className="productInput column"
            />
          </div>

          <div>
            <label>Obs.:</label>
            <div>
              <textarea
                className="clientObs"
                value={obs}
                onChange={(e) => setOBS(e.target.value)}
              />
            </div>
          </div>
          <div></div>
          <button>Create</button>
        </ul>
      </div>
    </form>
  );
}
