import { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import { FaArrowRight } from "react-icons/fa";
import { IoChevronUpSharp } from "react-icons/io5";
import { IoChevronDownSharp } from "react-icons/io5";
import DatePicker from "react-date-picker";
import axios from "axios";

export default function SupplierPurchasesHeader({
  boughtProducts,
  isEditPurchActive,
  setIsEditPurchActive,
  getSupplierList,
  children,
}) {
  const [isActive, setIsActive] = useState(false);
  const [totalCost, setTotalCost] = useState("");
  const [prodArrived, setProdArrived] = useState("");
  const [prodArriving, setProdArriving] = useState("");
  const [prodBought, setProdBought] = useState("");

  function calcCost() {
    let total = 0;
    boughtProducts.products.forEach((cost) => {
      total += cost.unit_cost * cost.quantity_arrived;
    });
    setTotalCost(total);
  }

  function calcProducts() {
    let arrived = 0;
    let arriving = 0;
    let bought = 0;
    boughtProducts.products.forEach((calc) => {
      arrived += calc.quantity_arrived;
      arriving += calc.quantity_to_arrive;
      bought += calc.quantity_bought;
    });
    setProdArrived(arrived);
    setProdArriving(arriving);
    setProdBought(bought);
  }

  function handleDelSuppPurch(e) {
    e.stopPropagation();

    if (
      window.confirm("Are you sure you want to DELETE whole purchase?") === true
    ) {
      let isDelete = prompt("Type 'delete' to confirm");
      if (isDelete === "delete") {
        axios
          .delete("https://caravela.dev/gestor-bolso-api/supplierOrder", {
            withCredentials: true,
            data: {
              id: boughtProducts.order_id,
            },
          })
          .then((resp) => {
            if (resp.data.ok) {
              window.confirm(`Purchase ${boughtProducts.order_id} deleted`);
              setIsEditPurchActive(false);
              getSupplierList();
            } else {
              window.alert("Choose again the warehouse");
            }
          });
      } else {
        window.alert("Operation Canceled");
      }
    } else {
      window.alert("Operation Canceled");
    }
  }

  useEffect(() => {
    calcCost();
    calcProducts();
    setIsActive(false);
  }, [boughtProducts]);

  return (
    <div className="WarehouseHeader">
      <div
        className="centerBetween thinBox LRPadding20"
        onClick={() => setIsActive(!isActive)}
      >
        <header className="alignCenter gap10">
          <p className="alignCenter gap10">
            Order Nº:<strong>{boughtProducts.order_id}</strong> <FaArrowRight />{" "}
            <DatePicker
              value={boughtProducts.order_date}
              clearIcon={null}
              calendarIcon={null}
              disableCalendar={true}
            />{" "}
            | Purchase Value:
            <NumericFormat
              value={totalCost}
              thousandSeparator={true}
              prefix={"€ "}
              decimalScale={2}
              fixedDecimalScale={true}
              className="totalPurchSpend"
              disabled
            />
          </p>
          {prodArriving !== 0 ? (
            <>
              {" "}
              <p>
                Arrived: <strong>{prodArrived}</strong> / Bought:{" "}
                <strong>{prodBought}</strong>
              </p>
              <p>
                | Missing: <strong>{prodArriving}</strong>{" "}
              </p>
            </>
          ) : (
            <strong>Order Completed</strong>
          )}
        </header>
        <div className="expandButton">
          {isActive ? (
            <div className="alignCenter gap10">
              {isEditPurchActive && (
                <button className="delete" onClick={handleDelSuppPurch}>
                  Delete
                </button>
              )}
              {isEditPurchActive ? (
                <button
                  onClick={(e) => {
                    setIsEditPurchActive(false);
                    e.stopPropagation();
                  }}
                >
                  Confirm
                </button>
              ) : (
                <button
                  onClick={(e) => {
                    setIsEditPurchActive(true);
                    e.stopPropagation();
                  }}
                >
                  Edit
                </button>
              )}
              <IoChevronUpSharp />
            </div>
          ) : (
            <IoChevronDownSharp />
          )}
        </div>
      </div>
      <div>{isActive && children}</div>
    </div>
  );
}
