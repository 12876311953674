import { useEffect, useState } from "react";
import CreateClient from "./CreateClient";
import ClientList from "./ClientList";
import ClientInfo from "./clientInfo/ClientInfo";
import axios from "axios";

export default function SalesMainPage({
  clientList,
  productsStock,
  onAddClientPurch,
  onModClientPurch,
  onModClient,
  onDelClient,
  onModProduct,
  onModCompound,
}) {
  const typeOfClient = [
    "Particular",
    "Empresarial",
    "Corporate",
    "Estado",
    "Funcionário",
    "Outros",
  ];
  const [costumerList, setCostumerList] = useState([]);
  const [soldItems, setSoldItems] = useState([]);

  function getCostumerList() {
    axios
      .get("https://caravela.dev/gestor-bolso-api/costumers", {
        withCredentials: true,
      })
      .then((resp) => {
        setCostumerList(resp.data);
      });
  }

  function getSoldItemsList() {
    axios
      .get("https://caravela.dev/gestor-bolso-api/productItems/sold", {
        withCredentials: true,
      })
      .then((resp) => {
        setSoldItems(resp.data.data);
        console.log(resp.data.data);
      });
  }

  const [WhichClient, setWhichClient] = useState("");
  const [clientSearched, setClientSearched] = useState(clientList);

  function handleSearch(e, srcType) {
    setClientSearched(clientList);
    let newSearch = clientList.filter((plan) =>
      Object.values(plan).some(
        (value) =>
          (typeof value === "string" || typeof value === "number") &&
          String(value).toLowerCase().includes(String(e).toLowerCase())
      )
    );

    if (srcType !== "all") {
      newSearch = newSearch.filter((type) => type.cltType === srcType);
    }

    setClientSearched(newSearch);
  }

  function handleSelClientInfo(e) {
    let clientFilter = costumerList.filter(
      (client) => client.customer_id === e
    );
    setWhichClient(clientFilter[0]);
  }

  useEffect(() => {
    setWhichClient(WhichClient);
    setClientSearched(clientList);
    getCostumerList();
    getSoldItemsList();
  }, [WhichClient, clientList]);

  return (
    <div>
      <CreateClient
        clientList={clientList}
        typeOfClient={typeOfClient}
        getCostumerList={getCostumerList}
      />
      <SearchClient onSearch={handleSearch} typeOfClient={typeOfClient} />
      <div className="spaceBetween">
        <ClientList
          clientList={clientSearched}
          setWhichClient={handleSelClientInfo}
          costumerList={costumerList}
        />
        <ClientInfo
          clientList={clientList}
          selClientInfo={WhichClient}
          productsStock={productsStock}
          onModClient={onModClient}
          onAddClientPurch={onAddClientPurch}
          onModClientPurch={onModClientPurch}
          onDelClient={onDelClient}
          setWhichClient={handleSelClientInfo}
          onModProduct={onModProduct}
          onModCompound={onModCompound}
          typeOfClient={typeOfClient}
          getCostumerList={getCostumerList}
          soldItems={soldItems}
        />
      </div>
    </div>
  );
}

function SearchClient({ onSearch, typeOfClient }) {
  const [search, setSearch] = useState("");
  const [inType, setInType] = useState("all");

  const handleSubmit = (e) => {
    e.preventDefault();
    onSearch(search, inType);
  };

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  const handleChangeType = (e) => {
    setInType(e.target.value);
  };

  useEffect(() => {
    onSearch(search, inType);
  }, [search, inType]);

  return (
    <>
      <div className="searchPlan centerBetween">
        <form onSubmit={handleSubmit}>
          <div className="alignCenter gap10">
            <input
              type="text"
              placeholder="🔍 Search"
              value={search}
              onChange={handleChange}
            />
            <label>Filter:</label>
            <select
              className="filter"
              value={inType}
              onChange={handleChangeType}
            >
              <option value="all">All</option>
              {typeOfClient.map((type, i) => (
                <option value={type} key={"serType" + i}>
                  {type}
                </option>
              ))}
            </select>
          </div>
        </form>
      </div>
    </>
  );
}
