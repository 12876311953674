import { useEffect, useState, useCallback } from "react";
import { NumericFormat } from "react-number-format";
import axios from "axios";
import { IoChevronUpSharp } from "react-icons/io5";
import { IoChevronDownSharp } from "react-icons/io5";
import DatePicker from "react-date-picker";

export default function SupplierPurchases({
  selSupplierInfo,
  supplierList,
  prods,
  isEditPurchActive,
  productsStock,
  onDelProduct,
  onDelSupplierBuy,
  index,
}) {
  const [newSoldPrice, setNewSoldPrice] = useState(prods.purchaseValue);
  const [seeOrderSN, setSeeOrderSN] = useState(false);
  const [isActive, setIsActive] = useState(true);

  function handleSoldPrice(price) {
    const newValue = parseFloat(price.floatValue);
    setNewSoldPrice(newValue);
  }

  function delSuppProduct() {
    const locationIndex = productsStock.findIndex((ware) =>
      ware.stock.some((item) => item.sn === prods.sn)
    );
    const stockIndex = productsStock[locationIndex].stock.findIndex(
      (item) => item.sn === prods.sn
    );

    const suppLocationIndex = supplierList.findIndex(
      (info) => info.suppNum === selSupplierInfo.suppNum
    );

    const suppSNIndex = supplierList[suppLocationIndex].purchases[
      index
    ].sn.findIndex((sn) => sn === prods.sn);

    if (
      window.confirm(
        `Are you sure you want to DELETE ${prods.brand} ${prods.productName} ${prods.sn} from the supplier? This will remove the product too`
      ) === true
    ) {
      let isDelete = prompt("Type 'delete' to confirm");
      if (isDelete === "delete") {
        if (!productsStock[locationIndex].stock[stockIndex].available) {
          window.alert(
            "This product isn't avaible, check if it was sold to someone, if it's not make him available to be able to delete the product"
          );
        } else if (selSupplierInfo.purchases[index].sn.length === 1) {
          window.alert(
            "This purchase has only one item, use the Delete button instead"
          );
        } else {
          onDelSupplierBuy(suppSNIndex, suppLocationIndex, index);
          onDelProduct(stockIndex, locationIndex);
          window.alert("Purchase product deleted and product deleted");
        }
      } else {
        window.alert("Operation Canceled");
      }
    } else {
      window.alert("Operation Canceled");
    }
  }

  return (
    <div>
      <div>
        <div className="clientPurchaseList centerBetween LRPadding20 thinBox">
          <div className="productInput column">
            <label>Category</label>
            <input
              type="text"
              value={prods.category_name}
              className="clientPurchase"
              disabled
            />
          </div>
          <div className="productInput column">
            <label>Brand</label>
            <input
              type="text"
              value={prods.brand}
              className="clientPurchase"
              disabled
            />
          </div>
          <div className="productInput column">
            <label>Product Name</label>
            <input
              type="text"
              value={prods.product_name}
              className="clientPurchase"
              disabled
            />
          </div>

          <div className="productInput column">
            <label>Purchase Value</label>
            <NumericFormat
              value={prods.unit_cost}
              onValueChange={handleSoldPrice}
              thousandSeparator={true}
              suffix={"€"}
              decimalScale={2}
              fixedDecimalScale={true}
              className="clientPurchase"
              disabled
            />
          </div>

          <div className="productInput column">
            <label>Units Arrived</label>
            <input
              type="text"
              value={prods.product_items.length}
              className="clientPurchase"
              disabled
            />
          </div>

          {!isEditPurchActive ? (
            <>
              <p
                onClick={() => setSeeOrderSN(!seeOrderSN)}
                style={{ cursor: "pointer" }}
              >
                {seeOrderSN ? <IoChevronUpSharp /> : <IoChevronDownSharp />}
              </p>
            </>
          ) : (
            <strong style={{ cursor: "pointer" }} onClick={delSuppProduct}>
              x
            </strong>
          )}
        </div>
      </div>
      {seeOrderSN && (
        <div className="suppSeeSN">
          <ShowSN prods={prods} isActive={isActive} />
        </div>
      )}
    </div>
  );
}

function ShowSN({ prods, isActive }) {
  const [hoveredItem, setHoveredItem] = useState(null);
  const [selectedSN, setSelectedSN] = useState();

  const handleMouseEnter = (index) => {
    setHoveredItem(index);
  };

  const handleMouseLeave = () => {
    setHoveredItem(null);
  };

  return (
    <>
      {isActive &&
        prods.product_items.map((item, i) => (
          <div
            className="thinBox LRPadding20"
            style={{
              cursor: "pointer",
              backgroundColor: hoveredItem === i ? "darkgrey" : "transparent",
            }}
            onMouseEnter={() => handleMouseEnter(i)}
            onMouseLeave={handleMouseLeave}
            onClick={() => {
              setSelectedSN(item);
              console.log(selectedSN);
            }}
          >
            {item.serial_number}
          </div>
        ))}
      {selectedSN && (
        <div className="overlay">
          <SeeSNInfo
            prods={prods}
            selectedSN={selectedSN}
            setSelectedSN={setSelectedSN}
          />
        </div>
      )}
    </>
  );
}

function SeeSNInfo({ selectedSN, setSelectedSN }) {
  const [allSN, setAllSN] = useState([]);
  const [seeSelectedSN, setSeeSelectedSN] = useState();

  const getProductsSN = useCallback(() => {
    axios
      .get("https://caravela.dev/gestor-bolso-api/productItems", {
        withCredentials: true,
      })
      .then((resp) => {
        if (resp.data.ok) {
          const data = resp.data.data;
          setAllSN(data);
          let foundSN = data.findIndex(
            (info) => info.product_item_id === +selectedSN.product_item_id
          );
          setSeeSelectedSN(data[foundSN]);
          console.log(data[foundSN]);
        }
      });
  }, [selectedSN]);

  useEffect(() => {
    getProductsSN();
  }, [getProductsSN]);

  return (
    <form className="clientSeeProd thinBox">
      <div className="LRPadding20 column">
        <header className="centerBetween">
          <h1>Product Information</h1>
          <h3 onClick={() => setSelectedSN("")} style={{ cursor: "pointer" }}>
            X
          </h3>
        </header>
        {seeSelectedSN && (
          <ul className="productInputTable centerBetween">
            <div>
              <label>Category</label>
              <div className="alignCenter gap10">
                <input
                  className="productInput column"
                  value={seeSelectedSN.category_name}
                  disabled
                />
              </div>
            </div>

            <div>
              <label>Brand</label>
              <input
                type="text"
                value={seeSelectedSN.brand}
                className="productInput column"
                disabled
              />
            </div>

            <div>
              <label>Product name</label>
              <input
                type="text"
                value={seeSelectedSN.name}
                className="productInput column"
                disabled
              />
            </div>

            <div>
              <label>Location</label>
              <input
                type="text"
                className="productInput column"
                value={seeSelectedSN.warehouse_name}
                disabled
              />
            </div>

            <div>
              <label>Supplier</label>
              <input
                type="text"
                value={seeSelectedSN.supplier_name}
                className="productInput column"
                disabled
              />
            </div>

            <div>
              <label>Purchase Value</label>
              <NumericFormat
                value={seeSelectedSN.cost_price}
                thousandSeparator={true}
                prefix={"€ "}
                decimalScale={2}
                fixedDecimalScale={true}
                className="productInput column"
                disabled
              />
            </div>

            <div>
              <label>SN</label>
              <input
                type="text"
                value={seeSelectedSN.serial_number}
                className="productInput column"
                disabled
              />
            </div>

            <div>
              <label>EAN</label>
              <input
                type="text"
                value={seeSelectedSN.ean}
                className="productInput column"
                disabled
              />
            </div>

            <div>
              <label>SKU</label>
              <input
                type="text"
                value={seeSelectedSN.sku}
                className="productInput column"
                disabled
              />
            </div>

            <div className="productInput column">
              <label>Purchase supplier date</label>
              <DatePicker
                value={seeSelectedSN.created_at}
                calendarIcon={null}
                clearIcon={null}
                disabled
              />
            </div>

            <div className="productInput column">
              <label>Entry date</label>
              <DatePicker
                value={seeSelectedSN.entry_date}
                calendarIcon={null}
                clearIcon={null}
                disabled
              />
            </div>

            <div className="productInput column">
              <label>Sold date</label>
              <DatePicker
                value={
                  seeSelectedSN.sale_date !== null && seeSelectedSN.sale_date
                }
                clearIcon={null}
                calendarIcon={null}
                disabled
              />
            </div>
          </ul>
        )}
      </div>
    </form>
  );
}
