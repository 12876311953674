import axios from "axios";
import { useState, useEffect, useCallback } from "react";
import DatePicker from "react-date-picker";
import { IoChevronUpSharp } from "react-icons/io5";
import { IoChevronDownSharp } from "react-icons/io5";
import { NumericFormat } from "react-number-format";

export default function ProductInventory({ warehouses, selWhichProduct }) {
  const [prodsSN, setProdsSN] = useState([]);
  const [showingSN, setShowingSN] = useState([]);

  const getProductsSN = useCallback(() => {
    axios
      .get("https://caravela.dev/gestor-bolso-api/productItems", {
        withCredentials: true,
      })
      .then((resp) => {
        setShowingSN([]);
        setProdsSN(resp.data.data);
      })
      .then(() => {
        prodsSN.forEach((prodSN) => {
          if (
            selWhichProduct.product_item_ids.includes(prodSN.product_item_id)
          ) {
            setShowingSN((showSN) => [...showSN, prodSN]);
          }
        });
      });
  }, [selWhichProduct]);

  console.log(warehouses);

  useEffect(() => {
    getProductsSN();
  }, [getProductsSN]);

  return (
    <div className="WarehouseHeader column width100">
      {warehouses.map((warehouse, i) => (
        <WarehouseHeader
          warehouse={warehouse}
          key={"ware" + i}
          selWhichProduct={selWhichProduct}
        />
      ))}
    </div>
  );
}

function WarehouseHeader({ warehouse, selWhichProduct }) {
  const [whichSN, setWhichSN] = useState([]);
  const isAvailable = warehouse.product_items.filter(
    (product) =>
      product.state === "In Stock" &&
      product.product_id === selWhichProduct.product_id
  );

  // const isAvailableComp = compound.filter((product) => product.available);
  const [isActive, setIsActive] = useState(false);
  const [seeProductInfo, setSeeProductInfo] = useState(false);
  //
  //const separateStock = stockList.reduce  {stock.length + compound.length} -- Items Available:{" "}

  return (
    <div>
      <div
        className="centerBetween thinBox LRPadding20"
        onClick={() => setIsActive(!isActive)}
      >
        <header className="alignCenter gap10">
          <p className="alignCenter gap10">
            <strong>{warehouse.warehouse_name}</strong>
          </p>
          <p>- Items avaible: {isAvailable.length}</p>
        </header>
        <div className="expandButton">
          {isActive ? <IoChevronUpSharp /> : <IoChevronDownSharp />}
        </div>
      </div>
      <div>
        {isActive &&
          warehouse.product_items.map(
            (item) =>
              item.product_id === selWhichProduct.product_id && (
                <div
                  className="warehouseInventory thinBox centerBetween LRPadding20"
                  onClick={() => {
                    setWhichSN(item);
                    setSeeProductInfo(!seeProductInfo);
                  }}
                >
                  <div className="warehouseInventoryShort alignCenter ">
                    Entry Date:{" "}
                    <DatePicker
                      value={item.entry_date}
                      clearIcon={null}
                      calendarIcon={null}
                      disableCalendar={true}
                    />
                    {" / "}
                    SN: {item.serial_number}
                    {" / "}
                    {item.state === "In Stock" ? (
                      <strong>{item.state}</strong>
                    ) : (
                      <>
                        <strong>-</strong>
                        / Since:
                        <DatePicker
                          value={item.sale_date}
                          clearIcon={null}
                          calendarIcon={null}
                          disableCalendar={true}
                        />
                      </>
                    )}
                  </div>
                </div>
              )
          )}
      </div>
      {seeProductInfo && (
        <div className="overlay">
          <WarehouseItems
            whichSN={whichSN}
            setSeeProductInfo={setSeeProductInfo}
          />
        </div>
      )}
    </div>
  );
}

function WarehouseItems({ whichSN, setSeeProductInfo }) {
  const [isEdit, setIsEdit] = useState(false);
  const [prodSN, setProdSN] = useState(whichSN.serial_number);
  const [price, setPrice] = useState(whichSN.cost_price);
  const [currPrice, setCurrPrice] = useState(whichSN.current_price);
  const [obs, setObs] = useState(whichSN.observations);

  console.log(whichSN);

  function handleModProdSN(e) {
    e.preventDefault();

    axios
      .put(
        "https://caravela.dev/gestor-bolso-api/productItem",
        {
          product_item_id: whichSN.product_item_id,
          product_id: whichSN.product_id,
          serial_number: prodSN,
          cost_price: price,
          purchase_date: whichSN.purchase_date,
          entry_date: whichSN.entry_date,
          sale_date: whichSN.sale_date,
          current_price: currPrice,
          warehouse_id: whichSN.warehouse_id,
          state: whichSN.state === null ? "" : whichSN.state,
          supplier_id: whichSN.supplier_id,
          order_id: whichSN.order_id,
          observations: whichSN.observations === null ? "" : obs,
        },
        { withCredentials: true }
      )
      .then((resp) => {
        if (resp.data.ok) {
          window.alert(`${whichSN.brand} - ${whichSN.product_name} changed`);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function handleCurrPrice(price) {
    const newValue = parseFloat(price.floatValue);
    setCurrPrice(newValue);
  }

  function handlePrice(price) {
    const newValue = parseFloat(price.floatValue);
    setPrice(newValue);
  }

  return (
    <form className="clientSeeProd thinBox">
      <div className="LRPadding20 column">
        <header className="centerBetween">
          <h1>
            {whichSN.brand} - {whichSN.product_name}{" "}
          </h1>
          <h3
            onClick={() => setSeeProductInfo(false)}
            style={{ cursor: "pointer" }}
          >
            X
          </h3>
        </header>
        <ul className="productInputTable centerBetween">
          <div>
            <label>Supplier</label>
            <input
              type="text"
              value={whichSN.supplier_name}
              className="productInput column"
              disabled
            />
          </div>

          <div>
            <label>SN</label>
            <input
              type="text"
              value={prodSN}
              onChange={(e) => setProdSN(e.target.value)}
              className="productInput column"
              disabled={!isEdit}
            />
          </div>

          <div>
            <label>Purchase Value</label>
            <NumericFormat
              value={price}
              onChange={(e) => handlePrice(e.target.value)}
              thousandSeparator={true}
              prefix={"€ "}
              decimalScale={2}
              fixedDecimalScale={true}
              className="productInput column"
              disabled={!isEdit}
            />
          </div>

          <div>
            <label>Current Price</label>
            <NumericFormat
              value={currPrice}
              onChange={(e) => handleCurrPrice(e.target.value)}
              thousandSeparator={true}
              prefix={"€ "}
              decimalScale={2}
              fixedDecimalScale={true}
              className="productInput column"
              disabled={!isEdit}
            />
          </div>

          <div className="productInput column">
            <label>Purchase supplier date</label>
            <DatePicker
              value={whichSN.purchase_date}
              calendarIcon={null}
              clearIcon={null}
              disabled
            />
          </div>

          <div className="productInput column">
            <label>Entry date</label>
            <DatePicker
              value={whichSN.entry_date}
              calendarIcon={null}
              clearIcon={null}
              disabled
            />
          </div>

          {whichSN.state === "In Stock" ? (
            <h3>In Stock</h3>
          ) : (
            <div className="productInput column">
              <label>Sold date</label>
              <DatePicker
                value={whichSN.sale_date}
                clearIcon={null}
                calendarIcon={null}
                disabled
              />
            </div>
          )}

          <div className="productInput column"></div>

          <div>
            <label>Description</label>
            <textarea
              type="text"
              value={obs}
              onChange={(e) => setObs(e.target.value)}
              className="productInput column"
              disabled={!isEdit}
            />
          </div>

          <div className="productInput column"></div>
          {!isEdit ? (
            <button
              onClick={(e) => {
                setIsEdit(!isEdit);
                e.preventDefault();
              }}
            >
              Edit
            </button>
          ) : (
            <button onClick={(e) => handleModProdSN(e)}>confirm</button>
          )}
        </ul>
      </div>
    </form>
  );
}
