import DatePicker from "react-date-picker";
import { useState, useEffect } from "react";
import { NumericFormat } from "react-number-format";
import axios from "axios";

export default function CompoundList({
  category,
  supplierList,
  selSupplierInfo,
  setCheckList,
  onAddSupplierArriving,
  compoundList,
  productsStock,
  onAddCompound,
  onModProduct,
  warehouses,
}) {
  const [selecCategory, setSelecCategory] = useState("");
  const [location, setLocation] = useState("");
  const [productName, setProductName] = useState("");
  const [sku, setSKU] = useState("");
  const [ean, setEAN] = useState("");
  const [sn, setSN] = useState("");
  const [pvpValue, setPVPValue] = useState("");
  const [entryDate, setEntryDate] = useState("");
  const [available, setAvailable] = useState(false);
  const [totalPurchase, setTotalPurchase] = useState([]);
  const [totalProfit, setTotalProfit] = useState();

  console.log(compoundList);

  function handleFinishCompound() {
    const newDate = new Date();
    let month = newDate.getMonth() + 1;
    let day = newDate.getDate();
    const year = newDate.getFullYear();
    day = day < 10 ? `0${day}` : day;
    month = month < 10 ? `0${month}` : month;
    const formattedDate = `${day}/${month}/${year}`;

    const listForCompound = [];
    compoundList.forEach((item) => listForCompound.push(item.sn));

    const locationIndex = productsStock.findIndex(
      (ware, e) => ware.name === compoundList[e].warehouse
    );

    if (selecCategory === "") {
      window.alert("Please, insert a category!");
    } else if (!productName) {
      window.alert("Please, insert a product name!");
    } else if (!sku) {
      window.alert("Please, insert a SKU!");
    } else if (!ean) {
      window.alert("Please, insert an EAN!");
    } else if (setEntryDate === "") {
      window.alert("Please, insert an arrival forecast!");
    } else {
      const updatedSaleList = {
        category: selecCategory,
        productName,
        sku,
        ean,
        sn,
        purchaseValue: totalPurchase,
        pvpValue,
        entryDate,
        available,
        prodsSN: listForCompound,
        soldPrice: 0,
        soldDate: "",
        soldWho: "",
        obs: "",
      };
      onAddCompound(updatedSaleList, locationIndex);

      listForCompound.forEach((arr) => {
        const stockIndex = productsStock[locationIndex].stock.findIndex(
          (item) => item.sn === arr
        );
        const changedProduct = {
          ...productsStock[locationIndex].stock[stockIndex],
          available: false,
          soldPrice: 0,
          soldDate: formattedDate,
          soldWho: `${productName} - ${sku}`,
        };
        onModProduct(changedProduct, locationIndex, stockIndex);
      });
    }
  }

  function handlePVPValue(price) {
    const newValue = parseFloat(price.floatValue);
    setPVPValue(newValue);
    setTotalProfit(
      ((newValue / 1.23 - totalPurchase) / (newValue / 1.23)) * 100
    );
  }

  const handleEntryDateChange = (date) => {
    let day = date.getDate();
    let month = date.getMonth() + 1;
    const year = date.getFullYear();
    day = day < 10 ? `0${day}` : day;
    month = month < 10 ? `0${month}` : month;
    const formattedDate = `${month}/${day}/${year}`;
    setEntryDate(formattedDate);
  };

  // useEffect(() => {
  //   const purch = [];
  //   console.log(compoundList);
  //   compoundList.forEach((item) => purch.push(item.purchaseValue));
  //   let total = purch.reduce((acc, curr) => acc + curr, 0).toFixed(2);
  //   setTotalPurchase(total);
  // }, []);

  const [background, setBackground] = useState("");
  const [selProduct, setSelClient] = useState("");
  const [isBold, setIsBold] = useState("");
  function handleBackground(e) {
    setSelClient(e);
    setBackground("#ababab");
  }

  function handleBackgroundRes() {
    setBackground("");
  }

  function handleIsBold(e) {
    setIsBold(e);
  }

  const [productsList, setProductsList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [prodsToAdd, setProdsToAdd] = useState([]);

  function getProducts() {
    axios
      .get("https://caravela.dev/gestor-bolso-api/products", {
        withCredentials: true,
      })
      .then((resp) => {
        setProductsList(resp.data.data);
        console.log(resp.data.data);
      });
  }

  function getCategories() {
    axios
      .get("https://caravela.dev/gestor-bolso-api/categories", {
        withCredentials: true,
      })
      .then((resp) => {
        setCategoryList(resp.data);
      });
  }

  // function handleAddtoList(prodID, prodCat, bra, prodName, prodSku, prodEan) {
  //   const isSkuExisting = arrivingList.some((item) => item.sku === prodSku);

  //   if (!isSkuExisting) {
  //     const updatedSaleList = [
  //       ...arrivingList,
  //       {
  //         product_id: prodID,
  //         category_id: prodCat,
  //         brand: bra,
  //         name: prodName,
  //         sku: prodSku,
  //         ean: prodEan,
  //       },
  //     ];
  //     setArrivingList(updatedSaleList);
  //   } else {
  //     window.alert(`${prodName} already exists in the list.`);
  //   }
  // }

  useEffect(() => {
    getProducts();
    getCategories();
  }, []);

  console.log(compoundList);

  return (
    <div className="creatCltPurch">
      <div>
        <header className="centerBetween">
          <h1>Create a new compound</h1>
          <h3 onClick={() => setCheckList(false)} style={{ cursor: "pointer" }}>
            X
          </h3>
        </header>
        <div className="spaceBetween gap10">
          <div className="suppProdList LRPadding20 thinBox ">
            <div>
              <h2>Product list</h2>
              {productsList.map((prod, i) => (
                <ProductID
                  background={i === selProduct ? background : ""}
                  brand={prod.brand}
                  sku={prod.sku}
                  prodNum={prod.product_id}
                  onSelectClient={() => {
                    //handleAddtoList(prod.product_id);
                    handleIsBold(i);
                  }}
                  isBold={isBold}
                  onHoverIn={() => handleBackground(i)}
                  onHoverOut={handleBackgroundRes}
                  ind={i}
                />
              ))}
            </div>
          </div>
          <div className="suppArrList thinBox">
            <>
              <div>
                <h2 style={{ textAlign: "center", margin: "8px" }}>Products</h2>
                {compoundList.map((item) => (
                  <div className="compoundProducts thinBox gap10">
                    <div>{item.category} /</div>
                    <div>{item.brand} /</div>
                    <div>{item.productName} /</div>
                    <div>SKU: {item.sku} /</div>
                    <div>SN: {item.sn} /</div>
                    <div>PVP price: {item.average_cost} €</div>
                  </div>
                ))}
              </div>
              <div className="spaceBetween">
                <div></div>
                <h4>
                  Total purchase price: €{totalPurchase} / Profit:{" "}
                  {totalProfit?.toFixed(2)}%
                </h4>
              </div>
            </>
          </div>
        </div>
      </div>
    </div>
  );
}

function ProductID({
  brand,
  sku,
  prodNum,
  onSelectClient,
  onHoverIn,
  onHoverOut,
  background,
  ind,
  isBold,
}) {
  const [selected, setSelected] = useState(false);

  function handleselected(e) {
    setSelected(e);
  }

  return (
    <div
      onClick={() => {
        onSelectClient(prodNum);
        handleselected(ind);
      }}
      onMouseEnter={onHoverIn}
      onMouseLeave={onHoverOut}
      style={{
        backgroundColor: background,
        cursor: "pointer",
      }}
    >
      <div
        className="LRPadding20 thinBox alignCenter gap10"
        style={
          isBold === selected
            ? { fontWeight: "bold" }
            : { fontWeight: "normal" }
        }
      >
        <p>{brand}</p>
        <p>-</p>
        <p>{sku}</p>
        <p>{isBold === selected ? ">" : ""}</p>
      </div>
    </div>
  );
}
