import { useState } from "react";

export default function ProductList({ productsList, setWhichProduct }) {
  const [background, setBackground] = useState("");
  const [selProduct, setSelClient] = useState("");
  const [isBold, setIsBold] = useState("");
  function handleBackground(e) {
    setSelClient(e);
    setBackground("#ababab");
  }

  function handleBackgroundRes() {
    setBackground("#e6e6e6");
  }

  function handleIsBold(e) {
    setIsBold(e);
  }

  return (
    <div className="clientList LRPadding20 thinBox">
      <h2>Product list</h2>
      {productsList.map((prod, i) => (
        <ProductID
          background={i === selProduct ? background : ""}
          brand={prod.brand}
          sku={prod.sku}
          prodNum={prod.product_id}
          onSelectClient={(e) => {
            setWhichProduct(e);
            handleIsBold(i);
          }}
          isBold={isBold}
          onHoverIn={() => handleBackground(i)}
          onHoverOut={handleBackgroundRes}
          ind={i}
        />
      ))}
    </div>
  );
}

function ProductID({
  brand,
  sku,
  prodNum,
  onSelectClient,
  onHoverIn,
  onHoverOut,
  background,
  ind,
  isBold,
}) {
  const [selected, setSelected] = useState(false);

  function handleselected(e) {
    setSelected(e);
  }

  return (
    <div
      onClick={() => {
        onSelectClient(prodNum);
        handleselected(ind);
      }}
      onMouseEnter={onHoverIn}
      onMouseLeave={onHoverOut}
      style={{
        backgroundColor: background,
        cursor: "pointer",
      }}
    >
      <div
        className="LRPadding20 thinBox alignCenter gap10"
        style={
          isBold === selected
            ? { fontWeight: "bold" }
            : { fontWeight: "normal" }
        }
      >
        <p>{brand}</p>
        <p>-</p>
        <p>{sku}</p>
        <p>{isBold === selected ? ">" : ""}</p>
      </div>
    </div>
  );
}
