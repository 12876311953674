import { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import axios from "axios";
import DatePicker from "react-date-picker";

export default function ProductInfoEdit({
  selWhichProduct,
  onEdit,
  edit,
  categoryList,
  getProducts,
}) {
  const [productNum, setProductNum] = useState("");
  const [sinceDate, setSinceDate] = useState("");
  const [category, setCategory] = useState("");
  const [choosenCat, setChoosenCat] = useState("");
  const [prodbrand, setProdBrand] = useState("");
  const [productName, setProductName] = useState("");
  const [prodSKU, setProdSKU] = useState("");
  const [prodEAN, setProdEAN] = useState("");
  const [seeSN, setSeeSN] = useState("");
  const [prodDescon, setProdDescon] = useState("");
  const [average, setAverage] = useState("");

  function findCategory() {
    const foundCategory = categoryList.findIndex(
      (cat) => cat.category_id === +selWhichProduct.category_id
    );
    setCategory(categoryList[foundCategory].name);
  }

  useEffect(() => {
    if (selWhichProduct) {
      setProductNum(selWhichProduct.product_id);
      setSinceDate(selWhichProduct.created_at);
      findCategory();
      setChoosenCat(selWhichProduct.category_id);
      setProdBrand(selWhichProduct.brand);
      setProductName(selWhichProduct.name);
      setProdSKU(selWhichProduct.sku);
      setProdEAN(selWhichProduct.ean);
      setProdDescon(selWhichProduct.discontinued === 0 ? false : true);
      setAverage(selWhichProduct.average_cost);
    }
  }, [selWhichProduct]);

  console.log(selWhichProduct);

  function handleSubmit(e) {
    e.preventDefault();

    axios
      .put(
        "https://caravela.dev/gestor-bolso-api/product",
        {
          product_id: productNum,
          name: productName,
          sku: prodSKU,
          ean: prodEAN,
          category_id: choosenCat,
          brand: prodbrand,
          discontinued: prodDescon === false ? 0 : 1,
        },
        { withCredentials: true }
      )
      .then((resp) => {
        if (resp.data.ok) {
          getProducts();
          window.alert(`Product ${prodbrand} ${productName} edited`);
          onEdit();
        }
      })
      .catch((error) => {
        window.alert(error);
      });
  }

  function handleDelProduct(e) {
    e.preventDefault();
    if (
      window.confirm(
        `Are you sure you want to delete ${prodbrand} ${productName}?`
      )
    ) {
      let isDelete = prompt("Type 'delete' to confirm");
      if (isDelete === "delete") {
        axios
          .delete("https://caravela.dev/gestor-bolso-api/product", {
            withCredentials: true,
            data: {
              id: productNum,
            },
          })
          .then((resp) => {
            if (resp.data.ok) {
              getProducts();
              onEdit();
              window.confirm(`${prodbrand} ${productName} removed`);
            } else {
              window.alert("Choose again the product");
            }
          });
      }
    }
  }

  function handleCalcAvg(e) {
    e.preventDefault();

    axios
      .post(
        "https://caravela.dev/gestor-bolso-api/averageCost",
        {
          productId: productNum,
        },
        { withCredentials: true }
      )
      .then((resp) => {
        if (resp.data.ok) {
          getProducts();
          window.alert(
            `Average price of ${prodbrand} - ${productName} has been calculated`
          );
          onEdit();
        }
      })
      .catch((error) => {
        window.alert(error);
      });
  }

  return (
    <form>
      {!edit && (
        <div className="centerBetween LRPadding20">
          <h2>Product Info</h2>
          <div>
            <button onClick={handleCalcAvg}>Calc. Avg. Price</button>{" "}
            <button className="delete" onClick={handleDelProduct}>
              Delete Product
            </button>{" "}
            <button onClick={handleSubmit}>Confirm Edition</button>
          </div>
        </div>
      )}

      <div className="LRPadding20 column">
        <ul className="productInputTable centerBetween">
          <div>
            <label>Product Number</label>
            <input
              type="text"
              value={productNum}
              onChange={(e) => setProductNum(+e.target.value)}
              className="productInput column"
              disabled
            />
          </div>
          <div>
            <label>Brand</label>
            <input
              type="text"
              value={prodbrand}
              onChange={(e) => setProdBrand(e.target.value)}
              className="productInput column"
              disabled={edit}
            />
          </div>

          {edit ? (
            <div>
              <label>Category</label>
              <div className="alignCenter gap10">
                <input
                  type="text"
                  value={category}
                  className="productInput column"
                  disabled
                />
              </div>
            </div>
          ) : (
            <div className="productInput column">
              <label>Category</label>
              <select
                type="text"
                value={choosenCat}
                onChange={(e) => {
                  setChoosenCat(e.target.value);
                  findCategory();
                }}
              >
                <option value={selWhichProduct.category_id}>{category}</option>
                {categoryList.map((category, i) => (
                  <option value={category.category_id} key={"category" + i}>
                    {category.name}
                  </option>
                ))}
              </select>
            </div>
          )}
          <div>
            <label>Product Name</label>
            <input
              type="text"
              value={productName}
              onChange={(e) => setProductName(e.target.value)}
              className="productInput column"
              disabled={edit}
            />
          </div>
          <div>
            <label>SKU</label>
            <input
              type="text"
              value={prodSKU}
              onChange={(e) => setProdSKU(e.target.value)}
              className="productInput column"
              disabled={edit}
            />
          </div>
          <div>
            <label>EAN</label>
            <input
              type="text"
              value={prodEAN}
              onChange={(e) => setProdEAN(e.target.value)}
              className="productInput column"
              disabled={edit}
            />
          </div>

          <div>
            <label>Average Price</label>
            <NumericFormat
              value={average}
              thousandSeparator={true}
              suffix={"€"}
              decimalScale={2}
              fixedDecimalScale={true}
              className="productInput column"
              disabled
            />
          </div>

          <div className="productInput column">
            <label>Since Date</label>
            <DatePicker
              value={sinceDate}
              clearIcon={null}
              calendarIcon={null}
              disabled
            />
          </div>

          <div className="productInput">
            {selWhichProduct && selWhichProduct.in_stock_count > 0 ? (
              <label style={{ fontSize: 18 }}>
                Stock: <strong>{selWhichProduct.in_stock_count}</strong>
              </label>
            ) : (
              <h3 style={{ color: "red" }}>Sold Out</h3>
            )}
          </div>

          {!edit ? (
            <div>
              <label className="alignCenter">
                Descontinue
                <input
                  type="checkbox"
                  checked={prodDescon}
                  onChange={() => setProdDescon(!prodDescon)}
                />
              </label>
            </div>
          ) : prodDescon === true ? (
            <h3 style={{ color: "red" }}>Descontinued</h3>
          ) : (
            <div className="productInput"></div>
          )}
        </ul>
      </div>
    </form>
  );
}
