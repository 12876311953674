import { useState } from "react";
import axios from "axios";
import DatePicker from "react-date-picker";

export default function CreateSupplier({ getSupplierList }) {
  const [isActive, setIsActive] = useState(false);

  return (
    <div>
      <header className="centerBetween">
        <h1>Purchase</h1>
        <button onClick={() => setIsActive(true)} style={{ cursor: "pointer" }}>
          + Create New Supplier
        </button>
      </header>
      <div className={isActive ? "overlay" : ""}>
        {isActive && (
          <CreateClientButton
            getSupplierList={getSupplierList}
            onClickDes={() => setIsActive(false)}
          />
        )}
      </div>
    </div>
  );
}

function CreateClientButton({ onClickDes, getSupplierList }) {
  const [suppNum, setSuppNum] = useState("");
  const [suppVAT, setSuppVAT] = useState("");
  const [suppName, setSuppName] = useState("");
  const [suppAddress, setSuppAddress] = useState("");
  const [addressNum, setAddressNum] = useState("");
  const [addressCode, setAddressCode] = useState("");
  const [suppEmail, setSuppEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [obs, setOBS] = useState("");
  const [suppWebsite, setSuppWebsite] = useState("");

  function handleSubmit(e) {
    e.preventDefault();

    if (suppName === "") {
      window.alert("Please, insert a name");
    } else if (suppVAT.length !== 9) {
      window.alert("Please, insert a 9 digit VAT");
    } else {
      axios
        .post(
          "https://caravela.dev/gestor-bolso-api/supplier",
          {
            name: suppName,
            email: suppEmail,
            address: suppAddress,
            phone_number: phoneNumber,
            address_number: addressNum,
            address_code: addressCode,
            observations: obs,
            vat: suppVAT,
            website: suppWebsite,
          },
          { withCredentials: true }
        )
        .then((resp) => {
          if (resp.data.ok) {
            getSupplierList();
            window.alert(`Supplier ${suppVAT} - ${suppName} created`);
            onClickDes();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  return (
    <form className="creatProductForm" onSubmit={handleSubmit}>
      <header className="centerBetween">
        <h1>Create New Supplier</h1>
        <h3 onClick={onClickDes} style={{ cursor: "pointer" }}>
          X
        </h3>
      </header>
      <h2>Supplier Information</h2>
      <div className="productInformation">
        <ul className="productInputTable centerBetween">
          <div>
            <div className="column gap10">
              <div>
                <label>Supplier Number:</label>{" "}
                <input
                  style={{ width: `60px` }}
                  placeholder={suppNum + 1}
                  disabled
                />{" "}
              </div>
              <div>
                <label>Since Date:</label>{" "}
                <DatePicker
                  value={new Date()}
                  clearIcon={null}
                  calendarIcon={null}
                  disabled
                />
              </div>
            </div>
          </div>

          <div className="productInput column"></div>

          <div>
            <label>VAT</label>
            <input
              type="number"
              value={suppVAT}
              onChange={(e) => setSuppVAT(e.target.value)}
              className="productInput column"
            />
          </div>

          <div>
            <label>Name</label>
            <input
              type="text"
              value={suppName}
              onChange={(e) => setSuppName(e.target.value)}
              className="productInput column"
            />
          </div>

          <div>
            <label>Adress</label>
            <input
              type="text"
              value={suppAddress}
              onChange={(e) => setSuppAddress(e.target.value)}
              className="productInput column"
            />
          </div>

          <div>
            <label>Adress Number</label>
            <input
              type="text"
              value={addressNum}
              onChange={(e) => setAddressNum(e.target.value)}
              className="productInput column"
            />
          </div>

          <div>
            <label>Postal Code</label>
            <input
              type="text"
              value={addressCode}
              onChange={(e) => setAddressCode(e.target.value)}
              className="productInput column"
            />
          </div>

          <div>
            <label>E-mail</label>
            <input
              type="text"
              value={suppEmail}
              onChange={(e) => setSuppEmail(e.target.value)}
              className="productInput column"
            />
          </div>

          <div>
            <label>Phone Number</label>
            <input
              type="text"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              className="productInput column"
            />
          </div>

          <div>
            <label>Website</label>
            <input
              type="text"
              value={suppWebsite}
              onChange={(e) => setSuppWebsite(e.target.value)}
              className="productInput column"
            />
          </div>

          <div>
            <label>Obs.:</label>
            <div>
              <textarea
                className="clientObs"
                value={obs}
                onChange={(e) => setOBS(e.target.value)}
              />
            </div>
          </div>
          <div></div>
          <button>Create</button>
        </ul>
      </div>
    </form>
  );
}
