import { useEffect, useState } from "react";
import SupplierList from "./SupplierList";
import SupplierInfo from "./supplierInfo/SupplierInfo";
import CreateSupplier from "./CreateSupplier";
import axios from "axios";

export default function PurchaseMainPage({
  //supplierList,
  productsStock,
  category,
  onAddSupplier,
  onModSupplier,
  onAddSupplierArriving,
  onModSupplierArriving,
  onDelSupplierArriving,
  onAddSupplierBuy,
  onDelSupplier,
  onAddProduct,
  onDelProduct,
  onDelSupplierBuy,
}) {
  const [WhichSupplier, setWhichSupplier] = useState("");
  //const [supplierSearched, setSupplierSearched] = useState(supplierList);

  const [supplierList, setSupplierList] = useState([]);

  function getSupplierList() {
    axios
      .get("https://caravela.dev/gestor-bolso-api/supplierOrders", {
        withCredentials: true,
      })
      .then((resp) => {
        setSupplierList(resp.data.data);
        console.log(supplierList);
      });
  }

  // function handleSearch(e) {
  //   setSupplierSearched(supplierList);
  //   let newSearch = supplierList.filter((plan) =>
  //     Object.values(plan).some(
  //       (value) =>
  //         (typeof value === "string" || typeof value === "number") &&
  //         String(value).toLowerCase().includes(String(e).toLowerCase())
  //     )
  //   );

  //   setSupplierSearched(newSearch);
  // }

  function handleSelSupplierInfo(e) {
    let supplierFilter = supplierList.filter((supp) => supp.supplier_id === e);
    setWhichSupplier(supplierFilter[0]);
  }

  useEffect(() => {
    setWhichSupplier(WhichSupplier);
    //setSupplierSearched(supplierList);
    getSupplierList();
  }, [WhichSupplier]);

  return (
    <div>
      <CreateSupplier getSupplierList={getSupplierList} />

      <SearchClient onSearch={"handleSearch"} />

      <div className="spaceBetween">
        <SupplierList
          supplierList={supplierList}
          setWhichSupplier={handleSelSupplierInfo}
        />
        <SupplierInfo
          supplierList={supplierList}
          selSupplierInfo={WhichSupplier}
          productsStock={productsStock}
          onModSupplier={onModSupplier}
          onDelSupplier={onDelSupplier}
          onAddSupplierArriving={onAddSupplierArriving}
          onModSupplierArriving={onModSupplierArriving}
          onDelSupplierArriving={onDelSupplierArriving}
          onAddSupplierBuy={onAddSupplierBuy}
          setWhichSupplier={handleSelSupplierInfo}
          onAddProduct={onAddProduct}
          onDelProduct={onDelProduct}
          onDelSupplierBuy={onDelSupplierBuy}
          category={category}
          getSupplierList={getSupplierList}
        />
      </div>
    </div>
  );
}

function SearchClient({ onSearch }) {
  const [search, setSearch] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    onSearch(search);
  };

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  // useEffect(() => {
  //   onSearch(search);
  // }, [search]);

  return (
    <>
      <div className="searchPlan centerBetween">
        <form onSubmit={handleSubmit}>
          <div className="alignCenter gap10">
            <input
              type="text"
              placeholder="🔍 Search"
              value={search}
              onChange={handleChange}
            />
          </div>
        </form>
      </div>
    </>
  );
}
