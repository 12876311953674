import { useEffect, useState } from "react";
import SupplierInfoEdit from "./SupplierInfoEdit";
import SupplierPurchasesHeader from "./SupplierPurchasesHeader";
import SupplierPurchases from "./SupplierPurchases";
import SupplierArrivingButton from "./SupplierArrivingButton";
import SupplierArriving from "./SupplierArriving";
import ConvertArrToBuy from "./ConvertArrToBuy";
import CreateProductButton from "./CreateProductButton";

export default function SupplierInfo({
  supplierList,
  selSupplierInfo,
  productsStock,
  onAddSupplierArriving,
  onDelSupplierArriving,
  onAddSupplierBuy,
  onAddProduct,
  onDelProduct,
  onDelSupplierBuy,
  category,
  getSupplierList,
}) {
  const [edit, setEdit] = useState(true);
  const [isActive, setIsActive] = useState(false);
  const [isArriving, setIsArriving] = useState(false);
  const [isBuying, setIsBuying] = useState(false);
  const [spended, setSpended] = useState();
  const [isEditPurchActive, setIsEditPurchActive] = useState(false);
  const [purOrArr, setPurOrArr] = useState("Pur");

  function handleEdit() {
    setEdit(!edit);
  }

  useEffect(() => {
    setEdit(true);
    setIsEditPurchActive(false);
  }, [selSupplierInfo]);

  console.log(supplierList);

  return (
    <div className="clientInfo LRPadding20 thinBox">
      {edit && (
        <div className="centerBetween">
          <h2>Supplier Info</h2>
          {selSupplierInfo && edit && (
            <div className="centerBetween gap10">
              <button onClick={() => setEdit(false)}>Edit Supplier</button>
            </div>
          )}
        </div>
      )}
      <SupplierInfoEdit
        selSupplierInfo={selSupplierInfo}
        edit={edit}
        onEdit={handleEdit}
        getSupplierList={getSupplierList}
      />

      {selSupplierInfo && (
        <div className="width100">
          <div className="centerBetween">
            <div className="centerBetween">
              <h3>Purchases: {selSupplierInfo.purchases.length} </h3>
            </div>
            {selSupplierInfo.products_quantity_to_arrive > 0 ? (
              <h3>Arriving: {selSupplierInfo.products_quantity_to_arrive} </h3>
            ) : (
              ""
            )}
            <div className="alignCenter gap10">
              {selSupplierInfo.products_quantity_to_arrive > 0 ? (
                <button onClick={() => setIsArriving(true)}>Arrived?</button>
              ) : (
                ""
              )}
              <button onClick={() => setIsActive(true)}>
                Create New Arriving
              </button>
            </div>
          </div>
          {selSupplierInfo.purchases.map((purch, i) => (
            <SupplierPurchasesHeader
              boughtProducts={purch}
              isEditPurchActive={isEditPurchActive}
              setIsEditPurchActive={setIsEditPurchActive}
              getSupplierList={getSupplierList}
            >
              {purch.products.map((prods) => (
                <SupplierPurchases
                  selSupplierInfo={selSupplierInfo}
                  supplierList={supplierList}
                  prods={prods}
                  isEditPurchActive={isEditPurchActive}
                  productsStock={productsStock}
                  onDelProduct={onDelProduct}
                  onDelSupplierBuy={onDelSupplierBuy}
                  index={i}
                />
              ))}
            </SupplierPurchasesHeader>
          ))}
          {/* {purOrArr === "Pur" ? (
            selSupplierInfo.purchases.length > 0 ? (
              selSupplierInfo.purchases.every((purch) =>
                purch.products.every(
                  (arr) =>
                    arr.quantity_arrived === 0 || arr.quantity_arrived === null
                )
              ) ? (
                <p>We had bought nothing yet</p>
              ) : (
                selSupplierInfo.purchases
                  .filter((purch) =>
                    purch.products.some((arr) => arr.quantity_arrived > 0)
                  )
                  .map((purch, i) => (
                    <SupplierPurchasesHeader
                      boughtProducts={purch}
                      isEditPurchActive={isEditPurchActive}
                      setIsEditPurchActive={setIsEditPurchActive}
                      getSupplierList={getSupplierList}
                    >
                      {purch.products.map((prods) => (
                        <SupplierPurchases
                          selSupplierInfo={selSupplierInfo}
                          supplierList={supplierList}
                          prods={prods}
                          isEditPurchActive={isEditPurchActive}
                          productsStock={productsStock}
                          onDelProduct={onDelProduct}
                          onDelSupplierBuy={onDelSupplierBuy}
                          index={i}
                        />
                      ))}
                    </SupplierPurchasesHeader>
                  ))
              )
            ) : (
              <p>We had bough nothing yet</p>
            )
          ) : selSupplierInfo.purchases.length > 0 ? (
            selSupplierInfo.purchases.map((pur) =>
              pur.products.map(
                (arr) =>
                  arr.quantity_to_arrive !== 0 && (
                    <SupplierArriving
                      purchases={pur}
                      arrProducts={arr}
                      selSupplierInfo={selSupplierInfo}
                      getSupplierList={getSupplierList}
                      setWhichSupplier={setWhichSupplier}
                    />
                  )
              )
            )
          ) : (
            <p>We have no bids at the moment</p>
          )} */}
          {isBuying && (
            <div className="overlay">
              <CreateProductButton
                selSupplierInfo={selSupplierInfo}
                productsStock={productsStock}
                setIsBuying={setIsBuying}
                category={category}
                onAddProduct={onAddProduct}
                supplierList={supplierList}
                onAddSupplierBuy={onAddSupplierBuy}
              />
            </div>
          )}
          {isActive && (
            <div className="overlay">
              <SupplierArrivingButton
                onAddSupplierArriving={onAddSupplierArriving}
                category={category}
                supplierList={supplierList}
                selSupplierInfo={selSupplierInfo}
                setIsActive={setIsActive}
              />
            </div>
          )}
          {isArriving && (
            <div className="overlay">
              <ConvertArrToBuy
                selSupplierInfo={selSupplierInfo}
                supplierList={supplierList}
                productsStock={productsStock}
                category={category}
                onAddProduct={onAddProduct}
                onAddSupplierBuy={onAddSupplierBuy}
                onDelSupplierArriving={onDelSupplierArriving}
                setIsArriving={setIsArriving}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
