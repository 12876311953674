import { MdOutlineDashboard } from "react-icons/md";
import { TbBoxSeam } from "react-icons/tb";
import { IoCartOutline } from "react-icons/io5";
import { SlNotebook } from "react-icons/sl";
import { MdOutlineInventory } from "react-icons/md";
import { useState } from "react";

export default function HeaderPages({ onElementChoosen }) {
  const [fontSize, setFontSize] = useState(20);
  const [boldFont, setBoldFont] = useState("normal");
  const [choose, setChoose] = useState("");
  const [selected, setSelected] = useState("dashboard");

  function handleFontSizeInc(e) {
    setChoose(e);
    setFontSize(22);
    setBoldFont("bold");
  }

  function handleFontSizeDec() {
    setFontSize(20);
    setBoldFont("normal");
    setChoose("");
  }

  return (
    <div className="mainHeader">
      <HeaderPagesComp
        style={{
          ...(choose === "dashboard"
            ? { fontSize: `${fontSize}px`, fontWeight: `${boldFont}` }
            : {}),
          ...(selected === "dashboard"
            ? { fontSize: `22px`, fontWeight: "bold" }
            : {}),
        }}
        onClickChoose={() => {
          onElementChoosen("dashboard");
          setSelected("dashboard");
        }}
        onHoverIn={() => handleFontSizeInc("dashboard")}
        onHoverOut={handleFontSizeDec}
      >
        <MdOutlineDashboard /> Dashboard
      </HeaderPagesComp>

      <HeaderPagesComp
        style={{
          ...(choose === "products"
            ? { fontSize: `${fontSize}px`, fontWeight: `${boldFont}` }
            : {}),
          ...(selected === "products"
            ? { fontSize: `22px`, fontWeight: "bold" }
            : {}),
        }}
        onClickChoose={() => {
          onElementChoosen("products");
          setSelected("products");
        }}
        onHoverIn={() => handleFontSizeInc("products")}
        onHoverOut={handleFontSizeDec}
      >
        <TbBoxSeam /> Products
      </HeaderPagesComp>

      <HeaderPagesComp
        style={{
          ...(choose === "sales"
            ? { fontSize: `${fontSize}px`, fontWeight: `${boldFont}` }
            : {}),
          ...(selected === "sales"
            ? { fontSize: `22px`, fontWeight: "bold" }
            : {}),
        }}
        onClickChoose={() => {
          onElementChoosen("sales");
          setSelected("sales");
        }}
        onHoverIn={() => handleFontSizeInc("sales")}
        onHoverOut={handleFontSizeDec}
      >
        <IoCartOutline /> Sales
      </HeaderPagesComp>

      <HeaderPagesComp
        style={{
          ...(choose === "purchase"
            ? { fontSize: `${fontSize}px`, fontWeight: `${boldFont}` }
            : {}),
          ...(selected === "purchase"
            ? { fontSize: `22px`, fontWeight: "bold" }
            : {}),
        }}
        onClickChoose={() => {
          onElementChoosen("purchase");
          setSelected("purchase");
        }}
        onHoverIn={() => handleFontSizeInc("purchase")}
        onHoverOut={handleFontSizeDec}
      >
        <SlNotebook /> Purchase
      </HeaderPagesComp>

      <HeaderPagesComp
        style={{
          ...(choose === "plans"
            ? { fontSize: `${fontSize}px`, fontWeight: `${boldFont}` }
            : {}),
          ...(selected === "plans"
            ? { fontSize: `22px`, fontWeight: "bold" }
            : {}),
        }}
        onClickChoose={() => {
          onElementChoosen("plans");
          setSelected("plans");
        }}
        onHoverIn={() => handleFontSizeInc("plans")}
        onHoverOut={handleFontSizeDec}
      >
        <MdOutlineInventory /> Inventory Plan
      </HeaderPagesComp>
    </div>
  );
}

function HeaderPagesComp({
  children,
  style,
  onClickChoose,
  onHoverIn,
  onHoverOut,
}) {
  return (
    <div
      className="noMove alignCenter"
      style={{ ...style, cursor: "pointer" }}
      onClick={onClickChoose}
      onMouseEnter={onHoverIn}
      onMouseLeave={onHoverOut}
    >
      {children}
    </div>
  );
}
