import { FaUserCircle } from "react-icons/fa";
import { FaRegBell } from "react-icons/fa";
import { useState } from "react";
import { MdOutlineNightsStay } from "react-icons/md";
import { MdOutlineWbSunny } from "react-icons/md";

export default function UserOptions() {
  const [isDark, setIsDark] = useState(false);

  function useDarkTheme() {
    const topBar = document.getElementById("topBar");

    setIsDark((prevTheme) => {
      const newTheme = !prevTheme;
      if (newTheme) {
        document.body.classList.add("dark-theme");
        topBar.classList.add("dark-theme");
      } else {
        document.body.classList.remove("dark-theme");
        topBar.classList.remove("dark-theme");
      }
      return newTheme;
    });
  }

  return (
    <div className="userOptions">
      <UserOptionsComp>
        <div onClick={useDarkTheme}>
          {isDark ? <MdOutlineWbSunny /> : <MdOutlineNightsStay />}{" "}
        </div>
      </UserOptionsComp>
      <UserOptionsComp>
        <FaRegBell />
      </UserOptionsComp>
      <UserOptionsComp>
        <FaUserCircle />
      </UserOptionsComp>
    </div>
  );
}

function UserOptionsComp({ children }) {
  return <div>{children}</div>;
}
