import { useEffect, useState } from "react";
import ProductInfoEdit from "./ProductInfoEdit";
import ProductStock from "./ProductStock";
import ProductInventory from "./ProductInventory";

export default function ProductInfo({
  selWhichProduct,
  warehouses,
  categoryList,
  getProducts,
}) {
  const [edit, setEdit] = useState(true);
  const [isActive, setIsActive] = useState(false);
  const [spended, setSpended] = useState();
  const [isEditPurchActive, setIsEditPurchActive] = useState(false);

  function handleEdit() {
    setEdit(!edit);
  }

  return (
    <div className="clientInfo LRPadding20 thinBox">
      {edit && (
        <div className="centerBetween LRPadding20">
          <h2>Product Info</h2>
          {selWhichProduct && edit && (
            <button onClick={() => setEdit(false)}>Edit Product</button>
          )}
        </div>
      )}
      <ProductInfoEdit
        selWhichProduct={selWhichProduct}
        categoryList={categoryList}
        edit={edit}
        onEdit={handleEdit}
        getProducts={getProducts}
      />
      {selWhichProduct && (
        <div className="WarehouseHeader LRPadding20">
          <button>Show Inventory</button>
          <div className="centerBetween" onClick={() => setIsActive(!isActive)}>
            <ProductInventory
              warehouses={warehouses}
              selWhichProduct={selWhichProduct}
            />
          </div>
        </div>
      )}
    </div>
  );
}
