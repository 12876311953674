import TopDashboard from "./TopDashboard";
import InventoryPlansBoard from "./InventoryPlansBoard";
import WarehouseDetail from "./WarehouseDetail";

import axios from "axios";

export default function DashboardMainPage({
  productsStock,
  addPlan,
  onSetElementChoosen,
}) {
  axios
    .post(
      "https://caravela.dev/gestor-bolso-api/login",
      {
        email: "alixcarmo03@protonmail.com",
        password: "123456",
      },
      {
        withCredentials: true,
      }
    )
    .then((resp) => {
      console.log(resp.data);
    });

  return (
    <>
      <header>
        <h1>Dashboard</h1>
      </header>
      <div className="gap50 column">
        <div className="topDashboard gap50">
          <TopDashboard
            title={"Inventory Summary"}
            leftInfo={"Total Items"}
            leftInfoNumbers={"1000"}
            rightInfo={"Low-Stock Alerts"}
            rightInfoNumbers={"12"}
          />
          <TopDashboard
            title={"Sales activities"}
            leftInfo={"To be delivered"}
            leftInfoNumbers={"200"}
            rightInfo={"To be Ordered"}
            rightInfoNumbers={"120"}
          />
        </div>
        <div className="midDashboard gap50 width100">
          <InventoryPlansBoard
            addPlan={addPlan}
            onSetElementChoosen={onSetElementChoosen}
          />
          <WarehouseDetail productsStock={productsStock} />
        </div>
      </div>
    </>
  );
}
