import DatePicker from "react-date-picker";
import { NumericFormat } from "react-number-format";
import { useState, useEffect } from "react";
import axios from "axios";

export default function ConvertAddSN({
  arrivingList,
  selSupplierInfo,
  warehouses,
}) {
  const [selecCategory, setSelecCategory] = useState(
    arrivingList.category_name
  );
  const [brand, setBrand] = useState(arrivingList.brand);
  const [productName, setProductName] = useState(arrivingList.product_name);
  const [sku, setSKU] = useState(arrivingList.sku);
  const [ean, setEAN] = useState(arrivingList.ean);
  const [snArray, setSNArray] = useState("");
  const [purchaseValue, setPurchaseValue] = useState(arrivingList.unit_cost);
  const [location, setLocation] = useState("");
  const [units, setUnits] = useState(arrivingList.quantity_bought);
  const [orderItemID, setOrderItemID] = useState(arrivingList.order_item_id);
  const [done, setDone] = useState(false);
  const [numOfSN, setNumOfSN] = useState([]);

  function handleAddProduct() {
    const snValues = snArray.split(",").map((value) => value.trim());

    let text;

    let checkSN = snValues.find((sn) => sn === "");

    if (location === "") {
      window.alert("Please choose a location");
    } else if (snArray === "") {
      window.alert("Please insert at least one SN");
    } else if (checkSN !== undefined) {
      window.alert('There is an empty SN, check if there is any ", ,"');
    } else {
      text = "Are you sure you want to add the products?";
      if (window.confirm(text) === true) {
        if (units > snValues.length) {
          text =
            "There is less SN counted than units, are you sure you want to confirm? ";
          if (window.confirm(text) === true) {
            axios
              .post(
                `https://caravela.dev/gestor-bolso-api/supplierOrder/products`,
                {
                  order_item_id: orderItemID,
                  sns: numOfSN,
                  warehouse_id: location,
                },
                {
                  withCredentials: true,
                }
              )

              .then((resp) => {
                if (resp.data.ok) {
                  setDone(true);
                  window.alert("Product added");
                } else {
                  console.log(resp);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            window.alert("Not confirmed");
          }
        } else {
          axios
            .post(
              `https://caravela.dev/gestor-bolso-api/supplierOrder/products`,
              {
                order_item_id: orderItemID,
                sns: numOfSN,
                warehouse_id: location,
              },
              {
                withCredentials: true,
              }
            )

            .then((resp) => {
              if (resp.data.ok) {
                setDone(true);
                window.alert("Product added");
              } else {
                console.log(resp);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      } else {
        window.alert("Not confirmed");
      }
    }
  }

  useEffect(() => {
    const snValues = snArray.split(",").map((value) => value.trim());
    setNumOfSN(snValues);
  }, [snArray, units]);

  return (
    <>
      <h3 className="suppArrListSep">
        {arrivingList.brand} {arrivingList.product_name}
      </h3>
      <form className="productDescription thinBox">
        <div className="LRPadding20 column">
          <ul className="productInputTable centerBetween">
            <div>
              <label>Category</label>
              <input
                type="text"
                value={selecCategory}
                className="productInput column"
                disabled
              />
            </div>

            <div>
              <label>Brand</label>
              <input
                type="text"
                value={brand}
                onChange={(e) => setBrand(e.target.value)}
                className="productInput column"
                disabled
              />
            </div>

            <div>
              <label>Product name</label>
              <input
                type="text"
                value={productName}
                onChange={(e) => setProductName(e.target.value)}
                className="productInput column"
                disabled
              />
            </div>

            <div>
              <label>Purchase Value</label>
              <NumericFormat
                value={purchaseValue}
                thousandSeparator={true}
                suffix={" €"}
                decimalScale={2}
                fixedDecimalScale={true}
                className="productInput column"
                disabled
              />
            </div>

            <div>
              <label>Location</label>
              <div className="alignCenter gap10">
                <select
                  className="filter larger"
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                >
                  <option value={""}>Select a Warehouse</option>
                  {warehouses.map((warehouse, i) => (
                    <option
                      value={warehouse.warehouse_id}
                      key={"warehouse" + i}
                    >
                      {warehouse.warehouse_name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div>
              <label>Supplier</label>
              <input
                type="text"
                value={selSupplierInfo.name}
                className="productInput column"
                disabled
              />
            </div>

            <div>
              <label>EAN</label>
              <input
                type="text"
                value={ean}
                onChange={(e) => setEAN(e.target.value)}
                className="productInput column"
                disabled
              />
            </div>

            <div>
              <label>SKU</label>
              <input
                type="text"
                value={sku}
                onChange={(e) => setSKU(e.target.value)}
                className="productInput column"
                disabled
              />
            </div>

            <div>
              <label>Units</label>
              <input
                type="number"
                value={units}
                onChange={(e) => setUnits(e.target.value)}
                className="productInput column"
              />
            </div>

            <div className="productInput column">
              <label>Entry date</label>
              <DatePicker
                className="dates"
                value={new Date()}
                clearIcon={null}
                calendarIcon={null}
                disabled
              />
            </div>

            <div>
              <label className="centerBetween">
                SN {'(use "," for multiple SN )'}{" "}
                <span>
                  {snArray ? numOfSN.length : 0} of {units}
                </span>
              </label>

              <textarea
                type="text"
                value={snArray}
                onChange={(e) => setSNArray(e.target.value)}
                className="suppArrListSn column"
              />
            </div>
          </ul>
        </div>
      </form>
      <div className="spaceBetween">
        <div></div>
        {done ? (
          <strong>Added</strong>
        ) : (
          <button className="confirmButton" onClick={handleAddProduct}>
            Confirm
          </button>
        )}
      </div>
    </>
  );
}
