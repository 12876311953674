import PlanInfo from "./PlanInfo";
import { IoChevronUpSharp } from "react-icons/io5";
import { IoChevronDownSharp } from "react-icons/io5";
import { useState } from "react";

export default function PlansPage({ plansList, getPlans }) {
  const urgentPlans = plansList.filter(
    (plan) => plan.timming === "Urgent" && !plan.finished
  );
  const normalPlans = plansList.filter(
    (plan) => plan.timming === "Normal" && !plan.finished
  );
  const donePlans = plansList.filter((plan) => plan.finished);

  const plansInfo = function (plan) {
    return {
      planNumber: plan.id,
      planTitle: plan.title,
      planTimming: plan.timming,
      planWarehouse: plan.warehouse,
      planDescription: plan.description,
      planEntryDate: plan.entry_date,
      planFinishDate: plan.finish_date,
      planFinished: plan.finished,
    };
  };

  return (
    <div className="warehousePage" key="warehousePage">
      <PlanHeader planTimming={"Urgent"} num={urgentPlans.length}>
        {urgentPlans.map((plan, i) => (
          <PlanInfo
            plansInfo={plansInfo(plan)}
            getPlans={getPlans}
            key={"planUr" + i}
          />
        ))}
      </PlanHeader>

      <PlanHeader
        planTimming={"Normal"}
        finished={false}
        num={normalPlans.length}
      >
        {normalPlans.map((plan, i) => (
          <PlanInfo
            plansInfo={plansInfo(plan)}
            getPlans={getPlans}
            key={"planNr" + i}
          />
        ))}
      </PlanHeader>

      <PlanHeader finished={true} num={donePlans.length}>
        {donePlans.map((plan, i) => (
          <PlanInfo
            plansInfo={plansInfo(plan)}
            getPlans={getPlans}
            key={"planDn" + i}
          />
        ))}
      </PlanHeader>
    </div>
  );
}

function PlanHeader({ planTimming, finished, num, children }) {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="WarehouseHeader">
      <div
        className="centerBetween thinBox LRPadding20"
        onClick={() => setIsActive(!isActive)}
      >
        <header className="alignCenter gap10">
          <p className="alignCenter gap10">
            {finished
              ? "Finished -"
              : planTimming === "Urgent"
              ? "Urgent -"
              : "Still for to do -"}
          </p>
          <p>Total plans: {num}</p>
        </header>
        <div className="expandButton">
          {isActive ? <IoChevronUpSharp /> : <IoChevronDownSharp />}
        </div>
      </div>
      <div>{isActive && children}</div>
    </div>
  );
}
