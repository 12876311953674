import { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import { FaArrowRight } from "react-icons/fa";
import { IoChevronUpSharp } from "react-icons/io5";
import { IoChevronDownSharp } from "react-icons/io5";
import DatePicker from "react-date-picker";

export default function ClientPurchasesHeader({
  purch,
  selClientInfo,
  productsStock,
  purchNum,
  purchDate,
  onModClientPurch,
  onModProduct,
  onModCompound,
  isEditPurchActive,
  setIsEditPurchActive,
  children,
}) {
  const [isActive, setIsActive] = useState(false);

  console.log(purch);

  function handleDelCltPurch(e) {
    e.stopPropagation();

    if (
      window.confirm("Are you sure you want to DELETE whole purchase?") === true
    ) {
      let isDelete = prompt("Type 'delete' to confirm");
      if (isDelete === "delete") {
        purch.sn.forEach((item) => {
          const locationIndex = productsStock.findIndex((ware) =>
            ware.stock.some((stock) => stock.sn === item)
          );
          const stockIndex = productsStock[locationIndex].stock.findIndex(
            (prod) => prod.sn === item
          );
          if (stockIndex === -1) {
            const compIndex = productsStock[locationIndex].compound.findIndex(
              (item) => item.sn === item
            );
            const changedProduct = {
              ...productsStock[locationIndex].compound[compIndex],
              available: true,
              soldPrice: 0,
              soldDate: "",
              soldWho: "",
            };
            onModCompound(changedProduct, locationIndex, compIndex);
          } else {
            const changedProduct = {
              ...productsStock[locationIndex].stock[stockIndex],
              available: true,
              soldPrice: 0,
              soldDate: "",
              soldWho: "",
            };
            onModProduct(changedProduct, locationIndex, stockIndex);
          }
        });

        const purchIndex = selClientInfo.purchases.findIndex(
          (purch) => purch.purchNumber === purchNum - 1
        );

        onModClientPurch(selClientInfo.cltNum, purchIndex);
        window.alert("Purchase deleted");
        setIsEditPurchActive(false);
      } else {
        window.alert("Operation Canceled");
      }
    } else {
      window.alert("Operation Canceled");
    }
  }

  useEffect(() => {
    setIsActive(false);
  }, [purchNum, purchDate]);

  return (
    <div className="WarehouseHeader">
      <div
        className="centerBetween thinBox LRPadding20"
        onClick={() => setIsActive(!isActive)}
      >
        <header className="alignCenter gap10">
          <p className="alignCenter gap10">
            Purchase Nº: <strong>{purch.sale_id}</strong> <FaArrowRight />
            <DatePicker
              value={purch.sale_date}
              clearIcon={null}
              calendarIcon={null}
              disableCalendar={true}
            />{" "}
            | w/VAT:
            <NumericFormat
              value={purch.total_price}
              thousandSeparator={true}
              prefix={"€ "}
              decimalScale={2}
              fixedDecimalScale={true}
              className="totalPurchSpend"
              disabled
            />
          </p>
        </header>
        <div className="expandButton">
          {isActive ? (
            <div className="alignCenter gap10">
              {isEditPurchActive && (
                <button className="delete" onClick={handleDelCltPurch}>
                  Delete
                </button>
              )}
              {isEditPurchActive ? (
                <button
                  onClick={(e) => {
                    setIsEditPurchActive(false);
                    e.stopPropagation();
                  }}
                >
                  Confirm
                </button>
              ) : (
                <button
                  onClick={(e) => {
                    setIsEditPurchActive(true);
                    e.stopPropagation();
                  }}
                >
                  Edit
                </button>
              )}
              <IoChevronUpSharp />
            </div>
          ) : (
            <IoChevronDownSharp />
          )}
        </div>
      </div>
      <div>{isActive && children}</div>
    </div>
  );
}
