import { TbUrgent } from "react-icons/tb";
import { RiTodoLine } from "react-icons/ri";
import { useState, useEffect } from "react";
import axios from "axios";

export default function InventoryPlansBoard({ addPlan, onSetElementChoosen }) {
  const [boldFont, setBoldFont] = useState("normal");
  const [choose, setChoose] = useState("");
  const [plansList, setPlansList] = useState([]);

  function getPlans() {
    axios
      .get("https://caravela.dev/gestor-bolso-api/plans", {
        withCredentials: true,
      })
      .then((resp) => {
        setPlansList(resp.data.data);
        console.log(resp.data.data);
      });
  }

  const datePlan = plansList.sort((a, b) => {
    const dateA = new Date(a.entry_date);
    const dateB = new Date(b.entry_date);
    return dateA - dateB;
  });

  const activeDatePlan = datePlan.filter((plan) => plan.finished === 0);

  function handleFontSizeInc(e) {
    setChoose(e);
    setBoldFont("bold");
  }

  function handleFontSizeDec() {
    setBoldFont("normal");
    setChoose("");
  }

  useEffect(() => {
    getPlans();
  }, []);

  return (
    <div className="inventoryPlansBoard thinBox">
      <div className="centerBetween">
        <h2>Inventory Plans</h2>
        <p
          style={choose === "yes" ? { fontWeight: `${boldFont}` } : {}}
          onClick={() => onSetElementChoosen("plans")}
          onMouseEnter={() => handleFontSizeInc("yes")}
          onMouseLeave={handleFontSizeDec}
        >
          View detail {">"}
        </p>
      </div>
      <div>
        {activeDatePlan.length > 0 ? (
          activeDatePlan.map((plan, i) => (
            <InventoryPlans
              key={"plan" + i}
              timming={plan.timming}
              title={plan.title}
              description={plan.description}
              warehouse={plan.warehouse}
              created={plan.entryDate}
              finished={plan.finished}
            />
          ))
        ) : (
          <strong>There are no plans at the moment</strong>
        )}
      </div>
    </div>
  );
}

function InventoryPlans({
  timming,
  title,
  description,
  warehouse,
  created,
  finished,
}) {
  return (
    <div className="inventoryPlans thinBox centerBetween">
      <div className="centerBetween gap50">
        <p className="inventoryPlansIcon">
          {timming === "Urgent" ? <TbUrgent /> : <RiTodoLine />}
        </p>
        <div className="dashboardPlans">
          <p>
            {timming === "Urgent" ? <strong>[Urgent] {title}</strong> : title}
          </p>
          <p>{description}</p>
        </div>
      </div>
      <p className="dashboardPlansInfo">{warehouse}</p>
      <div className="dashboardPlansInfo textCenter">
        <p>Created date:</p>
        <p>{created}</p>
      </div>
      <div className="dashboardPlansInfo">
        <p>{finished ? `Finished at:` : "Pending"}</p>
      </div>
    </div>
  );
}
