import { useState } from "react";

export default function ClientList({
  clientList,
  costumerList,
  setWhichClient,
}) {
  const [background, setBackground] = useState("");
  const [selClient, setSelClient] = useState("");
  const [isBold, setIsBold] = useState("");

  function handleBackground(e) {
    setSelClient(e);
    setBackground("#ababab");
  }

  function handleBackgroundRes() {
    setBackground("#e6e6e6");
  }

  function handleIsBold(e) {
    setIsBold(e);
  }

  return (
    <div className="clientList LRPadding20 thinBox">
      <h2>Client list</h2>
      {costumerList.map((client, i) => (
        <ClientListEntry
          background={i === selClient ? background : ""}
          vat={client.vat}
          cltName={client.name}
          cltNum={client.customer_id}
          onSelectClient={(e) => {
            setWhichClient(e);
            handleIsBold(i);
          }}
          isBold={isBold}
          onHoverIn={() => handleBackground(i)}
          onHoverOut={handleBackgroundRes}
          ind={i}
        />
      ))}
    </div>
  );
}

function ClientListEntry({
  vat,
  cltName,
  cltNum,
  onSelectClient,
  onHoverIn,
  onHoverOut,
  background,
  ind,
  isBold,
}) {
  const [selected, setSelected] = useState(false);

  function handleselected(e) {
    setSelected(e);
  }

  return (
    <div
      onClick={() => {
        onSelectClient(cltNum);
        handleselected(ind);
      }}
      onMouseEnter={onHoverIn}
      onMouseLeave={onHoverOut}
      style={{
        backgroundColor: background,
        cursor: "pointer",
      }}
    >
      <div
        className="LRPadding20 thinBox alignCenter gap10"
        style={
          isBold === selected
            ? { fontWeight: "bold" }
            : { fontWeight: "normal" }
        }
      >
        <p>{vat}</p>
        <p>-</p>
        <p>{cltName}</p>
        <p>{isBold === selected ? ">" : ""}</p>
      </div>
    </div>
  );
}
