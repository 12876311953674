import { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import axios from "axios";
import DatePicker from "react-date-picker";

export default function SupplierInfoEdit({
  selSupplierInfo,
  onEdit,
  edit,
  getSupplierList,
}) {
  const [suppNum, setSuppNum] = useState("");
  const [sinceDate, setSinceDate] = useState("");
  const [suppVAT, setSuppVAT] = useState("");
  const [suppName, setSuppName] = useState("");
  const [suppAddress, setSuppAddress] = useState("");
  const [addressNum, setAddressNum] = useState("");
  const [addressCode, setAddressCode] = useState("");
  const [suppEmail, setSuppEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [obs, setOBS] = useState("");
  const [purchases, setPurchases] = useState("");
  const [valueSpended, setValueSpended] = useState(0);
  const [suppWebsite, setSuppWebsite] = useState("");

  console.log(selSupplierInfo);

  useEffect(() => {
    if (selSupplierInfo) {
      setSuppNum(selSupplierInfo.supplier_id);
      setSinceDate(selSupplierInfo.created_at);
      setSuppVAT(selSupplierInfo.vat);
      setSuppName(selSupplierInfo.name);
      setSuppAddress(selSupplierInfo.address);
      setAddressNum(selSupplierInfo.address_number);
      setAddressCode(selSupplierInfo.address_code);
      setSuppEmail(selSupplierInfo.email);
      setPhoneNumber(selSupplierInfo.phone_number);
      setOBS(selSupplierInfo.observations);
      setPurchases(selSupplierInfo.purchases);
      setSuppWebsite(selSupplierInfo.website);
      setValueSpended(selSupplierInfo.valueSpent);
    }
  }, [selSupplierInfo]);

  function handleSubmit(e) {
    e.preventDefault();

    if (suppName === "") {
      window.alert('Please, insert a name, for default use "Cliente Consumo"');
    } else if (suppVAT.length < 9) {
      window.alert(
        "Please, insert a 9 digit VAT, use 999999999 if there is no VAT"
      );
    } else {
      axios
        .put(
          "https://caravela.dev/gestor-bolso-api/supplier",
          {
            id: suppNum,
            name: suppName,
            email: suppEmail,
            address: suppAddress,
            phone_number: phoneNumber,
            address_number: addressNum,
            address_code: addressCode,
            observations: obs,
            vat: suppVAT,
            website: suppWebsite,
          },
          { withCredentials: true }
        )
        .then((resp) => {
          if (resp.data.ok) {
            getSupplierList();
            window.alert(`Client ${suppVAT} - ${suppName} edited`);
            onEdit();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  function handleDelSupplier(e) {
    e.preventDefault();

    if (purchases.length > 0) {
      window.alert(
        "You can't delete a client with purchases. Change his info instead"
      );
    } else if (
      window.confirm(
        `Are you sure you want to delete ${suppVAT} - ${suppName}?`
      )
    ) {
      let isDelete = prompt("Type 'delete' to confirm");
      if (isDelete === "delete") {
        axios
          .delete("https://caravela.dev/gestor-bolso-api/supplier", {
            withCredentials: true,
            data: {
              id: suppNum,
            },
          })
          .then((resp) => {
            if (resp.data.ok) {
              getSupplierList();
              onEdit();
              window.confirm(`${suppVAT} - ${suppName} removed`);
            }
          });
      }
    }
  }

  return (
    <form>
      {!edit && (
        <div className="centerBetween">
          <h2>Supplier Info</h2>
          <div>
            <button className="delete" onClick={handleDelSupplier}>
              Delete Supplier
            </button>{" "}
            <button onClick={handleSubmit}>Confirm Edition</button>
          </div>
        </div>
      )}

      <div className="LRPadding20 column">
        <ul className="productInputTable centerBetween">
          <div>
            <label>NIF</label>
            <input
              type="text"
              value={suppVAT}
              onChange={(e) => setSuppVAT(+e.target.value)}
              className="productInput column"
              disabled={edit}
            />
          </div>

          <div>
            <label>Supplier Number</label>
            <div className="alignCenter gap10">
              <input
                type="text"
                value={suppNum}
                onChange={(e) => setSuppNum(e.target.value)}
                className="productInput column"
                disabled
              />
            </div>
          </div>

          <div>
            <label>Supplier Name</label>
            <input
              type="text"
              value={suppName}
              onChange={(e) => setSuppName(e.target.value)}
              className="productInput column"
              disabled={edit}
            />
          </div>

          <div>
            <label>Phone Number</label>
            <input
              type="text"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              className="productInput column"
              disabled={edit}
            />
          </div>

          <div>
            <label>Email</label>
            <input
              type="text"
              value={suppEmail}
              onChange={(e) => setSuppEmail(e.target.value)}
              className="productInput column"
              disabled={edit}
            />
          </div>

          <div>
            <label>Address</label>
            <input
              type="text"
              value={suppAddress}
              onChange={(e) => setSuppAddress(e.target.value)}
              className="productInput column"
              disabled={edit}
            />
          </div>

          <div>
            <label>Address Number</label>
            <div className="alignCenter gap10">
              <input
                type="text"
                value={addressNum}
                onChange={(e) => setAddressNum(e.target.value)}
                className="productInput column"
                disabled={edit}
              />
            </div>
          </div>

          <div>
            <label>Address Code</label>
            <input
              type="text"
              value={addressCode}
              onChange={(e) => setAddressCode(e.target.value)}
              className="productInput column"
              disabled={edit}
            />
          </div>

          <div className="productInput column">
            <label>Since Date</label>

            <DatePicker
              value={sinceDate}
              clearIcon={null}
              calendarIcon={null}
              disabled
            />
          </div>

          <div>
            <label>Value Spended</label>
            <NumericFormat
              value={valueSpended}
              thousandSeparator={true}
              prefix={"€ "}
              decimalScale={2}
              fixedDecimalScale={true}
              className="productInput column"
              disabled
            />
          </div>

          <div>
            <label>Website</label>
            <input
              type="text"
              value={suppWebsite}
              onChange={(e) => setSuppWebsite(e.target.value)}
              className="productInput column"
              disabled={edit}
            />
          </div>

          <div>
            <label>Obervations</label>
            <input
              type="text"
              value={obs}
              onChange={(e) => setOBS(e.target.value)}
              className="productInput column"
              disabled={edit}
            />
          </div>
        </ul>
      </div>
    </form>
  );
}
