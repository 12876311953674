import { useState, useEffect } from "react";
import axios from "axios";

export default function ModifyWarehouse({ onClickDes, getWarehouses }) {
  const [newWarehouse, setNewWarehouse] = useState("");
  const [modWarehouse, setModWarehouse] = useState("");
  const [newModWarehouse, setNewModWarehouse] = useState("");
  const [delWarehouse, setDelWarehouse] = useState("");
  const [warehouseList, setWarehouseList] = useState([]);

  function handleWarehouses() {
    axios
      .get("https://caravela.dev/gestor-bolso-api/warehouses", {
        withCredentials: true,
      })
      .then((resp) => {
        setWarehouseList(resp.data.data);
      });
  }

  function prevent(e) {
    e.preventDefault();
  }

  function handleNewWarehouse(e) {
    e.preventDefault();

    if (newWarehouse.length > 2) {
      axios
        .post(
          `https://caravela.dev/gestor-bolso-api/warehouse`,
          {
            name: newWarehouse,
          },
          {
            withCredentials: true,
          }
        )
        .then((res) => {
          if (res.data.ok) {
            window.alert(`${newWarehouse} created`);
            getWarehouses();
            onClickDes();
          }
        });
    } else {
      window.alert("You need to input at least 3 characters");
    }
  }

  function handleModWarehouse(e) {
    e.preventDefault();

    const foundWarehouse = warehouseList.findIndex(
      (ware) => ware.warehouse_id === +modWarehouse
    );

    const oldWareName = warehouseList[foundWarehouse].name;

    if (newModWarehouse.length > 2) {
      axios
        .put(
          "https://caravela.dev/gestor-bolso-api/warehouse",
          {
            id: +modWarehouse,
            name: newModWarehouse,
          },
          { withCredentials: true }
        )
        .then((resp) => {
          if (resp.data.ok) {
            window.alert(
              `Warehouse ${oldWareName}'s name changed to ${newModWarehouse}`
            );
            onClickDes();
            getWarehouses();
          } else {
            window.alert("Choose again the warehouse");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  function handleDelWarehouse(e) {
    e.preventDefault();

    const foundWarehouse = warehouseList.findIndex(
      (ware) => ware.warehouse_id === +delWarehouse
    );

    if (delWarehouse === "") {
      window.alert("Please choose a warehouse!");
    } else if (
      window.confirm(
        `Are you sure you want to delete ${warehouseList[foundWarehouse].name}?`
      )
    ) {
      let isDelete = prompt("Type 'delete' to confirm");
      if (isDelete === "delete") {
        axios
          .delete("https://caravela.dev/gestor-bolso-api/warehouse", {
            withCredentials: true,
            data: {
              id: delWarehouse,
            },
          })
          .then((resp) => {
            if (resp.data.ok) {
              window.confirm(`${warehouseList[foundWarehouse].name} deleted`);
              getWarehouses();
              onClickDes();
            } else {
              window.alert("Choose again the warehouse");
            }
          });
      } else {
        window.alert("Choose again the warehouse");
      }
    } else {
      window.alert("Choose again the warehouse");
    }
  }

  useEffect(() => {
    handleWarehouses();
  }, []);

  return (
    <form className="creatProductForm" onSubmit={prevent}>
      <header className="centerBetween">
        <h1>Warehouse Management</h1>
        <h3 onClick={onClickDes} style={{ cursor: "pointer" }}>
          X
        </h3>
      </header>
      <div>
        <h3>Create New Warehouse</h3>
        <div className="alignCenter gap10">
          <input
            type="text"
            value={newWarehouse}
            onChange={(e) => setNewWarehouse(e.target.value)}
            placeholder={"New warehouse"}
          />
          {newWarehouse.length > 2 && (
            <button onClick={handleNewWarehouse} className="filter">
              Create
            </button>
          )}
        </div>
      </div>
      <div>
        <h3>Modify a Warehouse</h3>
        <div className="alignCenter gap10">
          <select
            className="filter"
            value={modWarehouse}
            onChange={(e) => setModWarehouse(e.target.value)}
            defaultChecked
          >
            <option value={""}>Select a Warehouse</option>
            {warehouseList.map((warehouse, i) => (
              <option value={warehouse.warehouse_id} key={"warehouse" + i}>
                {warehouse.name}
              </option>
            ))}
          </select>
          {modWarehouse && (
            <input
              type="text"
              value={newModWarehouse}
              onChange={(e) => setNewModWarehouse(e.target.value)}
            />
          )}
          {newModWarehouse.length > 2 ? (
            <button onClick={handleModWarehouse} className="filter">
              Modify
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
      <div>
        <h3>Delete a Warehouse</h3>
        <div className="alignCenter gap10">
          <select
            className="filter"
            value={delWarehouse}
            onChange={(e) => setDelWarehouse(e.target.value)}
            defaultChecked
          >
            <option value={""}>Select a Warehouse</option>
            {warehouseList.map((warehouse, i) => (
              <option value={warehouse.warehouse_id} key={"warehouse" + i}>
                {warehouse.name}
              </option>
            ))}
          </select>
          {delWarehouse && (
            <button onClick={handleDelWarehouse} className="filter">
              Delete
            </button>
          )}
        </div>
      </div>
    </form>
  );
}
