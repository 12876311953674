import { useState, useEffect } from "react";
import ConvertAddSN from "./ConvertAddSN";
import axios from "axios";

export default function ConvertArrToBuy({ selSupplierInfo, setIsArriving }) {
  const [arrivingList, setArrivingList] = useState([]);
  const [create, setCreate] = useState(false);
  const [warehouses, setWarehouses] = useState([]);

  function getWarehouses() {
    axios
      .get("https://caravela.dev/gestor-bolso-api/productItems/warehouses", {
        withCredentials: true,
      })
      .then((resp) => {
        setWarehouses(resp.data.data);
      });
  }

  function handleAddToArriving(
    cat,
    bra,
    pName,
    pSKU,
    pEAN,
    pUni,
    pCost,
    pItemID
  ) {
    const isSkuExisting = arrivingList.some((item) => item.sku === pSKU);

    if (!isSkuExisting) {
      const updatedArrList = [
        ...arrivingList,
        {
          category_name: cat,
          brand: bra,
          product_name: pName,
          sku: pSKU,
          ean: pEAN,
          quantity_bought: pUni,
          unit_cost: pCost,
          order_item_id: pItemID,
        },
      ];
      setArrivingList(updatedArrList);
    } else {
      window.alert(`${pName} already exists in the list.`);
    }
  }

  function handleConvertToStock() {
    let text = "Are you sure you want to finish the arriving?";

    if (window.confirm(text) === true) {
      setIsArriving(false);
    }
  }

  function handleRemOnArriving(pSKU, pCost, pUni) {
    setArrivingList((prevList) => {
      return prevList.filter(
        (item) =>
          item.sku !== pSKU &&
          item.unit_cost !== pCost &&
          item.quantity_bought !== pUni
      );
    });
  }

  function handleCancelCreate() {
    let text = "Are you sure you want cancel the arriving?";
    if (window.confirm(text) === true) {
      setIsArriving(false);
    }
  }

  useEffect(() => {
    getWarehouses();
  }, []);

  return (
    <div className="creatCltPurch">
      {!create ? (
        <>
          <header className="centerBetween">
            <h1>
              Arriving stock: {selSupplierInfo.vat} - {selSupplierInfo.name}
            </h1>
            <h3
              onClick={() => setIsArriving(false)}
              style={{ cursor: "pointer" }}
            >
              X
            </h3>
          </header>
          <div className="centerBetween">
            <h2>Arriving List</h2>
            <h2>Arrived SKU</h2>
          </div>

          <div className="spaceBetween">
            <div className="suppArrListAdd">
              {selSupplierInfo.purchases.map((item) =>
                item.products.map(
                  (prod) =>
                    prod.quantity_to_arrive !== 0 && (
                      <div className="alignCenter thinBox LRPadding20">
                        <div
                          className="suppArrListToAdd gap10"
                          onClick={() =>
                            handleAddToArriving(
                              prod.category_name,
                              prod.brand,
                              prod.product_name,
                              prod.sku,
                              prod.ean,
                              prod.quantity_bought,
                              prod.unit_cost,
                              prod.order_item_id
                            )
                          }
                        >
                          <div>{prod.category_name} /</div>
                          <div>{prod.brand} /</div>
                          <div>{prod.product_name} /</div>
                          <div>SKU: {prod.sku} /</div>
                          <div>EAN: {prod.ean} /</div>
                          <div>{prod.unit_cost}€ /</div>
                          <div>{prod.quantity_bought} units</div>
                        </div>
                        <p> {">"}</p>
                      </div>
                    )
                )
              )}
            </div>
            <div className="suppArrListAdd">
              {arrivingList.map((prod) => (
                <div
                  className="suppArrListAdded thinBox gap10 LRPadding20"
                  onClick={() =>
                    handleRemOnArriving(
                      prod.sku,
                      prod.unit_cost,
                      prod.quantity_bought
                    )
                  }
                  style={{ cursor: "pointer" }}
                >
                  <div> {"<"}</div>
                  <div>{prod.category_name} /</div>
                  <div>{prod.brand} /</div>
                  <div>{prod.product_name} /</div>
                  <div>{prod.sku}</div>
                </div>
              ))}
            </div>
          </div>
          <div className="centerBetween marginTop">
            <div></div>
            <button onClick={() => setCreate(true)} className="filter">
              Create
            </button>
          </div>
        </>
      ) : (
        arrivingList.length > 0 && (
          <>
            <header className="centerBetween">
              <h1>
                Create products SN: {selSupplierInfo.vat} -{" "}
                {selSupplierInfo.name}
              </h1>
              <h3 onClick={handleCancelCreate} style={{ cursor: "pointer" }}>
                X
              </h3>
            </header>
            <h5>
              (Remember: You need to click on finish to create the products)
            </h5>
            {arrivingList.map((prod) => (
              <ConvertAddSN
                arrivingList={prod}
                selSupplierInfo={selSupplierInfo}
                warehouses={warehouses}
              />
            ))}

            <button
              className="suppArrListFinish"
              onClick={handleConvertToStock}
            >
              Finish
            </button>
          </>
        )
      )}
    </div>
  );
}
