import { useState } from "react";
import { IoChevronUpSharp } from "react-icons/io5";
import { IoChevronDownSharp } from "react-icons/io5";

export default function CompoundWare({ ware, onAddtoSale }) {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="compoundInventory thinBox">
      <header
        className="centerBetween gap10"
        onClick={() => setIsActive(!isActive)}
        style={{ cursor: "pointer" }}
      >
        <p className="alignCenter gap10">{ware.warehouse_name}</p>
        <div className="expandButton">
          {isActive ? <IoChevronUpSharp /> : <IoChevronDownSharp />}
        </div>
      </header>
      {isActive &&
        ware.product_items.map((info) =>
          info.state === "In Stock" ? (
            <div
              className="warehouseCompound centerBetween thinBox LRPadding20"
              onClick={() =>
                onAddtoSale(
                  info.category_name,
                  info.brand,
                  info.product_name,
                  info.sku,
                  info.serial_number,
                  info.current_price,
                  ware.name,
                  info.product_item_id
                )
              }
              style={{ cursor: "cell" }}
            >
              <div className="displayProducts gap10">
                <div>{info.category_name} /</div>
                <div>{info.brand} /</div>
                <div>{info.product_name} /</div>
                <div>SN: {info.serial_number}</div>
              </div>
              <p> {">"}</p>
            </div>
          ) : (
            ""
          )
        )}
    </div>
  );
}
